import { Button } from "@chakra-ui/button";
import { HStack, Stack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router";
import { Avatar } from "../../../Avatar";
import { PriorityIcon } from "../../../PriorityIcon";
import { VirtualizedTable } from "../../../VirtualizedTable";
import api from "../../../../services/api";
import { Task, User } from "../../../../types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box
} from '@chakra-ui/react'
import styled from "styled-components";
import { DownloadCSV } from "../../../DownloadCSV";

interface ProjectTasksProps {
  organizationId: string;
  projectId: string;
}

export const ProjectTasks: React.FC<ProjectTasksProps> = ({
  organizationId,
  projectId,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // showing the task details models
  const {
    isOpen: isOpenSubTaskModal,
    onOpen: onOpenSubTaskModal,
    onClose: onCloseSubTaskModal
  } = useDisclosure();
  const [subtaskList, setSubTaskList] = useState<Task[]>([])
  const handleCloseSubtTaksModal = () => {
    onCloseSubTaskModal();
    setSubTaskList([]);
  }

  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ASC" | "DESC" | undefined
  >(undefined);

  const { isLoading, isError, data: tasksData, error } = useQuery(
    ["project-tasks"],
    // @ts-ignore
    () => api.fetchTasks(organizationId, projectId)
  );

  const handleEditTask = useCallback(
    (taskId: Task["uid"]) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("modal", "task");
      searchParams.set("taskId", taskId);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location, navigate]
  );

  const handleOpenTaskModal = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("modal", "task");
    searchParams.set("projectId", projectId);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [navigate, location, projectId]);

  const handleSort = useCallback(
    (dataKey: string) => {
      if (dataKey === sortBy && sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (dataKey === sortBy && sortDirection === "DESC") {
        setSortBy(undefined);
        setSortDirection(undefined);
      } else {
        setSortBy(dataKey);
        setSortDirection("ASC");
      }
    },
    [sortBy, sortDirection, setSortBy, setSortDirection]
  );

  const columns = useMemo(
    () => [
      {
        width: 80,
        flexGrow: 2,
        label: t("tasks.name"),
        dataKey: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name: Task["name"], task: Task) => (
          <div
            onClick={() => handleEditTask(task.uid)}
            style={{
              textDecoration: task.complete ? "line-through" : "none",
              cursor: "pointer",
            }}
          >
            {name}
          </div>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.user"),
        dataKey: "user",
        sorter: (a, b) =>
          a["user"]?.nominative.localeCompare(b["user"]?.nominative),
        render: (user: User) => (
          <Avatar values={[user.nominative]} extendend src={user.imageUrl} />
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.priority"),
        dataKey: "priority",
        sorter: (a, b) => a["priority"] - b["priority"],
        render: (priority: Task["priority"]) => (
          <PriorityIcon priority={priority} extendend />
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.dueDate"),
        dataKey: "dueDate",
        render: (dueDate: Task["dueDate"]) =>
          dueDate ? moment(dueDate).format("DD/MM/yyyy") : null,
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("tasks.statusName"),
        dataKey: "complete",
        render: (isCompleted: Task["complete"]) =>
          isCompleted ? "Completed" : "Open",
      },
      {
        width: 50,
        flexGrow: 1,
        label: "subtask",
        dataKey: "subTasks",
        render: (subtask: Task["subTasks"]) => {
          if (subtask) {
            setSubTaskList(subtask)
          }
          return <button onClick={() => {
            onOpenSubTaskModal();
          }}>subtask</button>
        }
      },
    ],
    [handleEditTask, t]
  );

  // Define which columns to include in PDF
  const columnsForPDF = [
    { header: t<string>("tasks.name"), dataKey: 'name' },
    // { header: t<string>("tasks.user"), dataKey: 'nominative' },
    { header: t<string>("tasks.priority"), dataKey: 'priority' },
    { header: t<string>("tasks.dueDate"), dataKey: 'dueDate' },
    { header: t<string>("tasks.statusName"), dataKey: 'complete' },
    // Add or remove columns as needed for PDF
  ];

  const tasks = useMemo(() => {
    let taskList = tasksData ?? [];
    if (sortBy) {
      const columnData = columns.find((column) => column.dataKey === sortBy);

      if (columnData?.sorter) {
        taskList = taskList.sort(columnData.sorter);

        if (sortDirection === "DESC") {
          taskList = taskList.reverse();
        }
      }
    }

    return taskList;
  }, [columns, sortBy, sortDirection, tasksData]);

  const csvData = useMemo(() => {
    const rows = [
      [
        t("tasks.name").toUpperCase(),
        t("screens.projects.reports.user").toUpperCase(),
        t("tasks.priority").toUpperCase(),
        t("tasks.dueDate").toUpperCase(),
        t("tasks.statusName").toUpperCase(),
      ],
    ];

    if (tasksData) {
      for (let data of tasksData) {
        rows.push([
          data.name,
          data.user.nominative,
          data.priority?.toString(),
          moment(data.dueDate).format("DD/MM/YY HH:mm"),
          data.complete?.toString(),
        ]);
      }
    }

    return rows;
  }, [tasksData, t]);

  if (isLoading || !tasksData) {
    return <Spinner />;
  }

  // csv
  const downloadPDF = () => {

  };



  return (
    <Stack>
      <HStack justifyContent={"flex-end"} gap={2}>
        <DownloadCSV data={csvData} target={"_blank"} separator={";"} />
        <Button
          colorScheme={"teal"}
          size={"sm"}
          onClick={() => handleOpenTaskModal()}
        >
          {t<string>("tasks.addTask")}
        </Button>
      </HStack>
      <div
        style={{
          flex: "1 1 auto",
          width: "100%",
          height: `${(tasks.length + 1) * 50}px`,
          display: "flex",
        }}
      >
        <VirtualizedTable
          rowCount={tasks.length}
          rowGetter={({ index }) => tasks[index]}
          columns={columns}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onHeaderCellClick={handleSort}
          rowStyle={(row) =>
            row.index % 2 === 0 ? { background: "#EDF1FB" } : {}
          }
        />
        {isOpenSubTaskModal &&
          <Modal isOpen={isOpenSubTaskModal} onClose={handleCloseSubtTaksModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Sub task Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {subtaskList && subtaskList.map(e => {
                  return (
                    <Box as='section' display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <TaskName>{e.name}</TaskName>
                      <TaskName>{e.complete ? "Completed" : "Open"}</TaskName>
                    </Box>
                  )
                })}
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={handleCloseSubtTaksModal}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>}
      </div>
    </Stack>
  );
};

const TaskName = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: black;
`