import { Textarea, Button, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type EditorProps = {
  onSubmit: (e: any) => any;
  submitting: boolean;
  value: string | null;
  onCancel?: () => any;
};

export const Editor: React.FC<EditorProps> = ({
  onSubmit,
  submitting,
  value,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState(value || "");

  return (
    <Stack spacing={"0.5rem"}>
      <Textarea
        rows={4}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <div>
        {onCancel && (
          <Button itemType={"button"} onClick={onCancel} marginRight={"0.5rem"}>
            {t<string>("common.cancel")}
          </Button>
        )}
        <Button
          itemType={"button"}
          isLoading={submitting}
          onClick={() => onSubmit(text)}
          disabled={value?.length === 0}
          colorScheme={"teal"}
        >
          {t<string>("common.save")}
        </Button>
      </div>
    </Stack>
  );
};
