import { Stack } from "@chakra-ui/layout";
import styled from "styled-components";

export const Box = styled(Stack)`
  box-shadow: rgb(0 0 0 / 2%) 0px 3.5px 5.5px;
  border-radius: 1rem;
  padding: 1.25rem;
  width: 100%;
  margin-bottom: 0.8rem;
  background: ${(props) => props.theme.white};
`;
