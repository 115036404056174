import React, { useCallback, useMemo, useState } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { FixMeLater, TaskPriority, User } from "../../types";
import { CirclePicker } from "react-color";
import Project from "../../models/Project";
import { Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import Select from "react-select";
import { Input } from "@chakra-ui/input";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Button } from "@chakra-ui/button";
import { DatePicker } from "../DatePicker";
import { Task, TaskCategory } from "../../types";
import { Textarea } from "@chakra-ui/textarea";
import { StyledCheckbox, StyledFormLabel } from "./styled";
import * as Yup from "yup";
import { InlineTaskForm } from "./InlineTaskForm";
import { Avatar } from "../Avatar";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router";
import { TASK_PRIORITIES } from "../../config/tasks";
import { CategorySelect } from "../CategorySelect";
import { getSelectedTaskCategory } from "../../utils/select";

interface Values {
  uid: string | null;
  name: string;
  description: string;
  dueDate: number | null;
  projectId: string | null;
  userId: string;
  complete: boolean;
  priority: TaskPriority;
  categoryId: string | null;
}

type TaskFormProps = {
  task: Task | null;
  projects: Project[];
  isFetchingProjects: boolean;
  users: User[];
  isFetchingUsers: boolean;
  defaultProjectId?: Project["uid"];
  taskCategories: TaskCategory[];
  isFetchingTaskCategories: boolean;
  onSubmit: (data: FixMeLater) => any;
  onSubmitSubTask: (data: FixMeLater) => any;
  onCancel: () => any;
  onDelete?: (data: FixMeLater) => any;
};

export const TaskForm: React.FC<TaskFormProps> = ({
  task,
  projects,
  isFetchingProjects,
  users,
  isFetchingUsers,
  defaultProjectId,
  taskCategories,
  isFetchingTaskCategories,
  onSubmit,
  onSubmitSubTask,
  onCancel,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [subTaskFormVisible, setSubTaskFormVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const groupedProjects = useMemo(() => {
    const groupedProjects: any = [{ id: "", label: t("eventTile.noProject") }];

    for (let project of projects) {
      if (project.category) {
        if (
          !groupedProjects.find(
            (group) => group.label === project.category?.name
          )
        ) {
          groupedProjects.push({ label: project.category?.name, options: [] });
        }
      } else {
        if (
          !groupedProjects.find(
            (group) =>
              group.label === t("screens.board.event.noProjectCategory")
          )
        ) {
          groupedProjects.push({
            label: t("screens.board.event.noProjectCategory"),
            options: [],
          });
        }
      }

      for (let i = 0; i < groupedProjects.length; i++) {
        if (
          groupedProjects[i].label ===
          (project.category?.name || t("screens.board.event.noProjectCategory"))
        ) {
          groupedProjects[i].options.push({
            value: project.uid,
            label: `${project.title} (${project.customer?.nominative})`,
          });
        }
      }
    }

    return groupedProjects;
  }, [projects, t]);

  const usersOptions = useMemo(
    () =>
      users.map((user) => ({
        label: user.nominative,
        value: user.uid,
      })),
    [users]
  );

  const taskPriorities = useMemo(() => {
    return Object.keys(TASK_PRIORITIES).map((priorityKey) => ({
      label: t(`tasks.priorities.${TASK_PRIORITIES[priorityKey].name}`),
      value: priorityKey,
    }));
  }, [t]);

  const handleSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const projectId =
        values.projectId && values.projectId?.length > 0
          ? values.projectId
          : null;
      const userId =
        values.userId && values.userId?.length > 0 ? values.userId : null;

      const newTask = { ...values, projectId, userId };

      setSubmitting(false);
      onSubmit(newTask);
    },
    [onSubmit]
  );

  const handleSubtaskClick = useCallback(
    (taskId: Task["uid"]) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("modal", "task");
      searchParams.set("taskId", taskId);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [navigate, location]
  );

  const getSelectedProject: any = (projectId) => {
    if (!projectId) return "";

    const project = projects.find((project) => project.uid === projectId);

    return project
      ? {
        value: project.uid,
        label: project.title,
      }
      : "";
  };

  const getSelectedUser: any = (userId) => {
    if (!userId) return "";

    const user = users.find((user) => user.uid === userId);

    return user
      ? {
        value: user.uid,
        label: user.nominative,
      }
      : "";
  };

  const getSelectedPriority: any = (priority: TaskPriority) => {
    if (!priority) return "";

    return {
      label: t(`tasks.priorities.${TASK_PRIORITIES[priority].name}`),
      value: priority,
    };
  };

  const taskCategoriesOpts = useMemo(() => {
    const categories: any = [
      { id: "", label: t("screens.board.event.noProjectCategory") },
    ];

    for (let taskCategory of taskCategories) {
      categories.push({
        value: taskCategory.uid,
        label: taskCategory.name,
        color: taskCategory.color,
      });
    }

    return categories;
  }, [t, taskCategories]);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      height: "var(--chakra-sizes-10)",
      borderRadius: "var(--chakra-radii-md)",
      borderColor: "inherit",
    }),
  };

  return (
    <>
      <Formik
        key={JSON.stringify(task || {})}
        initialValues={{
          uid: task?.uid || null,
          name: task?.name || "",
          description: task?.description || "",
          dueDate: task?.dueDate || null,
          projectId: task?.project?.uid || defaultProjectId || null,
          userId: task?.user.uid || "",
          complete: !!task?.complete,
          priority: task?.priority ?? 30,
          categoryId: task?.category?.uid || null,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(t("tasks.validations.nameRequired"))
            .min(1, t("tasks.validations.nameRequired")),
          projectId: Yup.string()
            .required(t("tasks.validations.projectRequired"))
            .min(1, t("tasks.validations.projectRequired")),
          userId: Yup.string()
            .required(t("tasks.validations.userRequired"))
            .min(1, t("tasks.validations.userRequired")),
        })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Field type={"hidden"} name={"id"} />

            <HStack spacing={"1rem"}>
              <Heading as={"h4"} size={"md"}>
                {task ? t("tasks.editTask") : t("tasks.addTask")}
              </Heading>
            </HStack>
            <Stack spacing={"1rem"} paddingTop={"2rem"}>
              <HStack spacing={"1rem"}>
                {props.values.uid && (
                  <Field id={"complete"} name={"complete"}>
                    {({ field, form: { setFieldValue }, meta }) => (
                      <FormControl
                        isInvalid={meta.error && meta.touched}
                        style={{ width: "auto" }}
                      >
                        <StyledCheckbox
                          name={field.name}
                          isChecked={field.value}
                          onChange={(event) => {
                            setFieldValue(field.name, event.target.checked);
                          }}
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                )}
                <Field id={"name"} name={"name"}>
                  {({ field, form: { setFieldValue }, meta }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor="name">
                        {t<string>("tasks.name")}
                      </StyledFormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack width={200}>
                  <Field name={"priority"}>
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <FormControl isInvalid={meta.error && meta.touched}>
                        <StyledFormLabel htmlFor={"priority"}>
                          {t<string>("tasks.priority")}
                        </StyledFormLabel>
                        <Select
                          options={taskPriorities}
                          value={getSelectedPriority(field.value)}
                          onChange={(option) =>
                            setFieldValue(field.name, option?.value || null)
                          }
                          onBlur={field.onBlur}
                          styles={selectStyles}
                        />

                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
              </HStack>

              <Field name={"description"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl isInvalid={meta.description && meta.touched}>
                    <StyledFormLabel htmlFor={"description"}>
                      {t<string>("tasks.description")}
                    </StyledFormLabel>
                    <Textarea
                      name={field.name}
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      size={"sm"}
                    />

                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack>
                <Field name={"projectId"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor="projectId">
                        {t<string>("tasks.project")}
                      </StyledFormLabel>
                      <Select
                        options={groupedProjects}
                        value={getSelectedProject(field.value)}
                        isLoading={isFetchingProjects}
                        onChange={(option) =>
                          setFieldValue(field.name, option.value)
                        }
                        onBlur={field.onBlur}
                        styles={selectStyles}
                      />

                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name={"categoryId"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor="categoryId">
                        {t<string>("screens.board.formColor")}
                      </StyledFormLabel>
                      <CategorySelect
                        options={taskCategoriesOpts}
                        value={getSelectedTaskCategory(
                          field.value,
                          taskCategories
                        )}
                        isLoading={isFetchingTaskCategories}
                        onChange={(option) =>
                          setFieldValue(field.name, option?.value)
                        }
                        onBlur={field.onBlur}
                      />

                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <HStack>
                <Field name={"userId"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor={"userId"}>
                        {t<string>("tasks.user")}
                      </StyledFormLabel>
                      <Select
                        options={usersOptions}
                        value={getSelectedUser(field.value)}
                        isLoading={isFetchingUsers}
                        onChange={(option) =>
                          setFieldValue(field.name, option?.value || null)
                        }
                        onBlur={field.onBlur}
                        styles={selectStyles}
                      />

                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="dueDate">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl
                      isInvalid={meta.error && meta.touched}
                      style={{ width: "auto !important" }}
                    >
                      <StyledFormLabel htmlFor={"dueDate"}>
                        {t<string>("tasks.dueDate")}
                      </StyledFormLabel>
                      <DatePicker
                        // @ts-ignore
                        dateFormat={"dd/MM/yyyy"}
                        selectedDate={
                          props.values.dueDate
                            ? new Date(props.values.dueDate)
                            : undefined
                        }
                        onChange={(date) => {
                          setFieldValue("dueDate", date?.getTime() || null);
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </HStack>
            </Stack>

            {(subTaskFormVisible ||
              (task?.subTasks && task.subTasks.length > 0)) &&
              task && (
                <Stack paddingTop={"1rem"}>
                  <Heading as={"h6"} size={"xs"}>
                    {t<string>("tasks.subtasks")}
                  </Heading>
                  {task.subTasks && (
                    <SubTaskList
                      subtasks={task.subTasks}
                      onClick={handleSubtaskClick}
                    />
                  )}
                  {subTaskFormVisible && (
                    <InlineTaskForm
                      users={users}
                      isFetchingUsers={isFetchingUsers}
                      projectId={task?.project?.uid || ""}
                      parentId={task.uid}
                      onCancel={() => setSubTaskFormVisible(false)}
                      onSubmit={onSubmitSubTask}
                    />
                  )}
                </Stack>
              )}

            <HStack justifyContent={"space-between"} marginTop={"1.5rem"}>
              <HStack>
                {task?.uid && onDelete && (
                  <Button colorScheme={"red"} onClick={() => onDelete(task)}>
                    {t<string>("screens.board.deleteButton")}
                  </Button>
                )}
                {!task?.parentTask && task?.uid && (
                  <Button
                    variant={"ghost"}
                    onClick={() => setSubTaskFormVisible(true)}
                  >
                    {t<string>("tasks.addSubtask")}
                  </Button>
                )}
              </HStack>

              <HStack gridGap={1}>
                <Button variant={"ghost"} onClick={onCancel}>
                  {t<string>("screens.board.cancelButton")}
                </Button>

                <Button colorScheme={"teal"} type={"submit"}>
                  {t<string>("screens.board.confirmButton")}
                </Button>
              </HStack>
            </HStack>
          </Form>
        )}
      </Formik>
    </>
  );
};

type SubTaskListProps = {
  subtasks: Task[];
  onClick: (taskId: Task["uid"]) => any;
};

const SubTaskList: React.FC<SubTaskListProps> = ({ subtasks, onClick }) => {
  return (
    <Stack>
      {subtasks.map((subtask) => (
        <InlineSubTaskRow
          subtask={subtask}
          key={subtask.uid}
          onClick={() => onClick(subtask.uid)}
        />
      ))}
    </Stack>
  );
};

type InlineSubTaskRowProps = {
  subtask: Task;
  onClick: () => any;
};

const InlineSubTaskRow: React.FC<InlineSubTaskRowProps> = ({
  subtask,
  onClick,
}) => {
  return (
    <StyledHStack display={"flex"} padding={1}>
      {/* <StyledCheckbox
        isChecked={subtask.complete}
        onChange={(event) => {
          console.log(event.target.checked);
        }}
      /> */}
      <div style={{ flexGrow: 2 }}>
        <StyledLinkText onClick={onClick}>{subtask.name}</StyledLinkText>
      </div>
      <Avatar src={subtask.user.imageUrl} values={[subtask.user.nominative]} />
    </StyledHStack>
  );
};

const StyledHStack = styled(HStack)`
  border: 2px solid #fbfbfb;
  border-radius: 0.5rem;
`;

const StyledLinkText = styled(Text)`
  cursor: pointer;
  color: var(--chakra-colors-teal-600);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
