import { makeAutoObservable } from "mobx";
import api from "../services/api";
import {
  Customer,
  EditedProject,
  EditedProjectExpense,
  EditedProjectNote,
  Organization,
  ProjectCategory,
  ProjectNote,
  ProjectStatus,
  User,
} from "../types";
import Expense from "./Expense";

export default class Project {
  uid: string;
  title: string;
  customer: Customer | null;
  externalReference: string | null;
  notBillable: boolean;
  budget: number | null;
  marginabilityPercentage: number | null;
  toleranceDays: number | null;
  projectManager: User | null;
  category: ProjectCategory | null;
  organizationId: Organization["uid"];
  expenses: Expense[];
  status: ProjectStatus;
  notes: ProjectNote[];
  totalWorkLogCost: number;
  contactName: string | null;
  contactSurname: string | null;
  contactEmail: string | null;
  startDate: Date | null;
  endDate: Date | null;
  closingDate: Date | null;
  members: User[] | null;
  description: string | null;
  totalFinalBalanceExpenses: number;
  totalWorkLog: number;

  isFetchingExpenses: boolean = false;
  isFetchingNotes: boolean = false;
  isSubmitting: boolean = false;

  constructor(
    uid: string,
    title: string,
    customer: Customer | null,
    externalReference: string | null,
    notBillable: boolean,
    budget: number | null,
    marginabilityPercentage: number | null,
    toleranceDays: number | null,
    projectManager: User | null,
    category: ProjectCategory | null,
    organizationId: Organization["uid"],
    status: ProjectStatus,
    totalWorkLogCost: number,
    contact_name: string | null,
    contact_surname: string | null,
    contact_email: string | null,
    startDate: Date | null,
    endDate: Date | null,
    members: User[] | null,
    description: string | null,
    totalFinalBalanceExpenses: number,
    totalWorkLog: number,
    closingDate: Date | null
  ) {
    this.uid = uid;
    this.title = title;
    this.customer = customer;
    this.externalReference = externalReference;
    this.notBillable = notBillable;
    this.budget = budget;
    this.marginabilityPercentage = marginabilityPercentage;
    this.toleranceDays = toleranceDays;
    this.projectManager = projectManager;
    this.category = category;
    this.organizationId = organizationId;
    this.expenses = [];
    this.status = status;
    this.notes = [];
    this.totalWorkLogCost = totalWorkLogCost;
    this.contactName = contact_name;
    this.contactSurname = contact_surname;
    this.contactEmail = contact_email;
    this.startDate = startDate;
    this.endDate = endDate;
    this.members = members;
    this.description = description;
    this.totalFinalBalanceExpenses = totalFinalBalanceExpenses;
    this.totalWorkLog = totalWorkLog;
    this.closingDate = closingDate;

    makeAutoObservable(this);
  }

  get currentMarginability() {
    return this.budget && this.budget > 0
      ? (
          100 -
          (((this.totalWorkLogCost || 0) + this.totalFinalBalanceExpenses ||
            0) /
            (this.budget || 0)) *
            100
        ).toFixed(2)
      : null;
  }

  updateInstance = (data) => {
    this.title = data.title;
    this.customer = data.customer;
    this.externalReference = data.externalReference;
    this.notBillable = data.notBillable;
    this.budget = data.budget;
    this.marginabilityPercentage = data.marginabilityPercentage;
    this.toleranceDays = data.toleranceDays;
    this.category = data.category;
    this.status = data.status;
    this.contactName = data.contactName;
    this.contactSurname = data.contactSurname;
    this.contactEmail = data.contactEmail;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.projectManager = data.projectManager;
    this.members = data.members;
    this.description = data.description;
    this.totalFinalBalanceExpenses =
      data.totalFinalBalanceExpenses || this.totalFinalBalanceExpenses;
    this.totalWorkLogCost = data.totalWorkLogCost || this.totalWorkLogCost;
    this.totalWorkLog = data.totalWorkLog;
    this.closingDate = data.closingDate;
  };

  update = async (editedProject: EditedProject) => {
    this.isSubmitting = true;

    try {
      const data = await api.updateProject(
        this.organizationId,
        this.uid,
        editedProject
      );

      this.updateInstance(data);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  refresh = async () => {
    const data = await api.fetchProject(this.organizationId, this.uid);

    this.updateInstance(data);
  };

  updateDescription = async (description: Project["description"]) => {
    const editedProject: EditedProject = {
      uid: this.uid,
      title: this.title,
      customerId: this.customer?.uid || null,
      externalReference: this.externalReference,
      notBillable: this.notBillable,
      budget: this.budget,
      marginabilityPercentage: this.marginabilityPercentage,
      toleranceDays: this.toleranceDays,
      projectManagerId: this.projectManager?.uid || "",
      categoryId: this.category?.uid || null,
      statusId: this.status.uid,
      contactName: this.contactName,
      contactSurname: this.contactSurname,
      contactEmail: this.contactEmail,
      startDate: this.startDate,
      closingDate: this.closingDate,
      endDate: this.endDate,
      members: this.members?.map((member) => member.uid) || [],
      description,
    };

    await this.update(editedProject);
  };

  fetchExpenses = async () => {
    this.isFetchingExpenses = true;

    try {
      const expenses = await api.fetchProjectExpenses(
        this.organizationId,
        this.uid
      );
      this.expenses = expenses;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingExpenses = false;
    }
  };

  submitExpense = async (editedExpense: EditedProjectExpense) => {
    this.isSubmitting = true;
    try {
      if (editedExpense.uid) {
        const expense = await api.updateProjectExpense(
          this.organizationId,
          this.uid,
          editedExpense
        );

        for (let i = 0; i < this.expenses.length; i++) {
          if (this.expenses[i].uid === expense.uid) {
            this.expenses[i] = { ...expense };
          }
        }
      } else {
        const expense = await api.addProjectExpense(
          this.organizationId,
          this.uid,
          editedExpense
        );

        this.expenses.push(expense);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      this.refresh();
    }
  };

  deleteExpense = async (expense: Expense) => {
    this.isSubmitting = true;
    try {
      await api.deleteProjectExpense(
        this.organizationId,
        this.uid,
        expense.uid
      );

      this.expenses = this.expenses.filter((exp) => exp.uid !== expense.uid);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      this.refresh();
    }
  };

  fetchNotes = async () => {
    this.isFetchingNotes = true;

    try {
      const notes = await api.fetchProjectNotes(this.organizationId, this.uid);
      this.notes = notes;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingNotes = false;
    }
  };

  submitNote = async (editedNote: EditedProjectNote) => {
    this.isSubmitting = true;
    try {
      if (editedNote.uid) {
        const note = await api.updateProjectNote(
          this.organizationId,
          this.uid,
          editedNote
        );
        for (let i = 0; i < this.expenses.length; i++) {
          if (this.notes[i].uid === note.uid) {
            this.notes[i] = { ...note };
          }
        }
      } else {
        const note = await api.addProjectNote(
          this.organizationId,
          this.uid,
          editedNote
        );

        this.notes.push(note);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  deleteNote = async (note: ProjectNote) => {
    this.isSubmitting = true;
    try {
      await api.deleteProjectNote(this.organizationId, this.uid, note.uid);

      this.notes = this.notes.filter((exp) => exp.uid !== note.uid);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };
}
