import { Textarea, Button, Stack, Flex, Text, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditedProjectNote, ProjectNote, User } from "../types";
import { Avatar } from "./Avatar";
import { Comment } from "./Comment";

type Props = {
  notes: ProjectNote[];
  isFetching: boolean;
  onLoad: () => any;
  onSubmit: (note: EditedProjectNote) => any;
  onDelete: (note: ProjectNote) => any;
  user: User | null;
  isSubmitting: boolean;
};

type EditableCommentProps = {
  note: ProjectNote;
  onUpdate: (note: EditedProjectNote) => any;
  onDelete: (e: any) => any;
};

type EditorProps = {
  onChange: (note: string) => any;
  onSubmit: (e: any) => any;
  submitting: boolean;
  value: string;
  editMode?: boolean;
  onCancel?: () => any;
};

const Editor: React.FC<EditorProps> = ({
  onChange,
  onSubmit,
  submitting,
  value,
  editMode = false,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={"0.5rem"}>
      <Textarea
        rows={4}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div>
        {onCancel && (
          <Button itemType="button" onClick={onCancel} marginRight={"0.5rem"}>
            {t<string>("common.cancel")}
          </Button>
        )}
        <Button
          itemType="submit"
          isLoading={submitting}
          onClick={onSubmit}
          disabled={value.length === 0}
          colorScheme={"teal"}
        >
          {editMode
            ? t("common.confirm")
            : t("screens.projects.notes.actions.addNote")}
        </Button>
      </div>
    </Stack>
  );
};

const EditableComment: React.FC<EditableCommentProps> = ({
  note,
  onUpdate,
  onDelete,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState(note.note);
  const { t } = useTranslation();

  const actions = [
    <span onClick={onDelete}>{t<string>("common.delete")}</span>,
    <span onClick={() => setEditMode(true)}>{t<string>("common.edit")}</span>,
  ];

  return (
    <Comment
      author={note.createdBy.nominative}
      avatar={note.createdBy.imageUrl}
      actions={editMode ? [] : actions}
      content={
        editMode ? (
          <Editor
            onChange={setContent}
            onSubmit={() => {
              // TODO: Refactor with useReducer
              onUpdate({ uid: note.uid, note: content });
              setEditMode(false);
            }}
            onCancel={() => {
              // TODO: Refactor with useReducer
              setContent(note.note);
              setEditMode(false);
            }}
            value={content}
            submitting={false}
            editMode
          />
        ) : (
          <p>{content}</p>
        )
      }
      datetime={note.createdAt}
    />
  );
};

export const ProjectNotes: React.FC<Props> = ({
  notes,
  isFetching,
  onLoad,
  onSubmit,
  onDelete,
  user,
  isSubmitting,
}) => {
  const [note, setNote] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <div style={{ padding: "0 1rem" }}>
      {isFetching && <Spinner size={"lg"} />}

      {notes.map((note) => (
        <>
          <EditableComment
            key={note.uid}
            note={note}
            onUpdate={onSubmit}
            onDelete={(e) => {
              if (window.confirm(t("screens.projects.notes.actions.delete")))
                onDelete(note);
            }}
          />
        </>
      ))}

      {user && (
        <Stack>
          <Flex alignItems={"center"}>
            <Avatar
              src={user.imageUrl}
              name={user.nominative}
              size={"md"}
              marginRight={"0.5rem"}
            />
            <Text>{user.nominative}</Text>
          </Flex>
          <Editor
            onChange={setNote}
            onSubmit={() => {
              // TODO: Refactor with useReducer
              onSubmit({ uid: null, note });
              setNote("");
            }}
            submitting={isSubmitting}
            value={note}
          />
        </Stack>
      )}
    </div>
  );
};
