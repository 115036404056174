import {
  Link,
  Table,
  Tag,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { TFunction } from "i18next";
import { Observer } from "mobx-react";
import React from "react";

import Project from "../../../../models/Project";
import { Action } from "../../../../screens/ProjectDetails";

interface FinalExpensesProps {
  currentProject: Project;
  expenseDispatch: React.Dispatch<Action>;
  t: TFunction;
}

export const FinalExpenses: React.FC<FinalExpensesProps> = ({
  currentProject,
  expenseDispatch,
  t,
}) => {
  return (
    <Observer>
      {() => (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color="gray.400">{t<string>("screens.expenses.title")}</Th>
              <Th color="gray.400">{t<string>("screens.expenses.supplier")}</Th>
              <Th color="gray.400">
                {t<string>("screens.expenses.isBillable")}
              </Th>
              <Th color="gray.400">{t<string>("screens.expenses.amount")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentProject?.expenses.map((expense) => (
              <Tr key={expense.uid}>
                <Td>
                  <Link
                    href={"#"}
                    style={{
                      color: "var(--chakra-colors-teal-600)",
                      fontWeight: 400,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      expenseDispatch({
                        type: "edit-expense",
                        payload: { expense },
                      });
                    }}
                  >
                    {expense.title}
                  </Link>
                </Td>
                <Td>{expense.supplier}</Td>
                <Td>
                  {expense.isBillable && (
                    <Tag>{t<string>("screens.expenses.isBillable")}</Tag>
                  )}
                </Td>
                <Td>{expense.amount}</Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={3}> {t<string>("screens.expenses.totalAmount")}</Td>
              <Td>
                {currentProject?.expenses.reduce((total, expense) => {
                  return total + parseFloat(expense.amount.toString());
                }, 0)}{" "}
                €
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      )}
    </Observer>
  );
};
