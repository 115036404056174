import React, { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Input } from "@chakra-ui/input";
import * as Yup from "yup";
import { Stack } from "@chakra-ui/layout";
import { InvitedUser, Role } from "../../../types";
import { ModalFooterForm } from "../../ModalFooterForm";
import { Select } from "../../Select";
import { getSelectedRoleOption, toRoleOptions } from "../../../utils/role";

type Props = {
  roles: Role[];
  isFetchingRoles: boolean;
  onCancel: () => void;
  onSubmit: (invitedUser: InvitedUser) => void;
  onLoad: () => void;
};

export const InviteUserForm: React.FC<Props> = ({
  roles,
  isFetchingRoles,
  onCancel,
  onSubmit,
  onLoad,
}) => {
  const { t } = useTranslation();
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      height: "var(--chakra-sizes-10)",
      borderRadius: "var(--chakra-radii-md)",
      borderColor: "inherit",
    }),
  };

  useEffect(() => {
    onLoad();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      nominative: "",
      roleId: "",
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t("messages.formValidations.inviteUserForm.emailInvalid"))
        .required(t("messages.formValidations.inviteUserForm.emailRequired")),
      nominative: Yup.string()
        .min(2, t("messages.formValidations.inviteUserForm.nominativeRequired"))
        .required(
          t("messages.formValidations.inviteUserForm.nominativeRequired")
        ),
      roleId: Yup.string()
        .min(1, t("messages.formValidations.inviteUserForm.roleRequired"))
        .required(t("messages.formValidations.inviteUserForm.roleRequired")),
    }),
  });

  const rolesOptions = useMemo(() => {
    return toRoleOptions(roles);
  }, [roles]);

  const getSelectedRole = (roleId: string | null) => {
    return getSelectedRoleOption(roles, roleId);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={"1rem"} direction={"column"}>
        <FormControl
          isInvalid={
            Boolean(formik.errors.email) && formik.getFieldMeta("email").touched
          }
        >
          <StyledFormLabel>{t<string>("screens.users.email")}</StyledFormLabel>
          <Input
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={
            Boolean(formik.errors.nominative) &&
            formik.getFieldMeta("nominative").touched
          }
        >
          <StyledFormLabel>
            {t<string>("screens.users.nominative")}
          </StyledFormLabel>
          <Input
            name="nominative"
            value={formik.values.nominative}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.nominative}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={
            Boolean(formik.errors.roleId) &&
            formik.getFieldMeta("roleId").touched
          }
        >
          <StyledFormLabel htmlFor="roleId">
            {t<string>("screens.users.role")}
          </StyledFormLabel>
          <Select
            name="roleId"
            options={rolesOptions}
            value={getSelectedRole(formik.values.roleId)}
            isLoading={isFetchingRoles}
            onChange={(option) => formik.setFieldValue("roleId", option?.value)}
            styles={selectStyles}
            placeholder={t<string>("common.selectPlaceholder")}
          />

          <FormErrorMessage>{formik.errors.roleId}</FormErrorMessage>
        </FormControl>

        <ModalFooterForm onCancel={onCancel} />
      </Stack>
    </form>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
