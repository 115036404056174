import { Stack, Text } from "@chakra-ui/react";
import { Flex } from "rebass";
import { Avatar } from "../Avatar";

type CommentProps = {
  author: string;
  avatar?: string;
  datetime?: string;
  actions?: React.ReactNode[];
  content?: React.ReactNode;
};

export const Comment: React.FC<CommentProps> = ({
  author,
  avatar,
  datetime,
  actions,
  content,
}) => {
  return (
    <Stack paddingBottom={"1rem"}>
      <Flex alignItems={"center"}>
        <Avatar src={avatar} name={author} marginRight={"0.5rem"} />
        <Text marginRight={"0.5rem"}>{author}</Text>
        <Text fontSize={"11px"} color={"gray.400"} verticalAlign={"center"}>
          {datetime}
        </Text>
      </Flex>
      {content}
      <Flex>
        {actions?.map((action, index) => (
          <Flex
            marginRight={"0.3rem"}
            key={index}
            fontSize={"13px"}
            color={"gray"}
            style={{ cursor: "pointer" }}
          >
            {action}
          </Flex>
        ))}
      </Flex>
    </Stack>
  );
};
