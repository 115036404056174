import {
  Stores,
  FixMeLater,
  User,
  ServiceCode,
  TimeBlockingEvent,
} from "../types";
import { makeAutoObservable } from "mobx";
import api from "../services/api";
import gapi from "../services/gapi";
import Timer from "../models/Timer";
import { Ability } from "@casl/ability";
import { getCookieConsentValue } from "react-cookie-consent";
import dayjs, { Dayjs } from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})
export class SessionStore {
  isInitialized = false;
  user: User | null = null;
  isLoading = false;
  isSubmitting = false;
  sessionToken?: string = undefined;
  accessToken?: string = undefined;
  timer: Timer | null = null;

  stores: Stores;

  getWorkignDays: dayjs.Dayjs[] | null = null;

  get isLogged() {
    return this.user !== null;
  }

  get userRole() {
    return (
      this.user?.role || {
        uid: "guest",
        name: "Guest",
        acls: new Ability(),
      }
    );
  }

  get userHasAcceptedCookies() {
    return this.user?.cookieConsent || getCookieConsentValue("TimeTrap");
  }

  constructor(stores: Stores) {
    this.stores = stores;


    makeAutoObservable(this);
  }

  initialize = async () => {
    try {
      const user = await api.getCurrentUser();

      // If user is logged with Google, not restore the session from LocalStorage otherwise this.refreshTokenSetup will not be executed
      // TODO: Improve with better solution
      if (user && !user.externalId) {
        this.restoreSession();
        const preferences = user.preferences;
        const gridStart: Dayjs = this.parseTime(preferences.businessStartTime!);
        const gridEnd: Dayjs = this.parseTime(preferences.businessEndTime!);
        const totalTime = gridEnd.diff(gridStart, 'second');
        preferences.totalHours = totalTime //seconds
        this.user = { ...user, preferences: preferences };
      } else {
        this.user = null;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isInitialized = true;
    }
  };

  login = async (email: User["email"], password: string) => {
    this.isSubmitting = true;
    try {
      const user = await api.login(email, password);

      console.log(user);
      this.user = { ...user };
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  sendRecoveryLink = async (email: User["email"]) => {
    this.isSubmitting = true;

    try {
      await api.lostPassword(email);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  changePassword = async (
    email: User["email"],
    password: string,
    token: string
  ) => {
    this.isSubmitting = true;

    try {
      await api.changePassword(email, password, token);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  basicSignUp = async (
    nominative: User["nominative"],
    email: User["email"],
    password: string
  ) => {
    this.isSubmitting = true;
    try {
      const user = await api.basicSignUp(email, password, nominative);

      this.user = { ...user };
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  signUser = async (
    accessToken: string,
    sessionToken: FixMeLater,
    uid: string,
    nominative: string,
    email: string,
    imageUrl: string
  ) => {
    try {
      const user = await api.signUser(uid, nominative, email, imageUrl);
      this.sessionToken = sessionToken;
      this.accessToken = accessToken;
      api.setSession(sessionToken);
      gapi.setAccessToken(accessToken);
      console.log(user);
      this.user = user;

      // let calendarsList = await fetch(
      //   `https://www.googleapis.com/calendar/v3/users/me/calendarList`,
      //   {
      //     headers: { Authorization: `Bearer ${accessToken}` },
      //   }
      // );
      // console.log(await calendarsList.json());

      // let calendarEvents = await fetch(
      //   `https://www.googleapis.com/calendar/v3/calendars/federico@themostaza.com/events?timeMin=2020-09-28T00:00:00-01:00&timeMax=2020-10-02T23:59:59-00:00&showDeleted=false`,
      //   {
      //     headers: { Authorization: `Bearer ${accessToken}` },
      //   }
      // );
      // console.log(await calendarEvents.json());
      // //return calendarsList.json();
      this.persistSession();
    } catch (err) {
      console.log(err);
    }
  };

  restoreSession = async () => {
    const data = localStorage.getItem("session");
    const sessionData = data ? JSON.parse(data) : null;

    if (sessionData) {
      this.accessToken = sessionData.accessToken;
      this.sessionToken = sessionData.token;
      gapi.setAccessToken(sessionData.accessToken);
    }
  };

  persistSession = async () => {
    const data = {
      token: this.sessionToken,
      accessToken: this.accessToken,
    };
    localStorage.setItem("session", JSON.stringify(data));
  };

  refreshTokenSetup = (res: FixMeLater) => {
    // Timing to renew access token
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      console.log("new access token: ", newAuthRes);
      this.accessToken = newAuthRes.access_token;
      gapi.setAccessToken(newAuthRes.access_token);
      this.persistSession();

      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming);
    };

    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
  };

  resetSession = () => {
    this.user = null;
    this.accessToken = undefined;
    this.sessionToken = undefined;
    localStorage.removeItem("session");
  };

  updateUser = async (data: any) => {
    if (this.user) {
      this.isSubmitting = true;
      try {
        const user = await api.updateUser(this.user?.uid, {
          ...this.user,
          ...data,
        });

        const preferences = user.preferences;
        const gridStart: Dayjs = this.parseTime(preferences.businessStartTime!);
        const gridEnd: Dayjs = this.parseTime(preferences.businessEndTime!);
        const totalTime = gridEnd.diff(gridStart, 'second');
        preferences.totalHours = totalTime //seconds
        // Updating user cause errors on Board and GCalendars
        // this.user = { ...this.user, ...user };
        this.user = {
          ...this.user,
          termsOfUse: user.termsOfUse,
          privacyPolicy: user.privacyPolicy,
          language: user.language,
          preferences: preferences
        };
      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmitting = false;
      }
    }
  };

  enableIntegration = async (
    service: ServiceCode,
    authToken: string,
    tokenType: string,
    metadata?: object
  ) => {
    if (this.user) {
      try {
        const integration = await api.enableIntegration(
          this.user.uid,
          service,
          authToken,
          tokenType,
          metadata
        );

        this.user.integrations.push({ service });
      } catch (err) {
        throw err;
      }
    }
  };

  disableIntegration = async (service: ServiceCode) => {
    if (this.user) {
      try {
        await api.disableIntegration(this.user.uid, service);

        this.user.integrations = this.user.integrations.filter(
          (intergration) => intergration.service !== service
        );
      } catch (err) {
        throw err;
      }
    }
  };

  fetchActiveTimer = async () => {
    if (this.user) {
      try {
        this.timer = await api.getTimer(this.user.uid);
      } catch (err) {
        throw err;
      }
    }
  };

  startTimer = async (timeBlockingEventId: TimeBlockingEvent["uid"]) => {
    if (this.user) {
      try {
        this.timer = await api.createTimer(
          this.user.uid,
          timeBlockingEventId,
          new Date().getTime()
        );
      } catch (err) {
        throw err;
      }
    }
  };

  stopTimer = async () => {
    await this.timer?.stop();
    for (let i = 0; i < this.stores.board.timeBlockingEvents.length; i++) {
      if (
        this.timer?.event.uid === this.stores.board.timeBlockingEvents[i].uid
      ) {
        this.stores.board.timeBlockingEvents[
          i
        ].elapsedTime = this.timer.event.elapsedTime;
      }
    }
    this.timer = null;
  }

  updateWeekDays = (currentDay: Date) => {
    if (this.user?.preferences) {
      const days = this.user.preferences.businessDaysOfWeek
      // alert(days)
      // const array = days.map(e => (
      //   dayjs(currentDay).startOf('week').add(e - 1, 'day')
      // ))
      this.getWorkignDays = [...days.map(e => dayjs(currentDay).set('day', e === 0 ? 7 : e))].sort((a, b) => (a.diff(b)))
      // this.getWorkignDays = array
    } else {
      this.getWorkignDays = null;
    }
  }

  parseTime = (time: string): Dayjs => {
    const [hours, minutes] = time.split(':').map(Number);
    return dayjs().hour(hours).minute(minutes).second(0);
  }
}
