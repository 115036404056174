import { Stores, TimeBlockingEvent, User } from "../types";
import { makeAutoObservable } from "mobx";
import api, { getAsanaSignedUrl, disableAsana } from "../services/api";
import gapi from "../services/gapi";
import GoogleCalendar from "../models/GoogleCalendar";
import { EventInput } from "../libs/@fullcalendar/common";
import { datesAreOnSameDay } from "../utils/date";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween)

type PercetangeType = { day: dayjs.Dayjs, value: number }
type UserTaskView = {
  id: string;
  userId: string;
  name: string;
  nextFiveDayValue: PercetangeType[];
  imageUrl?: string;
}

type UserWiseEventType = {
  userId: string,
  events: TimeBlockingEvent[],
  report?: { day: dayjs.Dayjs; events: TimeBlockingEvent[], percentage?: number, }[]
}

type SelectorValueType = {
  value: string;
  label: string;
}

type UserInfoPrecType = {
  userId: string,
  report: { day: dayjs.Dayjs, percetange: number }[]
}

export class BoardStore {
  isFetching = false;
  isSubmitting = false;
  timeBlockingEvents: TimeBlockingEvent[] = [];
  userId: User["uid"] = "";
  gCalendars: GoogleCalendar[] = [];
  startDate = new Date();
  endDate = new Date();
  activeToolbarItem: string | null = null;
  timeBlockingEventsNotAssigned: TimeBlockingEvent[] = [];

  fiveDayUserTask: UserTaskView[] = [];

  isFetchingGCalendars = false;

  userWiseEvents: UserWiseEventType[] = [];
  stores: Stores;

  userPrecantageInfo: UserInfoPrecType[] = [];

  constructor(stores: Stores) {
    this.stores = stores;

    makeAutoObservable(this);
  }

  get currentPlayingEvent() {
    return this.stores.session.timer?.event;
  }

  get events(): EventInput[] {
    const events: EventInput[] = [
      {
        events: this.timeBlockingEvents.map<EventInput>(
          (event: TimeBlockingEvent) => ({
            id: event.uid,
            title: event.title,
            start: new Date(event.startTime),
            end: new Date(event.endTime),
            color: event.category?.color || "null",
            elapsedTime: event.elapsedTime,
            extendedProps: {
              completed: event.completed,
              eventType: event.eventType,
              taskLinkId: event.taskLinkId,
              projectId: event.projectId,
              project: event.project,
              source: event.source || null,
              isTask: true,
              recurrenceId: event.recurrenceId,
              sourceId: event.sourceId,
              recurrence: event.recurrence,
              category: event.category,
              gEventId: event.gEventId,
              calanderId: event.calanderId,
            },
          })
        ),
      },
    ];

    if (this.userId === this.stores.session.user?.uid) {
      for (let calendar of this.gCalendars) {
        if (calendar.enabled) {
          events.push({
            events: calendar.events.reduce((events, event) => {
              // If not exist event with externalId = Google Calendar Event Id, add to events
              if (
                !this.timeBlockingEvents.find(
                  (timeBlockingEvent) => timeBlockingEvent.sourceId === event.id
                )
              ) {
                events.push({
                  id: event.id,
                  title: event.title,
                  start: new Date(event.startTime),
                  end: new Date(event.endTime),
                  extendedProps: {
                    source: "gcalendar",
                    eventType: "activity",
                    isTask: false,
                    calanderId: calendar.id,
                    gEventId: event.id,
                    sourceId: event.id
                  },
                });
              }

              return events;
            }, []),
            color: calendar.backgroundColor,
          });
        }
      }
    }

    return events;
  }

  init = () => {
    this.fetchGoogleCalendars();
  };

  changeDateRange = (userId: User["uid"], startDate: Date, endDate: Date) => {
    this.startDate = startDate;
    this.endDate = endDate;

    this.fetchTimeBlockingEvents(userId);

    for (let calendar of this.gCalendars) {
      if (calendar.enabled && this.startDate && this.endDate) {
        calendar.fetchEvents(
          // @ts-ignore
          this.startDate.toISOString(),
          // @ts-ignore
          this.endDate.toISOString(),
          this.stores.session.user?.email
        );
      }
    }
  };

  fetchTimeBlockingEvents = async (userId: User["uid"]) => {
    this.isFetching = true;
    this.userId = userId;
    try {
      const events = await api.fetchTimeBlockingEvents(
        userId,
        this.startDate,
        this.endDate
      );
      this.timeBlockingEvents = events;
    } catch (err) {
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  fetchTimeBlockingEventsByDate = async (userId: User["uid"], sDate: Date, eDate: Date) => {
    this.isFetching = true;
    this.userId = userId;
    try {
      const events = await api.fetchTimeBlockingEvents(
        userId,
        sDate,
        eDate
      );

      return events;
    } catch (err) {
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  fetchTimeBlockingEventsByDate3 = async (userId: User["uid"], sDate: Date, eDate: Date) => {
    this.isFetching = true;
    this.userId = userId;
    try {
      const events = await api.fetchTimeBlockingEvents(
        userId,
        sDate,
        eDate
      );

      this.timeBlockingEvents = events;
    } catch (err) {
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  fetchTimeBlockingEventsByDate2 = async (userId: User["uid"], sDate: Date, eDate: Date) => {
    this.isFetching = true;
    this.userId = userId;
    try {
      const events = await api.fetchTimeBlockingEvents(
        userId,
        sDate,
        eDate
      );
      let makedaysReport: { day: dayjs.Dayjs; events: TimeBlockingEvent[], percentage?: number }[] = [];
      if (this.stores.session.getWorkignDays && this.stores.session?.user) {
        const getWDays = this.stores.session.getWorkignDays;
        getWDays.map(e => {
          const da = events.filter(et => {
            return e.isBetween(new Date(et.startTime), new Date(et.endTime), 'day', '[]') && et.eventType === "activity"
          })
          // @ts-ignore
          const totalHousr = this.stores.session.user.preferences.totalHours || 0
          const workLog = da.reduce((prevValue, currValue) => {
            return prevValue + currValue.elapsedTime;
          }, 0);
          const prec = parseFloat(((workLog / totalHousr) * 100).toFixed(2))
          makedaysReport.push({
            day: e,
            events: da,
            percentage: prec
          })
        })
      }
      console.log("makedaysReport -> ", makedaysReport);

      const fineUserExisit = this.userWiseEvents.find(e => e.userId === userId);
      if (fineUserExisit) {
        const data = this.userWiseEvents.map(e => {
          if (e.userId === userId) {
            return {
              userId,
              events: events,
              report: makedaysReport
            }
          }
          return e
        })
        this.userWiseEvents = data;
      } else {
        this.userWiseEvents.push({ userId: userId, events: events, report: makedaysReport })
      }
      this.timeBlockingEvents = events
      // return events;
    } catch (err) {
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  fetchGoogleCalendars = async () => {
    this.isFetchingGCalendars = true;
    try {
      const calendars = await gapi.fetchCalendars();
      this.gCalendars = calendars;
      for (let i = 0; i < this.gCalendars.length; i++) {
        this.gCalendars[
          i
        ].enabled = !!this.stores.session.user?.preferences.gcalendars.find(
          (gcalendarId) => gcalendarId === this.gCalendars[i].id
        );

        if (this.gCalendars[i].enabled) {
          this.gCalendars[i].fetchEvents(
            // @ts-ignore
            this.startDate.toISOString(),
            // @ts-ignore
            this.endDate.toISOString(),
            this.stores.session.user?.email
          );
        }
      }
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingGCalendars = false;
    }
  };

  updateGoogleCelandar = async (calnderId: string, start: Date, end: Date, eventId: string, summary?: string) => {
    this.isFetchingGCalendars = true;
    try {
      const calendars = await gapi.updateEventGCal(calnderId, start, end, eventId, summary);
      // this.gCalendars = calendars;
      // for (let i = 0; i < this.gCalendars.length; i++) {
      //   this.gCalendars[
      //     i
      //   ].enabled = !!this.stores.session.user?.preferences.gcalendars.find(
      //     (gcalendarId) => gcalendarId === this.gCalendars[i].id
      //   );

      //   if (this.gCalendars[i].enabled) {
      //     this.gCalendars[i].fetchEvents(
      //       // @ts-ignore
      //       this.startDate.toISOString(),
      //       // @ts-ignore
      //       this.endDate.toISOString(),
      //       this.stores.session.user?.email
      //     );
      //   }
      // }
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingGCalendars = false;
    }
  };

  deleteGoogleCalendarEvent = async (calnderId: string, eventId: string) => {
    this.isFetchingGCalendars = true;
    try {
      await gapi.deleteGCalendarEvent(calnderId, eventId);
    } catch (err) {
      throw err;
    } finally {
      await this.fetchGoogleCalendars();
      this.isFetchingGCalendars = false;
    }
  };

  toggleGoogleCalendar = async (calendarId: string, toggle: boolean) => {
    for (let calendar of this.gCalendars) {
      if (calendar.id === calendarId) {
        calendar.setEnabled(toggle);
        if (toggle && this.startDate && this.endDate) {
          calendar.fetchEvents(
            // @ts-ignore
            this.startDate.toISOString(),
            // @ts-ignore
            this.endDate.toISOString(),
            this.stores.session.user?.email
          );
        }
      }
    }

    if (this.stores.session.user) {
      if (toggle) {
        this.stores.session.user.preferences.gcalendars.push(calendarId);
      } else {
        this.stores.session.user.preferences.gcalendars = this.stores.session.user.preferences.gcalendars.filter(
          (gcId) => gcId !== calendarId
        );
      }
      this.stores.session.updateUser(this.stores.session.user);
    }
  };

  submitEvent = async (data, userId?: string | null) => {
    console.log("subtmi Date -> \n", data);

    this.userId = userId ? userId : this.userId
    this.isSubmitting = true;
    try {
      const event = { ...data };
      if (data.startTime) event.startTime = data.startTime.getTime();
      if (data.endTime) event.endTime = data.endTime.getTime();

      if (event.startTime && event.endTime && event.eventType !== "activity") {
        event.elapsedTime = (event.endTime - event.startTime) / 1000;
      }

      if (data.id) {
        // alert("existing")
        // Before update, merge data
        const eventSnapshot =
          this.timeBlockingEvents.find((tbe) => tbe.uid === data.id) || {};

        if (!eventSnapshot) {
          throw new Error("Impossibile aggiornare l'evento. Task non trovato");
        }

        const updatedEvent = {
          ...eventSnapshot,
          ...event,
          categoryId:
            event.categoryId === ""
              ? null
              : event.extendedProps?.category?.uid
                ? event.extendedProps.category.uid
                : event.categoryId,
        };

        const response = await api.setTimeBlockingEvent(
          this.userId,
          updatedEvent.uid,
          updatedEvent
        );

        for (let i in this.timeBlockingEvents) {
          if (this.timeBlockingEvents[i].uid === data.id) {
            this.timeBlockingEvents[i] = { ...response };
          }
        }

        if (data.extendedProps?.source === "gcalendar") {
          if (data.extendedProps.calanderId) {
            // alert(JSON.stringify(data))
            await this.updateGoogleCelandar(data.extendedProps.calanderId, new Date(data.startTime), new Date(data.endTime), data.extendedProps.sourceId, data.title)
          } else {
            alert("event Id Not found! GC")
          }
        }

      } else {
        // alert("new one")
        const newEvent = await api.addTimeBlockingEvent(
          this.userId,
          {
            ...event,
            categoryId: event.extendedProps?.category?.uid
              ? event.extendedProps.category.uid
              : event.categoryId,
          },
          this.stores.session.user?.organization?.uid || ""
        );

        // Remove old Recurring event instance
        this.timeBlockingEvents = this.timeBlockingEvents.filter(
          (event) =>
            !(
              event.recurrenceId &&
              newEvent.recurrenceId &&
              event.recurrenceId === newEvent.recurrenceId &&
              datesAreOnSameDay(
                new Date(event.startTime),
                new Date(newEvent.startTime)
              )
            )
        );

        this.timeBlockingEvents.push(newEvent);
        if (newEvent.recurrenceId) {
          this.fetchTimeBlockingEvents(this.userId);
        }
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isSubmitting = false;
      await this.fetchAllUserReport()
    }
  };

  updateRecurrence = async (recurrenceId: string, data: any) => {
    this.isSubmitting = true;
    try {
      await api.updateRecurrence(this.userId, recurrenceId, data);
      this.fetchTimeBlockingEvents(this.userId);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      await this.fetchAllUserReport();
    }
  };

  deleteEvent = async (eventId, deleteRecurrence: boolean) => {
    this.isSubmitting = true;
    try {
      const oldEvent = await api.deleteTimeBlockingEvent(
        this.userId,
        eventId,
        deleteRecurrence
      );

      if (deleteRecurrence) {
        this.fetchTimeBlockingEvents(this.userId);
      }

      this.timeBlockingEvents = this.timeBlockingEvents.filter(
        (event) => event.uid !== oldEvent.uid
      );
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
      await this.fetchAllUserReport()
    }
  };

  // addInlineEvent = async (date: Date) => {
  //   let startDate = date;
  //   startDate.setTime(startDate.getTime() + 8 * 60 * 60 * 1000); // Hack: Set start hour to 8:00:00. TODO: Fix with Business Start Time (user.preferences)

  //   for (let eventSource of this.events) {
  //     for (let event of eventSource.events) {
  //       const endDate = event.end as Date;

  //       if (
  //         endDate.getFullYear() === date.getFullYear() &&
  //         endDate.getMonth() === date.getMonth() &&
  //         endDate.getDate() === date.getDate()
  //       ) {
  //         if (endDate > date) {
  //           startDate = endDate;
  //         }
  //       }
  //     }
  //   }

  //   const endDate = new Date(startDate);
  //   endDate.setTime(startDate.getTime() + 1 * 60 * 60 * 1000);

  //   this.timeBlockingEvents.push({
  //     uid: generateQuickGuid(),
  //     title: "",
  //     startTime: startDate.getTime(),
  //     endTime: endDate.getTime(),
  //     elapsedTime: 0,
  //     extendedProps: {
  //       isNew: true,
  //     },
  //   });
  // };

  enableTodoist = async () => {
    try {
      const response = await api.getTodoistSignedUrl();

      window.location.href = response.url;
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  enableAsana = async () => {
    try {
      const response = await getAsanaSignedUrl();
      window.location.href = response.url;
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  disableOauthIntegration = async (oauthIntegration: "todoist" | "asana") => {
    this.isSubmitting = true;
    try {
      switch (true) {
        case oauthIntegration === "todoist":
          await api.disableTodoist();
          break;
        case oauthIntegration === "asana":
          await disableAsana();
          break;
      }
      if (this.stores.session.user) {
        const integrations = this.stores.session.user?.integrations.filter(
          (integration) => integration.service !== oauthIntegration
        );
        this.stores.session.user = {
          ...this.stores.session.user,
          integrations,
        };
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  startTimer = async (eventId: TimeBlockingEvent["uid"]) => {
    let timeBlockingEvent = this.timeBlockingEvents.find(
      (event) => event.uid === eventId
    );

    // Maybe is a Google Calendar event..
    if (!timeBlockingEvent) {
      let gCEvent: EventInput | null = null;

      for (let calendar of this.gCalendars) {
        if (calendar.enabled) {
          for (let event of calendar.events) {
            if (event.id === eventId) {
              gCEvent = event;
              break;
            }
          }
          if (gCEvent) {
            break;
          }
        }
      }

      if (gCEvent) {
        await this.submitEvent({
          // @ts-ignore
          startTime: new Date(gCEvent.startTime),
          // @ts-ignore
          endTime: new Date(gCEvent.endTime),
          title: gCEvent.title,
          source: "gcalendar",
          sourceId: gCEvent.id,
        });

        timeBlockingEvent = this.timeBlockingEvents.find(
          (event) => event.sourceId === eventId
        );
      }
    }

    if (timeBlockingEvent) {
      this.stores.session.startTimer(timeBlockingEvent.uid);
      //   new Timer(this.userId, new Date().getTime(), timeBlockingEvent)
      // );
    }
  };

  fetchTimeBlockingEventsNotAssigned = async () => {
    this.isFetching = true;

    try {
      const events = await api.fetchTimeBlockingEventsNotAssigned(this.userId);

      this.timeBlockingEventsNotAssigned = events;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetching = false;
    }
  };

  updateFiveDayUserTask = (data: UserTaskView[]) => {
    this.fiveDayUserTask = data.map(e => {
      e.nextFiveDayValue = e.nextFiveDayValue.sort((a, b) => (a.day.diff(b.day)))
      return e;
    });
  }

  resetUserWiseData = () => {
    this.userWiseEvents = [];
  }

  updateUserWiseData = (selectorValues: SelectorValueType[]) => {
    const d = this.userWiseEvents
    this.userWiseEvents = d.filter((e1) => {
      if (selectorValues.find(e => e.value === e1.userId)) {
        return true
      } else {
        return false
      }
    })
  }

  fetchAllUserReport = async () => {
    const demapty: UserInfoPrecType[] = [];
    if (this.stores.organization.selectorValues.length === 0) {
      this.userPrecantageInfo = demapty;
      this.stores.organization.toggleRightSideTimeGrapView(false);
    } else {
      for (let i = 0; i < this.stores.organization.selectorValues.length; i++) {
        const e = this.stores.organization.selectorValues[i];
        if (e.value) {
          const eData = await this.fetchTimeBlockingEventsByDate(e.value, dayjs().startOf('week').toDate(), dayjs().startOf('week').add(7, 'day').toDate());
          if (eData) {
            if (this.stores.session.user?.preferences) {
              const totalHours = this.stores.session.user.preferences.totalHours || 0;
              if (this.stores.session.getWorkignDays) {
                const filterU: { day: dayjs.Dayjs, percetange: number }[] = []
                this.stores.session.getWorkignDays.map(ed => {
                  const da = eData.filter(et => {
                    return ed.isBetween(new Date(et.startTime), new Date(et.endTime), 'day', '[]') && et.eventType === "activity"
                  });
                  // console.log("da => ", da);
                  const workLog = da.reduce((prevValue, currValue) => {
                    return prevValue + currValue.elapsedTime;
                  }, 0);

                  const prec = parseFloat(((workLog / totalHours) * 100).toFixed(2));
                  // console.log("entry -> usid -> ", e.value, ' D ', ed.date(), " p -> ", prec);
                  filterU.push({
                    day: ed,
                    percetange: prec,
                  })
                });

                demapty.push({
                  userId: e.value,
                  report: filterU,
                })
              }
            }
          }
        }
      }
      this.userPrecantageInfo = demapty
    }
  }
}
