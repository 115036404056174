import React, { useCallback, useEffect, useRef, useState } from "react";
import { sec2time } from "../../utils/dateTimeFormat";

import ClockImage from "../../assets/images/clock.svg";
import { FixMeLater, ServiceCode, TimeBlockingEventType } from "../../types";
import ContextMenu from "./../ContextMenu";
import {
  ElapsedTimeContainer,
  EventContainer,
  RunningEventContainer,
  TimerButton,
} from "./styled";
import { datesAreOnSameDay } from "../../utils/date";
import { HiCheckCircle } from "react-icons/hi";
import { Icon } from "../Icon";

const RunningEvent: React.FC = () => (
  <RunningEventContainer>
    <img
      src={ClockImage}
      alt={"timer"}
      style={{ height: "2rem", width: "2rem" }}
    />
  </RunningEventContainer>
);

type Props = {
  id: string;
  title: string;
  elapsedTime: number;
  startDate: Date;
  endDate: Date;
  event: FixMeLater;
  isTimerRunning: boolean;
  completed: boolean;
  onTimerStop: () => void;
  onTimerStart: (id: string) => void;
  onEventClick: (event: FixMeLater) => void;
  onSubmit: (data: any) => void;
  onContextMenu: React.MouseEventHandler<HTMLDivElement>;
  menu?: any;
  eventType: TimeBlockingEventType;
  source?: ServiceCode | null;
};

export const EventTile: React.FC<Props> = ({
  id,
  title,
  elapsedTime = 0,
  startDate,
  endDate,
  event,
  isTimerRunning,
  completed,
  onTimerStop,
  onTimerStart,
  onEventClick,
  onSubmit,
  onContextMenu,
  menu,
  eventType,
  source,
}) => {
  const eventTileEl = useRef<any>(null);
  const [oldZIndex, setOldZIndex] = useState();

  // I define dates so that I can only show time in format HH:MM
  const startTime =
    startDate.getHours() +
    ":" +
    (startDate.getMinutes() < 10 ? "0" : "") +
    startDate.getMinutes();
  const endTime =
    endDate.getHours() +
    ":" +
    (endDate.getMinutes() < 10 ? "0" : "") +
    endDate.getMinutes();

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (isTimerRunning) {
        onTimerStop();
      } else {
        onTimerStart(id);
      }
    },
    [isTimerRunning, onTimerStop, onTimerStart, id]
  );

  const onMouseEnter = useCallback(() => {
    if (eventTileEl.current) {
      const closestEl = eventTileEl.current.closest(
        ".fc-timegrid-event-harness"
      );
      setOldZIndex(closestEl.style.zIndex);
      closestEl.style.zIndex = 99999;
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    if (eventTileEl.current) {
      const closestEl = eventTileEl.current.closest(
        ".fc-timegrid-event-harness"
      );
      closestEl.style.zIndex = oldZIndex;
    }
  }, [oldZIndex]);

  return (
    <EventContainer
      ref={eventTileEl}
      onContextMenu={onContextMenu}
      isTimerRunning={isTimerRunning}
      eventType={eventType}
      onClick={() => onEventClick(event)}
      color={event.backgroundColor !== "null" ? event.backgroundColor : null}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isTimerRunning ? (
        <RunningEvent />
      ) : (
        <div>
          <div style={{ overflow: "hidden", fontWeight: 500 }}>
            {/* {source && <IntegrationIcon size={"small"} service={source} />} */}
            {title}
          </div>
          <span style={{ opacity: 0.75 }}>
            {startTime}-{endTime}
          </span>
          {elapsedTime > 0 && eventType !== "planning" && (
            <ElapsedTimeContainer>
              {completed && <Icon iconName={"HiCheckCircle"} size={"small"} />}
              <span>{sec2time(elapsedTime, "HH:MM")}</span>
            </ElapsedTimeContainer>
          )}
        </div>
      )}

      {eventType !== "planning" &&
        !isTimerRunning &&
        !completed &&
        datesAreOnSameDay(startDate, new Date()) && (
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          >
            <TimerButton
              onClick={handleClick}
              type={"button"}
              isTimerRunning={isTimerRunning}
            />
          </div>
        )}

      {menu && <ContextMenu parentRef={eventTileEl}>{menu}</ContextMenu>}
    </EventContainer>
  );
};
