import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  onDelete?: (data?: any) => any;
  onCancel: () => any;
};

export const ModalFooterForm: React.FC<Props> = ({ onDelete, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div style={{ flexGrow: 1 }}>
        {onDelete && (
          <Button variant={"outline"} colorScheme={"red"} onClick={onDelete}>
            {t<string>("screens.board.deleteButton")}
          </Button>
        )}
      </div>
      <HStack>
        <Button colorScheme={"teal"} onClick={onCancel} variant={"ghost"}>
          {t<string>("screens.board.cancelButton")}
        </Button>

        <Button colorScheme={"teal"} variant={"solid"} type={"submit"}>
          {t<string>("screens.board.confirmButton")}
        </Button>
      </HStack>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 2em;
  margin-bottom: 0.5rem;
`;
