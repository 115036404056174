import {
  Button,
  Link,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { TFunction } from "i18next";
import { Observer } from "mobx-react";
import React, { useCallback } from "react";
import Expense from "../../../../models/Expense";

import Project from "../../../../models/Project";
import { Action } from "../../../../screens/ProjectDetails";

interface EstimatedExpensesProps {
  currentProject: Project;
  toast: any;
  expenseDispatch: React.Dispatch<Action>;
  t: TFunction;
}

export const EstimatedExpenses: React.FC<EstimatedExpensesProps> = ({
  currentProject,
  toast,
  expenseDispatch,
  t,
}) => {
  const handleToFinalBalanceExpense = useCallback(
    async (estimateExpense: Expense) => {
      try {
        const {
          uid,
          amount,
          description,
          organizationId,
          projectId,
          supplier,
          title,
        } = estimateExpense;

        await currentProject?.submitExpense({
          uid,
          amount,
          description,
          organizationId,
          projectId,
          supplier,
          title,
          isFinalBalance: true,
          isBillable: false,
        });

        toast({
          title: t("common.actionExecutedSuccessfully"),
          status: "success",
          position: "bottom-left",
        });
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [currentProject, toast, t]
  );
  return (
    <Observer>
      {() => (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color="gray.400">{t<string>("screens.expenses.title")}</Th>
              <Th color="gray.400">{t<string>("screens.expenses.supplier")}</Th>
              <Th color="gray.400">{t<string>("screens.expenses.amount")}</Th>
              <Th color="gray.400">{t<string>("common.action")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentProject?.expenses.map((expense) => (
              <Tr key={expense.uid}>
                <Td>
                  <Link
                    href={"#"}
                    style={{
                      color: "var(--chakra-colors-teal-600)",
                      fontWeight: 400,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      expenseDispatch({
                        type: "edit-expense",
                        payload: { expense },
                      });
                    }}
                  >
                    {expense.title}
                  </Link>
                </Td>
                <Td>{expense.supplier}</Td>
                <Td>{expense.amount}</Td>
                <Td>
                  {!expense.isFinalBalance && (
                    <Button
                      size={"sm"}
                      onClick={() => handleToFinalBalanceExpense(expense)}
                    >
                      {t<string>("screens.expenses.toFinalBalance")}
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Td colSpan={2}> {t<string>("screens.expenses.totalAmount")}</Td>
              <Td colSpan={2}>
                {currentProject?.expenses.reduce((total, expense) => {
                  return total + parseFloat(expense.amount.toString());
                }, 0)}{" "}
                €
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      )}
    </Observer>
  );
};
