import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { Observer, observer } from "mobx-react";
import { ProjectForm } from "../../components/ProjectForm";
import Project from "../../models/Project";
import { VirtualizedTable } from "../../components/VirtualizedTable";
import { PageHeader } from "../../components/PageHeader";
import { Heading } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Icon } from "../../components/Icon";
import { ActionGroup } from "../../components/ActionGroup";
import { SearchBar } from "../../components/SearchBar";
import useDebounce from "../../hooks/useDebounce";
import { fuzzyMatch } from "../../utils/string";
import { Tooltip } from "@chakra-ui/tooltip";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  Spinner,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { CategoriesDrawer } from "./CategoriesDrawer";
import { Select } from "../../components/Select";
import { ProjectStatus } from "../../types";
import projectsConfig from "../../config/projects";
import { DownloadCSV } from "../../components/DownloadCSV";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useProjectsWorkflows } from "../../hooks/useProjectsWorkflows";
import { isLimited } from "../../utils/plan";
import { CustomerForm } from "../../components/Customers/CustomerForm";
import { CustomerModal } from "../../components/Customers/CutomerModal";

export const Projects: React.FC = observer(() => {
  const [currentProject, setCurrentProject] = useState<null | Project>(null);
  const { organization, session } = useStores();
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const organizationId = queryParams.get("organizationId") || "";
  const toast = useToast();

  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<
    "ASC" | "DESC" | undefined
  >(undefined);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(searchQuery, 300);
  const {
    isFetching: isFetchingProjectsWorkflows,
    data: projectsWorkflows = [],
  } = useProjectsWorkflows(organizationId);

  const debouncedFilters = useDebounce(organization.projectsFilters, 300);

  const {
    isOpen: isCategoriesDrawerOpen,
    onOpen: onCategoriesDrawerOpen,
    onClose: onCategoriesDrawerClose,
  } = useDisclosure();

  const {
    isOpen: isProjectModalOpen,
    onOpen: onProjectModalOpen,
    onClose: onProjectModalClose,
  } = useDisclosure();

  const {
    isOpen: isCustomerModalOpen,
    onOpen: onCustomerModalOpen,
    onClose: onCustomerModalClose,
  } = useDisclosure();

  const handleCloseCustomerModal = useCallback(() => {
    onCustomerModalClose();
  }, [onCustomerModalClose]);

  useEffect(() => {
    organization.fetchProjects(organizationId);
    organization.fetchProjectCategories(organizationId);
    organization.fetchCustomers(organizationId);
    organization.fetchUsers(organizationId);
  }, []);

  useEffect(() => {
    if (!isFetchingProjectsWorkflows && projectsWorkflows.length > 0) {
      organization.projectsFilters["status"] = projectsWorkflows.reduce(
        (acc, workflow): Array<string> =>
          workflow.selectedByDefault ? [...acc, workflow.uid] : acc,
        [] as Array<string>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingProjectsWorkflows]);

  const changeFilter = useCallback(
    (dataKey, value) => {
      organization.updateProjectFilters(dataKey, value);
    },
    [organization]
  );

  const getTextSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <Input
                  id={dataIndex}
                  value={
                    (organization.projectsFilters[dataIndex] as string) || ""
                  }
                  onChange={(e) => changeFilter(dataIndex, e.target.value)}
                  placeholder={`${t<string>("common.search")} ${t<string>(
                    `screens.projects.${dataIndex}`
                  )}`}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, organization, t]
  );

  const statusOptions = useMemo(
    () =>
      projectsWorkflows.map((status) => ({
        label: status.name,
        value: status.uid,
      })),
    [projectsWorkflows]
  );

  const getSelectedStatus: any = useCallback(
    (statusListIds: ProjectStatus["uid"][]) => {
      return (
        statusListIds &&
        statusListIds.map((statusId) => {
          const status = projectsWorkflows.find(
            (category) => category.uid === statusId
          );

          return (
            status && {
              value: status.uid,
              label: status.name,
            }
          );
        })
      );
    },
    [projectsWorkflows]
  );

  const getStatusSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    id={dataIndex}
                    options={statusOptions}
                    // @ts-ignore
                    isMulti
                    value={
                      getSelectedStatus(
                        organization.projectsFilters[dataIndex]
                      ) || []
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                            label: string;
                            value: string;
                          }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, getSelectedStatus, organization, statusOptions]
  );

  const getSelectedUser: any = useCallback(
    (userIds: string[]) => {
      return (
        userIds &&
        userIds.map((userId) => {
          const user = organization.users.find((user) => user.uid === userId);
          return user
            ? {
              value: user.uid,
              label: user.nominative,
            }
            : "";
        })
      );
    },
    [organization]
  );

  const getProjectManagerSearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    key={organization.users.length}
                    id={dataIndex}
                    // @ts-ignore
                    isMulti
                    options={organization.users.map((user) => ({
                      label: user.nominative,
                      value: user.uid,
                    }))}
                    value={
                      getSelectedUser(
                        organization.projectsFilters[dataIndex]
                      ) || ""
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                            label: string;
                            value: string;
                          }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, organization, getSelectedUser]
  );

  const getSelectedCategory: any = useCallback(
    (categoryIds) => {
      return (
        categoryIds &&
        categoryIds.map((categoryId) => {
          const category = organization.projectCategories.find(
            (category) => category.uid === categoryId
          );

          return (
            category && {
              value: category.uid,
              label: category.name,
            }
          );
        })
      );
    },
    [organization]
  );

  const getProjectCategorySearchProps = useCallback(
    (dataIndex) => ({
      filterDropdown: () => (
        <Observer>
          {() => (
            <FormControl>
              <InputGroup>
                <div style={{ width: "100%" }}>
                  <Select
                    id={dataIndex}
                    options={organization.projectCategories.map((category) => ({
                      label: category.name,
                      value: category.uid,
                    }))}
                    // @ts-ignore
                    isMulti
                    value={
                      getSelectedCategory(
                        organization.projectsFilters[dataIndex]
                      ) || ""
                    }
                    onChange={(options) => {
                      const selectedOptions = options as any;

                      changeFilter(
                        dataIndex,
                        options
                          ? (selectedOptions as {
                            label: string;
                            value: string;
                          }[]).map((option) => option.value)
                          : null || null
                      );
                    }}
                  />
                </div>
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={(e) => changeFilter(dataIndex, "")}
                  >
                    {"Reset"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </Observer>
      ),
    }),
    [changeFilter, getSelectedCategory, organization]
  );

  const columns = useMemo(
    () => [
      {
        width: 120,
        flexGrow: 2,
        label: t("screens.projects.title"),
        dataKey: "title",
        sorter: (a, b) => a.title.localeCompare(b.title),
        ...getTextSearchProps("title"),
        render: (title: Project["title"], record: Project) => (
          <Link
            to={`/user/${session.user?.uid}/projects/${record.uid}?organizationId=${organizationId}`}
            style={{
              color: "var(--chakra-colors-teal-600)",
              fontWeight: 500,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip label={title} aria-label={title}>
              {title}
            </Tooltip>
          </Link>
        ),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.projects.customer"),
        dataKey: "customer",
        render: (customer: Project["customer"]) => customer?.nominative,
        ...getTextSearchProps("customer"),
        sorter: (a, b) =>
          a.customer?.nominative.localeCompare(b.customer?.nominative),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.projects.externalReference"),
        dataKey: "externalReference",
        ...getTextSearchProps("externalReference"),
      },
      {
        width: 80,
        flexGrow: 2,
        label: t("screens.projects.projectManager"),
        dataKey: "projectManager",
        render: (projectManager: Project["projectManager"]) =>
          projectManager?.nominative,
        ...getProjectManagerSearchProps("projectManager"),
        sorter: (a, b) =>
          a["projectManager"]?.nominative.localeCompare(
            b["projectManager"]?.nominative
          ),
      },
      {
        width: 60,
        flexGrow: 1,
        label: t("screens.projects.status"),
        dataKey: "status",
        render: (status: Project["status"]) => status?.name || "",
        sorter: (a: Project, b: Project) =>
          (a.status?.name || "").localeCompare(b.status?.name || ""),
        ...getStatusSearchProps("status"),
      },
      {
        width: 80,
        flexGrow: 1,
        label: t("screens.projects.category"),
        dataKey: "category",
        render: (category: Project["category"]) => category?.name,
        ...getProjectCategorySearchProps("category"),
        sorter: (a, b) =>
          (a["category"]?.name || "").localeCompare(b["category"]?.name || ""),
      },
      {
        width: 70,
        flexGrow: 1,
        label: t("screens.projects.budget"),
        dataKey: "budget",
        sorter: (a, b) => a["budget"] - b["budget"],
        render: (budget: Project["budget"]) => (budget ? `${budget} €` : ""),
      },
      {
        width: 70,
        flexGrow: 1,
        label: t("screens.projects.currentMarginability"),
        dataKey: "currentMarginability",
        sorter: (a, b) => a["currentMarginability"] - b["currentMarginability"],
        render: (currentMarginability: Project["currentMarginability"]) =>
          currentMarginability ? `${currentMarginability} %` : "",
      },
    ],
    [
      organizationId,
      t,
      getStatusSearchProps,
      getProjectCategorySearchProps,
      getProjectManagerSearchProps,
      getTextSearchProps,
    ]
  );

  const projects = useMemo(() => {
    let projects = organization.projects;
    if (sortBy) {
      const columnData = columns.find((column) => column.dataKey === sortBy);

      if (columnData?.sorter) {
        projects = projects.slice().sort(columnData.sorter);

        if (sortDirection === "DESC") {
          projects = projects.reverse();
        }
      }
    }

    if (debouncedQuery) {
      projects = projects.filter(
        (project) =>
          !debouncedQuery || fuzzyMatch(JSON.stringify(project), debouncedQuery)
      );
    }

    for (let dataKey of Object.keys(debouncedFilters)) {
      projects = projects.filter((project) =>
        Array.isArray(debouncedFilters[dataKey])
          ? (debouncedFilters[dataKey] as string[]).some((searchQuery) =>
            fuzzyMatch(JSON.stringify(project[dataKey]), searchQuery)
          )
          : fuzzyMatch(
            JSON.stringify(project[dataKey]),
            debouncedFilters[dataKey] as string
          )
      );
    }

    return projects;
  }, [
    organization.projects,
    sortBy,
    sortDirection,
    columns,
    debouncedQuery,
    debouncedFilters,
  ]);

  const csvData = useMemo(() => {
    const rows = [
      [
        t("screens.projects.title").toUpperCase(),
        t("screens.projects.customer").toUpperCase(),
        t("screens.projects.externalReference").toUpperCase(),
        t("screens.projects.projectManager").toUpperCase(),
        t("screens.projects.status").toUpperCase(),
        t("screens.projects.category").toUpperCase(),
        t("screens.projects.budget").toUpperCase(),
        t("screens.projects.currentMarginability").toUpperCase(),
        t("screens.projects.startDate").toUpperCase(),
        t("screens.projects.endDate").toUpperCase(),
      ],
    ];

    if (projects) {
      for (let project of projects) {
        rows.push([
          project.title,
          project.customer?.nominative || "",
          project.externalReference || "",
          project.projectManager?.nominative || "",
          project.status?.name || "",
          project.category?.name || "",
          project.budget?.toString() || "",
          project.currentMarginability || "",
          project.startDate?.toLocaleDateString() || "",
          project.endDate?.toLocaleDateString() || "",
        ]);
      }
    }

    return rows;
  }, [projects, t]);

  const handleOpenProjectModal = useCallback(() => {
    organization.fetchUsers(organizationId);
    organization.fetchProjectCategories(organizationId);
    onProjectModalOpen();
  }, [onProjectModalOpen, organizationId, organization]);

  const handleSubmit = useCallback(
    async (project) => {
      try {
        await organization.createProject(organizationId, project);
        toast({
          title: t("screens.projects.actions.createSuccess"),
          status: "success",
          position: "bottom-left",
        });

        onProjectModalClose();
        setCurrentProject(null);
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [organization, organizationId, toast, t, onProjectModalClose]
  );

  // const handleDeleteProject = useCallback(
  //   async (projectId: Project["uid"]) => {
  //     if (window.confirm(t("screens.projects.actions.delete"))) {
  //       try {
  //         await organization.deleteProject(organizationId, projectId);
  //         setCurrentProject(null);
  //         setIsModalProjectVisible(false);
  //       } catch (err) {
  //         alert(err);
  //       }
  //     }
  //   },
  //   [organization, organizationId, t]
  // );

  // const handleSubmitCategory = useCallback(
  //   async (category) => {
  //     try {
  //       await organization.createProjectCategory(organizationId, category.name);

  //       enqueueSnackbar(t("screens.projects.actions.categorySuccess"), {
  //         variant: "success",
  //       });
  //       onCategoryModalClose();
  //     } catch (err) {
  //       console.log(err);
  //       enqueueSnackbar(t("common.error"), {
  //         variant: "error",
  //       });
  //     }
  //   },
  //   [organization, organizationId, t, enqueueSnackbar, onCategoryModalClose]
  // );

  // const handleUpdateCategory = useCallback(
  //   async (categoryId, data) => {
  //     try {
  //       await organization.updateProjectCategory(
  //         organizationId,
  //         categoryId,
  //         data.label
  //       );
  //       enqueueSnackbar(t("screens.projects.actions.categoryUpdateSuccess"), {
  //         variant: "success",
  //       });
  //     } catch (err) {
  //       console.log(err);
  //       enqueueSnackbar(t("common.error"), {
  //         variant: "error",
  //       });
  //     }
  //   },
  //   [organizationId, organization, t, enqueueSnackbar]
  // );

  // const handleSearch = useCallback(
  //   (selectedKeys, confirm, dataIndex) => {
  //     confirm();
  //     setSearchText(selectedKeys[0]);
  //     setSearchedColumn(dataIndex);
  //   },
  //   [setSearchText, setSearchedColumn]
  // );

  // const handleReset = useCallback(
  //   (clearFilters) => {
  //     clearFilters();
  //     setSearchText("");
  //   },
  //   [setSearchText]
  // );

  const handleSort = useCallback(
    (dataKey: string) => {
      if (dataKey === sortBy && sortDirection === "ASC") {
        setSortDirection("DESC");
      } else if (dataKey === sortBy && sortDirection === "DESC") {
        setSortBy(undefined);
        setSortDirection(undefined);
      } else {
        setSortBy(dataKey);
        setSortDirection("ASC");
      }
    },
    [sortBy, sortDirection, setSortBy, setSortDirection]
  );

  const handleCustomerSubmit = useCallback(
    async (customer) => {
      try {
        if (organizationId) {
          await organization.createCustomer(organizationId, customer);

          toast({
            title: t("screens.customers.actions.created"),
            status: "success",
            position: "bottom-left",
          });
        }

        onCustomerModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [onCustomerModalClose, organization, organizationId, toast, t]
  );

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.projects.titleList")}
        </Heading>
        <ActionGroup>
          {organization.isFetching && <Spinner />}
          {Object.keys(organization.projectsFilters).length > 0 && (
            <Button
              variant={"ghost"}
              size={"sm"}
              colorScheme={"teal"}
              leftIcon={<Icon iconName={"HiOutlineX"} />}
              onClick={() => organization.cleanProjectFilters()}
            >
              {t<string>("common.cleanFilters")}
            </Button>
          )}
          <SearchBar
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
          />
          <DownloadCSV data={csvData} target={"_blank"} separator={";"} />
          <Button
            leftIcon={<Icon iconName={"HiOutlineTag"} />}
            colorScheme="teal"
            variant="ghost"
            size={"sm"}
            onClick={onCategoriesDrawerOpen}
          >
            {t<string>("screens.projects.categories")}
          </Button>
          <Button
            colorScheme={"teal"}
            size={"sm"}
            onClick={handleOpenProjectModal}
            disabled={
              session.user && session.user.organization
                ? isLimited(
                  session.user.organization.plan,
                  "PROJECT",
                  organization.projects.length
                )
                : false
            }
          >
            {t<string>("screens.organization.addProject")}
          </Button>
        </ActionGroup>
      </PageHeader>

      <div style={{ flex: "1 1 auto", width: "100%", display: "flex" }}>
        <VirtualizedTable
          rowCount={projects.length}
          rowGetter={({ index }) => projects[index]}
          columns={columns}
          onHeaderCellClick={handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          activeFilters={organization.projectsFilters}
          rowStyle={(row) =>
            row.index % 2 === 0 ? { background: "#EDF1FB" } : {}
          }
        />
      </div>

      {isProjectModalOpen && (
        <Modal
          isOpen={isProjectModalOpen}
          size={"xl"}
          onClose={onProjectModalClose}
        >
          <ModalOverlay />
          <ModalContent maxW={"820px"}>
            <ModalHeader>
              {t<string>("screens.organization.addProject")}
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <ProjectForm
                project={currentProject}
                onCancel={onProjectModalClose}
                onSubmit={handleSubmit}
                users={organization.users}
                isFetchingUsers={organization.isFetchingUsers}
                categories={organization.projectCategories}
                isFetchingCategories={organization.isFetchingProjectCategories}
                customers={organization.customers}
                isFetchingCustomers={organization.isFetchingCustomers}
                isFetchingProjectsWorkflows={isFetchingProjectsWorkflows}
                projectsWorkflows={projectsWorkflows}
                onCreateCustomer={onCustomerModalOpen}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isCustomerModalOpen && (
        <CustomerModal
          customer={null}
          onSubmit={handleCustomerSubmit}
          onDismiss={handleCloseCustomerModal}
          isVisible={isCustomerModalOpen}
          onDelete={() => { }}
        />
      )}

      {/* <Drawer
        title="Gantt"
        width={"95%"}
        closable={true}
        onClose={() => setIsGanttVisible(false)}
        visible={isGanttVisible}
      >
        <FrappeGantt
          // @ts-ignore
          tasks={organization.projects
            .filter((project) => project.startDate && project.endDate)
            .map((project) => ({
              id: project.uid,
              name: project.title,
              progress: 100,
              start: moment(project.startDate).format("YYYY-MM-DD"),
              end: moment(project.endDate).format("YYYY-MM-DD"),
            }))}
        />
      </Drawer> */}

      {isCategoriesDrawerOpen && (
        <CategoriesDrawer
          isOpen={isCategoriesDrawerOpen}
          onClose={onCategoriesDrawerClose}
        />
      )}
    </div>
  );
});
