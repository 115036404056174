import { Checkbox } from "@chakra-ui/checkbox";
import { Heading, Stack } from "@chakra-ui/layout";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "../../components/Button";
import { Column } from "../../components/Column";
import { Icon } from "../../components/Icon";
import api from "../../services/api";
import { ColumnHeader } from "./styled";
import { Draggable } from "../../libs/@fullcalendar/interaction";
import { ExternalEvent } from "../../components/ExternalEvent";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation, useParams } from "react-router";
import uniqBy from "lodash/uniqBy";
import { useUserProjects } from "../../hooks/useUserProjects";
import { Select } from "../../components/Select";
import { getSelectedProject } from "../../utils/select";
import { useToast } from "@chakra-ui/react";

export const TasksView: React.FC = observer(() => {
  const SORUCE = "tasks";

  const { t } = useTranslation();
  const { session } = useStores();
  const [stateFilter, setStateFilter] = useState("todo");
  // const [assignFilter, setAssignFilter] = useState(true);
  const [projectId, setProjectId] = useState<string | null>(null);
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();

  const queryClient = useQueryClient();

  const { isLoading, isError, data: tasksData, error } = useQuery(
    ["tasks", session.user?.organization?.uid, projectId, userId],
    // @ts-ignore
    () => api.fetchTasks(session.user?.organization?.uid, projectId, userId)
  );

  const {
    isFetching: isFetchingUserProjects,
    data: userProjects = [],
  } = useUserProjects(
    session.user?.organization?.uid || "",
    session.user?.uid || ""
  );

  const tasks = useMemo(() => {
    return (tasksData || []).map((task) => ({
      id: task.uid,
      title: task.name,
      elapsedTime: 0,
      due: task.dueDate,
      projectId: task.project?.uid,
      project: task.project,
      description: task.description,
      color: task.color,
      complete: task.complete,
      userId: task.user.uid,
      user: task.user,
      subTasks: task.subTasks,
      priority: task.priority,
      category: task.category,
    }));
  }, [tasksData]);

  const groupedProjects = useMemo(() => {
    const groupedProjects: any = [{ id: "", label: t("eventTile.noProject") }];

    for (let project of userProjects) {
      if (project.category) {
        if (
          !groupedProjects.find(
            (group) => group.label === project.category?.name
          )
        ) {
          groupedProjects.push({ label: project.category?.name, options: [] });
        }
      } else {
        if (
          !groupedProjects.find(
            (group) =>
              group.label === t("screens.board.event.noProjectCategory")
          )
        ) {
          groupedProjects.push({
            label: t("screens.board.event.noProjectCategory"),
            options: [],
          });
        }
      }

      for (let i = 0; i < groupedProjects.length; i++) {
        if (
          groupedProjects[i].label ===
          (project.category?.name || t("screens.board.event.noProjectCategory"))
        ) {
          groupedProjects[i].options.push({
            value: project.uid,
            label: `${project.title} (${project.customer?.nominative})`,
          });
        }
      }
    }

    return groupedProjects;
  }, [userProjects, t]);

  const getAssegnees = (task) => {
    const assegnees = task.user ? [task.user] : [];

    if (task.subTasks) {
      for (let i = 0; i < task.subTasks.length; i++) {
        assegnees.push(task.subTasks[i].user);
      }
    }
    return uniqBy(assegnees, "nominative");
  };

  const filteredTask = useMemo(() => {
    let taskList: any[] = [];
    if (!stateFilter || stateFilter === "all") {
      taskList = tasks;
    } else {
      taskList = tasks.filter((task) => !task.complete);
    }

    // if (assignFilter) {
    //   taskList = taskList.filter((task) => task.userId === session.user?.uid);
    // }

    return taskList;
  }, [tasks, stateFilter, session]);

  useEffect(() => {
    //fetchData();

    let draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("data-title");
          let eventSourceId = eventEl.getAttribute("data-source-id");
          let source = eventEl.getAttribute("data-source");
          let eventColor = eventEl.getAttribute("data-color");
          let projectId = eventEl.getAttribute("data-project-id");
          let categoryId = eventEl.getAttribute("data-category-id");
          return {
            title,
            eventSourceId,
            source,
            color: eventColor, // Color is for the D&D ghost
            eventColor,
            projectId,
            categoryId,
          };
        },
      });
    }
  }, []);

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast({
        title: t("common.error"),
        status: "error",
        position: "bottom-left",
      });
    }
  }, [isError, error, t, toast]);

  const handleToggleStateFilter = useCallback(
    (key, checked) => {
      setStateFilter(checked ? key : null);
    },
    [setStateFilter]
  );

  // const handleToggleAssignFilter = useCallback(
  //   (state) => {
  //     setAssignFilter(state);
  //   },
  //   [setAssignFilter]
  // );

  const handleOpenTaskModal = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("modal", "task");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [navigate, location]);

  const handleEditTask = useCallback(
    (taskId: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("modal", "task");
      searchParams.set("taskId", taskId);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location.search, location.pathname, navigate]
  );

  return (
    <Column>
      <ColumnHeader>
        <Heading as={"h4"} size={"md"} fontWeight={500}>
          {t<string>("tasks.title")}
        </Heading>
        <div>
          <Popover>
            <PopoverTrigger>
              <IconButton style={{ marginLeft: "1rem" }}>
                <Icon iconName={"HiOutlineFilter"} />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <Stack marginTop={4}>
                  <div>Stato</div>
                  <Checkbox
                    isChecked={stateFilter === "todo"}
                    onChange={(event) =>
                      handleToggleStateFilter("todo", event.target.checked)
                    }
                  >
                    {"Da completare"}
                  </Checkbox>
                  <Checkbox
                    isChecked={stateFilter === "all"}
                    onChange={(event) =>
                      handleToggleStateFilter("all", event.target.checked)
                    }
                  >
                    {"Tutti"}
                  </Checkbox>

                  <div>Progetto</div>
                  <Select
                    options={groupedProjects}
                    value={
                      projectId
                        ? getSelectedProject(projectId, userProjects)
                        : null
                    }
                    isLoading={isFetchingUserProjects}
                    onChange={(option) => setProjectId(option?.value || null)}
                  />

                  {/* <div>Assegnazione</div>
                  <Checkbox
                    isChecked={assignFilter}
                    onChange={(event) => handleToggleAssignFilter(true)}
                  >
                    {"Assegnati a me"}
                  </Checkbox>
                  <Checkbox
                    isChecked={!assignFilter}
                    onChange={(event) => handleToggleAssignFilter(false)}
                  >
                    {"Tutti"}
                  </Checkbox> */}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <IconButton
            onClick={() => queryClient.invalidateQueries("tasks" as any)}
          >
            <Icon iconName={"HiRefresh"} />
          </IconButton>

          <IconButton onClick={handleOpenTaskModal}>
            <Icon iconName={"HiPlus"} />
          </IconButton>
        </div>
      </ColumnHeader>
      {isLoading && <div>Loading...</div>}

      <div
        id={"external-events"}
        style={{
          height: "100%",
          flex: "1 1 auto",
          position: "relative",
          overflowY: "auto",
          marginLeft: "0.2rem",
          overflowX: "hidden",
        }}
      >
        {filteredTask.map((task) => (
          <ExternalEvent
            className={"fc-event"}
            title={task.title}
            sourceId={task.id}
            key={task.id}
            source={"timetrap"}
            description={task.description}
            showId={false}
            onClick={handleEditTask}
            color={task.category?.color}
            project={task.project}
            complete={task.complete}
            assignees={getAssegnees(task)}
            priority={task.priority}
            category={task.category}
            disabled={false}
          >
            {task.title}
          </ExternalEvent>
        ))}
      </div>
    </Column>
  );
});
