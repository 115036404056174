import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { TFunction } from "i18next";
import { useCallback } from "react";
import Project from "../../../models/Project";
import { ExpenseForm } from "../../ExpenseForm";
import Expense from "../../../models/Expense";
import { EditedProjectExpense } from "../../../types";
import { Action } from "../../../screens/ProjectDetails";

type ExpenseModalProps = {
  currentProject: Project;
  toast: any;
  organizationId: string;
  expenseState: {
    expense: Expense | null;
    isModalVisible: boolean;
  };
  projectId: string;
  expenseDispatch: React.Dispatch<Action>;
  t: TFunction;
};

export const ExpenseModal: React.FC<ExpenseModalProps> = ({
  currentProject,
  toast,
  organizationId,
  expenseState,
  projectId,
  expenseDispatch,
  t,
}) => {
  const handleSubmitExpense = useCallback(
    async (expense: EditedProjectExpense) => {
      try {
        await currentProject?.submitExpense(expense);

        toast({
          title: t("common.actionExecutedSuccessfully"),
          status: "success",
          position: "bottom-left",
        });

        expenseDispatch({ type: "close-expense" });
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [currentProject, toast, t, expenseDispatch]
  );

  const handleDeleteExpense = useCallback(
    async (expense: Expense) => {
      if (window.confirm(t("screens.expenses.actions.delete"))) {
        try {
          await currentProject?.deleteExpense(expense);
          toast({
            title: t("common.actionExecutedSuccessfully"),
            status: "success",
            position: "bottom-left",
          });
          expenseDispatch({ type: "close-expense" });
        } catch (err) {
          console.log(err);
          toast({
            title: t("common.error"),
            status: "error",
            position: "bottom-left",
          });
        }
      }
    },
    [t, currentProject, toast, expenseDispatch]
  );

  return (
    <Modal
      isOpen={expenseState.isModalVisible}
      onClose={() => expenseDispatch({ type: "close-expense" })}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t<string>("screens.expenses.modalTitle")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ExpenseForm
            key={expenseState.expense?.uid || ""}
            organizationId={organizationId}
            projectId={projectId}
            expense={expenseState.expense}
            onCancel={() => expenseDispatch({ type: "close-expense" })}
            onSubmit={handleSubmitExpense}
            onDelete={() =>
              expenseState.expense && handleDeleteExpense(expenseState.expense)
            }
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
