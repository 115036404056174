import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Stack,
    HStack, Menu, MenuButton, MenuItem, MenuList,
    Avatar,
    Button,
} from '@chakra-ui/react'
import { useStores } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { colors } from "../../theme";
import LogoImg from "../../assets/icons/timetrap-logo.svg";
import { Icon } from "../../components/Icon";
import { ReactComponent as TeamPlanIconSVG } from "../../assets/icons/team-plan.svg";
import { ReactComponent as TaskIcon } from "../../assets/icons/task.svg";
import { IntegrationIcon } from "../../components/IntegrationIcon";
import integrations from "../../config/integrations";
import { GoogleLogout } from "react-google-login";
import keys from "../../config/keys";
import { ChangelogModal } from "../../components/ChangelogModal/ChangelogModal";
import { useCookies } from "react-cookie";
import { AbilityContext } from "../../components/Can";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { MdTaskAlt } from "react-icons/md";
import { IconType } from "react-icons";
const { version } = require("../../../package.json");

type Props = {};

// const navStrucutre = {
//     planning: {

//     }
// }

export enum MenuItemKeys {
    board,
    tasks,
    projects,
    dashboard,
    integrations,
    organization,
    organizationDetail,
    customers,
    users,
    roles,
    timesheet,
    subscription,
    workflows,
    webhooks,
    teamplan,
    teamplan2,
    teamplan3,
}
// const TeamPlanIcon = () => (React.createElement(TeamPlanIconSVG, {
//     style: { width: 25, height: 25 }
// }))
export const SiderBarBeta: React.FC<Props> = observer(() => {

    const { t } = useTranslation();
    const { session, ui, organization } = useStores();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["new-changelog"]);
    const ability = useContext(AbilityContext);

    const [showChangelogModal, setShowChangelogModal] = useState(false);

    const toggleShowChangelogModal = useCallback(() => {
        setShowChangelogModal(!showChangelogModal);
    }, [showChangelogModal]);

    const handleNavigateTo = useCallback(
        (key) => {
            ui.setActiveScreen(key);
            ui.setIsSidebarCollapsed(false);
            ui.setIsSecondarySidebarVisible(false);
            ui.setDataSource(null);
            organization.toggleRightSideTimeGrapView(false);

            switch (key) {
                case MenuItemKeys.board:
                    navigate(`/user/${session.user?.uid}/board`);
                    break;
                case MenuItemKeys.tasks:
                    navigate(`/user/${session.user?.uid}/board`);
                    break;
                case MenuItemKeys.projects:
                    navigate(
                        `/user/${session.user?.uid}/projects?organizationId=${session.user?.organization?.uid}`
                    );
                    break;
                case MenuItemKeys.organization:
                    ui.setIsSidebarCollapsed(false);
                    navigate(`/organization/${session.user?.organization?.uid}`);

                    break;
                case MenuItemKeys.dashboard:
                    navigate(`/user/${session.user?.uid}/dashboard`);
                    break;
                case MenuItemKeys.integrations:
                    navigate(`/user/${session.user?.uid}/integrations`);
                    break;
                case MenuItemKeys.organizationDetail:
                    navigate(`/organization/${session.user?.organization?.uid}`);
                    break;
                case MenuItemKeys.customers:
                    navigate(`/organization/${session.user?.organization?.uid}/customers`);
                    break;
                case MenuItemKeys.workflows:
                    navigate(`/organization/${session.user?.organization?.uid}/workflows`);
                    break;
                case MenuItemKeys.subscription:
                    navigate(`/organization/${session.user?.organization?.uid}/subscription`);
                    break;
                case MenuItemKeys.webhooks:
                    navigate(`/organization/${session.user?.organization?.uid}/webhooks`);
                    break;
                case MenuItemKeys.roles:
                    navigate(`/organization/${session.user?.organization?.uid}/roles`);
                    break;
                case MenuItemKeys.users:
                    navigate(`/organization/${session.user?.organization?.uid}/users`);
                    break;
                case MenuItemKeys.timesheet:
                    navigate(`/organization/${session.user?.organization?.uid}/reports/timesheet`);
                    break;
                case MenuItemKeys.subscription:
                    navigate(`/organization/${session.user?.organization?.uid}/subscription`);
                    break;
                case MenuItemKeys.teamplan:
                    navigate(`/user/${session.user?.uid}/teamplan`);
                    break;
                case MenuItemKeys.teamplan2:
                    navigate(`/user/${session.user?.uid}/teamplan2`);
                    break;
                case MenuItemKeys.teamplan3:
                    navigate(`/user/${session.user?.uid}/teamplan3`);
                    break;
            }
        },
        [navigate, session, ui]
    );

    const handleOpenSourcedata = useCallback(
        (sourcedata) => {
            ui.setIsSidebarCollapsed(false);
            ui.setIsSecondarySidebarVisible(true);
            ui.setDataSource(sourcedata);
        },
        [ui]
    );
    const colorsTheme = colors(false);

    useEffect(() => {
        if (cookies["new-changelog"] && cookies["new-changelog"] === "true") {
            if (!showChangelogModal) {
                toggleShowChangelogModal();
            }
        }
    }, [cookies, showChangelogModal, toggleShowChangelogModal]);

    return (
        <>
            <SidebarContainer>
                <section style={{
                    width: '100%',
                    display: "flex",
                    gap: '10px',
                    flexDirection: 'column',
                }}>
                    <BrandContainer>
                        <BrandIcon>
                            <img src={LogoImg} alt={"TimeTrap"} />
                        </BrandIcon>
                    </BrandContainer>
                    <NavBarContainer>
                        {/* @ts-ignore */}
                        <Accordion allowToggle >
                            <AccordionItem id="planning-menu" borderStyle={'none'}>
                                <AccordionButton
                                    _hover={{
                                        backgroundColor: '#319795',
                                        color: colorsTheme.white,
                                        borderRadius: "5px"
                                    }}
                                    gap={2}
                                    onClick={() => handleNavigateTo(MenuItemKeys.board)}
                                >
                                    <Icon iconName={"HiOutlineTemplate"} size="small2" />
                                    <Box as='span' flex='1' textAlign='left'>
                                        {t<string>("nav.board")}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel pb={4}>
                                    <NavItemButton
                                        onClick={() => {
                                            if (ui.activeScreen !== MenuItemKeys.tasks) {
                                                handleNavigateTo(MenuItemKeys.tasks)
                                            }
                                            handleOpenSourcedata("tasks")
                                        }}
                                    >
                                        {/* <TaskIcon style={{ width: 18, height: 18, }} className="svg-task" /> */}
                                        <Icon2 iconName={MdTaskAlt} size="small2" />
                                        {t<string>("nav.tasks")}
                                    </NavItemButton>
                                    {/* <NavItemButton
                                        onClick={() => handleNavigateTo(MenuItemKeys.teamplan2)}
                                    >
                                        <Icon2 iconName={HiOutlineUserGroup} size="small2" />
                                        {t<string>("nav.teamplan")}
                                    </NavItemButton> */}
                                    <NavItemButton
                                        onClick={() => handleNavigateTo(MenuItemKeys.teamplan3)}
                                    >
                                        <Icon2 iconName={HiOutlineUserGroup} size="small2" />
                                        {t<string>("nav.teamplan")}
                                    </NavItemButton>
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem id="dahboard" borderStyle={'none'}>
                                <AccordionButton
                                    _hover={{
                                        backgroundColor: '#319795',
                                        color: colorsTheme.white,
                                        borderRadius: "5px"
                                    }}
                                    gap={2}
                                    onClick={() => handleNavigateTo(MenuItemKeys.dashboard)}
                                >
                                    <Icon iconName={"HiOutlineChartBar"} size="small2" />
                                    <Box as='span' flex='1' textAlign='left'>
                                        {t<string>("nav.dashboard")}
                                    </Box>
                                </AccordionButton>
                            </AccordionItem>
                            {
                                // @ts-ignore
                                ability.can("read", "project") && (
                                    <AccordionItem id="project" borderStyle={'none'}>
                                        <AccordionButton
                                            _hover={{
                                                backgroundColor: '#319795',
                                                color: colorsTheme.white,
                                                borderRadius: "5px"
                                            }}
                                            gap={2}
                                            onClick={() => handleNavigateTo(MenuItemKeys.projects)}
                                        >
                                            <Icon iconName={"HiOutlineLightningBolt"} size="small2" />
                                            <Box as='span' flex='1' textAlign='left'>
                                                {t<string>("nav.projects")}
                                            </Box>
                                        </AccordionButton>
                                    </AccordionItem>)
                            }
                            {
                                // @ts-ignore
                                ability.can("read", "project") && (
                                    <AccordionItem id="organization" defaultValue={1} borderStyle={'none'}>
                                        <AccordionButton
                                            _hover={{
                                                backgroundColor: '#319795',
                                                color: colorsTheme.white,
                                                borderRadius: "5px"
                                            }}
                                            gap={2}
                                        >
                                            <Icon iconName={"HiOutlineOfficeBuilding"} size="small2" />
                                            <Box as='span' flex='1' textAlign='left'>
                                                {t<string>("nav.organization")}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <NavItemButton
                                                onClick={() => handleNavigateTo(MenuItemKeys.organizationDetail)}
                                            >
                                                {t<string>("screens.organization.nav.organizationDetail")}
                                            </NavItemButton>
                                            <NavItemButton
                                                onClick={() => handleNavigateTo(MenuItemKeys.customers)}
                                            >
                                                {t<string>("screens.customers.titleList")}
                                            </NavItemButton>
                                            <Accordion allowToggle>
                                                <AccordionItem id="orgenization-menu-project">
                                                    <AccordionButton
                                                        _hover={{
                                                            backgroundColor: '#319795',
                                                            color: colorsTheme.white,
                                                            borderRadius: "5px"
                                                        }}
                                                        paddingLeft={'10px'}
                                                    >
                                                        <Box as='span' flex='1' textAlign='left'>
                                                            {t<string>("screens.organization.nav.projectSetup")}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>

                                                    <AccordionPanel pb={4}>
                                                        <NavItemButton
                                                            onClick={() => handleNavigateTo(MenuItemKeys.workflows)}
                                                        >
                                                            {t<string>("screens.workflows.title")}
                                                        </NavItemButton>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                                <AccordionItem id="orgenization-menu-user">
                                                    <AccordionButton
                                                        _hover={{
                                                            backgroundColor: '#319795',
                                                            color: colorsTheme.white,
                                                            borderRadius: "5px"
                                                        }}
                                                        paddingLeft={'10px'}
                                                    >
                                                        <Box as='span' flex='1' textAlign='left'>
                                                            {t<string>("screens.organization.nav.userManagement")}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>

                                                    <AccordionPanel pb={4}>
                                                        <NavItemButton
                                                            onClick={() => handleNavigateTo(MenuItemKeys.roles)}
                                                        >
                                                            {t<string>("screens.roles.titleList")}
                                                        </NavItemButton>
                                                        <NavItemButton
                                                            onClick={() => handleNavigateTo(MenuItemKeys.users)}
                                                        >
                                                            {t<string>("screens.users.titleList")}
                                                        </NavItemButton>
                                                        <NavItemButton
                                                            onClick={() => handleNavigateTo(MenuItemKeys.timesheet)}
                                                        >
                                                            {t<string>("screens.timesheet.title")}
                                                        </NavItemButton>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                                <AccordionItem id="orgenization-menu-biling">
                                                    <AccordionButton
                                                        _hover={{
                                                            backgroundColor: '#319795',
                                                            color: colorsTheme.white,
                                                            borderRadius: "5px"
                                                        }}
                                                        paddingLeft={'10px'}
                                                    >
                                                        <Box as='span' flex='1' textAlign='left'>
                                                            {t<string>("screens.organization.nav.subscription")}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                    <AccordionPanel pb={4}>
                                                        <NavItemButton
                                                            onClick={() => handleNavigateTo(MenuItemKeys.subscription)}
                                                        >
                                                            {t<string>("screens.subscription.title")}
                                                        </NavItemButton>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                                <AccordionItem id="orgenization-menu-advanced">
                                                    <AccordionButton
                                                        _hover={{
                                                            backgroundColor: '#319795',
                                                            color: colorsTheme.white,
                                                            borderRadius: "5px"
                                                        }}
                                                        paddingLeft={'10px'}
                                                    >
                                                        <Box as='span' flex='1' textAlign='left'>
                                                            {t<string>("screens.organization.nav.advanced")}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>

                                                    <AccordionPanel pb={4}>
                                                        <NavItemButton
                                                            onClick={() => handleNavigateTo(MenuItemKeys.webhooks)}
                                                        >
                                                            {t<string>("screens.webhooks.title")}
                                                        </NavItemButton>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                        </AccordionPanel>
                                    </AccordionItem>)
                            }
                            <AccordionItem id="integrations" borderStyle={'none'}>
                                <AccordionButton
                                    _hover={{
                                        backgroundColor: '#319795',
                                        color: colorsTheme.white,
                                        borderRadius: "5px"
                                    }}
                                    gap={2}
                                    onClick={() => handleNavigateTo(MenuItemKeys.integrations)}
                                >
                                    <Icon iconName={"HiSwitchHorizontal"} size="small2" />
                                    <Box as='span' flex='1' textAlign='left'>
                                        {t<string>("nav.integrations")}
                                    </Box>
                                </AccordionButton>
                            </AccordionItem>
                            {/* {ui.activeScreen === MenuItemKeys.board && session.accessToken && ( */}
                            <AccordionItem id="gcalendar" borderStyle={'none'}>
                                <AccordionButton
                                    _hover={{
                                        backgroundColor: '#319795',
                                        color: colorsTheme.white,
                                        borderRadius: "5px"
                                    }}
                                    gap={2}
                                    onClick={() => handleOpenSourcedata("gcalendar")}
                                >
                                    <IntegrationIcon service={"gcalendar"} />
                                    <Box as='span' flex='1' textAlign='left'>
                                        {"Google Calendar"}
                                    </Box>
                                </AccordionButton>
                            </AccordionItem>
                            {/* )} */}
                            {
                                // ui.activeScreen === MenuItemKeys.board &&
                                session.user?.integrations.map((integration, ind) => (
                                    <AccordionItem id={integration.service + ind} key={integration.service} borderStyle={'none'}>
                                        <AccordionButton
                                            _hover={{
                                                backgroundColor: '#319795',
                                                color: colorsTheme.white,
                                                borderRadius: "5px"
                                            }}
                                            gap={2}
                                            onClick={() => handleOpenSourcedata(integration.service)}
                                        >
                                            <IntegrationIcon service={integration.service} />
                                            <Box as='span' flex='1' textAlign='left'>
                                                {integrations[integration.service].title}
                                            </Box>
                                        </AccordionButton>
                                    </AccordionItem>
                                ))}
                        </Accordion>
                    </NavBarContainer>
                </section>
                {/* @ts-ignore */}
                <HStack padding={'3px'} justifyContent={'center'}>
                    <section>
                        <Menu isLazy>
                            <MenuButton>
                                <Avatar
                                    src={session.user?.imageUrl}
                                    // @ts-ignore 
                                    values={session.user?.nominative.split(" ") || []}
                                    alt={session.user?.nominative}
                                    size={'sm'}
                                />
                            </MenuButton>
                            <MenuList zIndex={999999} minW={"8rem"}>
                                {/* MenuItems are not rendered unless Menu is open */}
                                {session.user && (
                                    <MenuItem
                                        onClick={() =>
                                            navigate(`/user/${session.user?.uid}/profile`)
                                        }
                                    >
                                        {t<string>("menu.profile")}
                                    </MenuItem>
                                )}
                                <GoogleLogout
                                    clientId={keys.GOOGLE_CLIENT_ID}
                                    onLogoutSuccess={() => {
                                        session.resetSession();
                                        navigate("/", { replace: true });
                                    }}
                                    render={(renderProps) => (
                                        // @ts-ignore
                                        <MenuItem onClick={renderProps.onClick}>
                                            {t<string>("screens.board.logoutButton")}
                                        </MenuItem>
                                    )}
                                />
                            </MenuList>
                        </Menu>
                    </section>
                    <section>
                        <a
                            target={"_blank"}
                            href={keys.KNOWLEDGEBASE_URL}
                            rel={"noopener noreferrer"}
                            title={"TimeTrap Knowledgebase"}
                        >
                            <Icon
                                iconName={"HiInformationCircle"}
                                color={"#6FCDBE"}
                                size={"large"}
                            />
                        </a>
                    </section>
                    <section>
                        <Button
                            variant={"link"}
                            colorScheme={"facebook"}
                            onClick={toggleShowChangelogModal}
                        >
                            v {version}
                        </Button>
                    </section>
                </HStack>
            </SidebarContainer>
            <ChangelogModal
                userHasAcceptedCookies={Boolean(session.userHasAcceptedCookies)}
                t={t}
                isVisible={showChangelogModal}
                toggleModal={toggleShowChangelogModal}
            />
        </>
    )
});

const SidebarContainer = styled.aside`
position: relative;
  display: flex;
flex-direction: column;
justify-content: space-between;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  background: ${(props) => props.theme.bg1};
   width: 200px;
   padding-top: 1rem;
`;

const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease;
  padding-top: 1rem;

  &:hover {
    opacity: 0.75;
  }
`;

const BrandIcon = styled.div`
  display: block;
  width: 52px;
  height: 52px;

  img {
    width: 100%;
  }
`;

const NavItemButton = styled.button`
    height: 40px;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    padding-left: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    &:hover {
        background: #319795;
        color: white;
        border-radius: 5px;
    }
`;

const FooterView = styled.section`
    height: 80px;
    width: 100%
`;

const NavBarContainer = styled.section`
display: flex;
flex-direction: column;
padding-left: 10px;
`;

type IconProps = {
    iconName: IconType;
    size?: string;
    color?: string;
    extraProps?: any;
};

const Icon2: React.FC<IconProps> = ({ iconName, size, color, extraProps, }) => {
    let iconComponent = React.createElement(iconName);
    const iconSize =
        size === "xsmall"
            ? "0.65rem"
            : size === "small2"
                ? "20px"
                : size === "small"
                    ? "1rem"
                    : size === "large"
                        ? "2.5rem"
                        : size === "xlarge"
                            ? "3rem"
                            : size === "xxlarge"
                                ? "3.8rem"
                                : "1.4rem";

    return (
        <div style={{ fontSize: iconSize, color: color }} {...extraProps}>{iconComponent}</div>
    );
};
