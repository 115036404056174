import { Observer } from "mobx-react";
import React from "react";
import Project from "../../../../models/Project";
import { ProjectNotes as ProjectNotesComponent } from "../../../../components/ProjectNotes";
import { SessionStore } from "../../../../stores/Session";

type ProjectReportProps = {
  currentProject: Project;
  session: SessionStore;
};

export const ProjectNotes: React.FC<ProjectReportProps> = ({
  currentProject,
  session,
}) => {
  return (
    <Observer>
      {() => (
        <ProjectNotesComponent
          key={JSON.stringify(currentProject.notes)}
          onLoad={currentProject.fetchNotes}
          isFetching={currentProject.isFetchingNotes}
          notes={currentProject.notes}
          onSubmit={currentProject.submitNote}
          onDelete={currentProject.deleteNote}
          isSubmitting={currentProject.isSubmitting}
          user={session.user}
        />
      )}
    </Observer>
  );
};
