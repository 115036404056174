import { Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router";
import styled from "styled-components";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useStores } from "../../hooks/useStores";
import { useTaskCategories } from "../../hooks/useTaskCategories";
import api from "../../services/api";
import { Task } from "../../types";
import { TaskForm } from "../TaskForm";

type Props = {};

export const TaskModal: React.FC<Props> = observer(() => {
  const { organization, session } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const queryParams = useQueryParams();

  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingTaskCategories,
    data: taskCategories = [],
  } = useTaskCategories(session.user?.organization?.uid || "");

  const { isLoading, isError, data: task, error, refetch } = useQuery(
    ["task", taskId],
    // @ts-ignore
    () => api.fetchTask(session.user?.organization?.uid, taskId),
    {
      enabled: !!taskId,
    }
  );

  const addTaskMutation = useMutation(
    // @ts-ignore
    (task) => api.addTask(session.user?.organization?.uid, task),
    {
      onSuccess: (task) => {
        queryClient.setQueryData(["tasks"] as any, (tasks) => [
          ...((tasks as Task[]) || []),
          task,
        ]);
      },
      onSettled: () => {
        queryClient.invalidateQueries("tasks" as any);
        queryClient.invalidateQueries("project-tasks" as any);
      },
    }
  );

  const updateTaskMutation = useMutation(
    // @ts-ignore
    (task) => api.updateTask(session.user?.organization?.uid, task),
    {
      onSettled: () => {
        queryClient.invalidateQueries("tasks" as any);
        queryClient.invalidateQueries("project-tasks" as any);
      },
    }
  );

  const deleteTaskMutation = useMutation(
    // @ts-ignore
    (taskId) => api.deleteTask(session.user?.organization?.uid, taskId),
    {
      onSettled: () => {
        queryClient.invalidateQueries("tasks" as any);
        queryClient.invalidateQueries("project-tasks" as any);
      },
    }
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("modal") === "task" && searchParams.has("taskId")) {
      if (session.user?.organization) {
        organization.fetchUserProjects(
          session.user?.organization?.uid,
          session.user?.uid
        );
        organization.fetchUsers(session.user?.organization?.uid);
      }

      setIsVisible(true);
      setTaskId(searchParams.get("taskId"));
    } else if (searchParams.get("modal") === "task") {
      if (session.user?.organization) {
        organization.fetchUserProjects(
          session.user?.organization?.uid,
          session.user?.uid
        );
        organization.fetchUsers(session.user?.organization?.uid);
      }
      setTaskId(null);
      setIsVisible(true);
    } else if (isVisible) {
      setIsVisible(false);
      setTaskId(null);
    }
  }, [isVisible, location, organization, refetch, session.user]);

  const handleDismiss = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete("modal");
    searchParams.delete("taskId");
    searchParams.delete("projectId");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [navigate, location]);

  const handleSubmitTask = useCallback(
    async (task) => {
      if (task.uid) {
        await updateTaskMutation.mutateAsync(task);
      } else {
        await addTaskMutation.mutateAsync(task);
      }

      handleDismiss();
    },
    [handleDismiss, updateTaskMutation, addTaskMutation]
  );

  const handleDeleteTask = useCallback(
    async (task) => {
      if (task.uid) {
        await deleteTaskMutation.mutateAsync(task.uid);
      }
      handleDismiss();
    },
    [deleteTaskMutation, handleDismiss]
  );

  const handleSubmitSubTask = useCallback(
    async (task) => {
      await addTaskMutation.mutateAsync(task);
      refetch();
    },
    [addTaskMutation, refetch]
  );

  const handleNavigateToParent = useCallback(
    (parentTaskId) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("modal", "task");
      searchParams.set("taskId", parentTaskId);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location, navigate]
  );

  return isVisible ? (
    <Modal isOpen={isVisible} size={"xl"} onClose={handleDismiss}>
      <ModalOverlay />
      {/* @ts-ignore */}
      <ModalContent maxW={"820px"}>
        <ModalCloseButton />
        <ModalBody style={{ padding: "2rem" }}>
          {task?.parentTask && (
            <StyledLinkText
              onClick={() => handleNavigateToParent(task.parentTask?.uid)}
            >
              {task.parentTask.name}
            </StyledLinkText>
          )}
          <TaskForm
            task={task || null}
            onCancel={handleDismiss}
            onSubmit={handleSubmitTask}
            projects={organization.userProjects}
            isFetchingProjects={organization.isFetchingProjects}
            users={organization.users}
            isFetchingUsers={organization.isFetchingUsers}
            onDelete={handleDeleteTask}
            defaultProjectId={queryParams.get("projectId") || undefined}
            onSubmitSubTask={handleSubmitSubTask}
            taskCategories={taskCategories}
            isFetchingTaskCategories={isLoadingTaskCategories}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  );
});

const StyledLinkText = styled(Text)`
  cursor: pointer;
  color: var(--chakra-colors-teal-600);
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.5rem;
`;
