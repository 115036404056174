import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { AbilityContext } from "../../components/Can";
import { useStores } from "../../hooks/useStores";
import { SidebarContainer, BrandIcon, BrandContainer, SubMenuItemContainer } from "./styled";
import LogoImg from "../../assets/icons/timetrap-logo.svg";
import { Icon } from "../../components/Icon";
import { Avatar } from "../../components/Avatar";
import { GoogleLogout } from "react-google-login";
import keys from "../../config/keys";
import { observer } from "mobx-react";
import { IntegrationIcon } from "../../components/IntegrationIcon";
import integrations from "../../config/integrations";
import { Nav, NavItem, NavItemIcon, NavItemText } from "../../components/Nav";
import {
  Box, Button, Menu, MenuButton, MenuItem, MenuList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { ChangelogModal } from "../../components/ChangelogModal/ChangelogModal";
import { useCookies } from "react-cookie";

type Props = {};

export enum MenuItemKeys {
  board,
  tasks,
  projects,
  dashboard,
  integrations,
  organization,
  organizationDetail,
  customers,
  users,
  roles,
  timesheet,
  subscription,
  workflows,
  webhooks,
  teamplan,
  teamplan2,
}

export const Sidebar: React.FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { session, ui } = useStores();
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["new-changelog"]);

  const [showChangelogModal, setShowChangelogModal] = useState(false);

  const toggleShowChangelogModal = useCallback(() => {
    setShowChangelogModal(!showChangelogModal);
  }, [showChangelogModal]);

  const handleNavigateTo = useCallback(
    (key) => {
      ui.setActiveScreen(key);
      ui.setIsSidebarCollapsed(false);
      ui.setIsSecondarySidebarVisible(false);
      ui.setDataSource(null);

      switch (key) {
        case MenuItemKeys.board:
          navigate(`/user/${session.user?.uid}/board`);
          break;
        case MenuItemKeys.projects:
          navigate(
            `/user/${session.user?.uid}/projects?organizationId=${session.user?.organization?.uid}`
          );
          break;
        case MenuItemKeys.organization:
          ui.setIsSidebarCollapsed(false);
          navigate(`/organization/${session.user?.organization?.uid}`);

          break;
        case MenuItemKeys.dashboard:
          navigate(`/user/${session.user?.uid}/dashboard`);
          break;
        case MenuItemKeys.integrations:
          navigate(`/user/${session.user?.uid}/integrations`);
          break;
        case MenuItemKeys.organizationDetail:
          navigate(`/organization/${session.user?.organization?.uid}`);
          break;
        case MenuItemKeys.customers:
          navigate(`/organization/${session.user?.organization?.uid}/customers`);
          break;
        case MenuItemKeys.workflows:
          navigate(`/organization/${session.user?.organization?.uid}/workflows`);
          break;
        case MenuItemKeys.subscription:
          navigate(`/organization/${session.user?.organization?.uid}/subscription`);
          break;
        case MenuItemKeys.webhooks:
          navigate(`/organization/${session.user?.organization?.uid}/webhooks`);
          break;
        case MenuItemKeys.roles:
          navigate(`/organization/${session.user?.organization?.uid}/roles`);
          break;
        case MenuItemKeys.users:
          navigate(`/organization/${session.user?.organization?.uid}/users`);
          break;
        case MenuItemKeys.timesheet:
          navigate(`/organization/${session.user?.organization?.uid}/reports/timesheet`);
          break;
        case MenuItemKeys.subscription:
          navigate(`/organization/${session.user?.organization?.uid}/subscription`);
          break;
        case MenuItemKeys.teamplan:
          navigate(`/user/${session.user?.uid}/teamplan`);
          break;
        case MenuItemKeys.teamplan2:
          navigate(`/user/${session.user?.uid}/teamplan2`);
          break;
      }
    },
    [navigate, session, ui]
  );

  const handleOpenSourcedata = useCallback(
    (sourcedata) => {
      ui.setIsSidebarCollapsed(true);
      ui.setIsSecondarySidebarVisible(true);
      ui.setDataSource(sourcedata);
    },
    [ui]
  );

  useEffect(() => {
    if (cookies["new-changelog"] && cookies["new-changelog"] === "true") {
      if (!showChangelogModal) {
        toggleShowChangelogModal();
      }
    }
  }, [cookies, showChangelogModal, toggleShowChangelogModal]);

  return (
    <>
      <SidebarContainer isCollapsed={ui.isSidebarCollapsed}>
        <BrandContainer>
          <BrandIcon>
            <img src={LogoImg} alt={"TimeTrap"} />
          </BrandIcon>
        </BrandContainer>
        <section style={{ marginTop: 15, display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", overflowY: "auto" }}>
          <Nav justifyContent="space-between" w="100%" id={"sidebar"} >
            {/* @ts-ignore */}
            <Accordion allowToggle id='main-menu'>
              <AccordionItem id="planning-item">
                <h2>
                  <AccordionButton
                    aria-current={ui.activeScreen === MenuItemKeys.board}
                    onClick={() => handleNavigateTo(MenuItemKeys.board)}
                  >
                    <NavItemIcon>
                      <Icon iconName={"HiOutlineTemplate"} color={ui.activeScreen === MenuItemKeys.board ? "green" : "black"} />
                    </NavItemIcon>
                    <Box as='span' flex='1' textAlign='left' color={ui.activeScreen === MenuItemKeys.board ? "green" : "black"} >
                      {t<string>("nav.board")}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <SubMenuItemContainer>
                    <NavItemIcon>
                      <Icon iconName={"HiCheck"} color={ui.activeScreen === MenuItemKeys.tasks ? "green" : "black"} />
                    </NavItemIcon>
                    <Box as='button' flex='1' textAlign='left' color={ui.activeScreen === MenuItemKeys.tasks ? "green" : "black"}
                      aria-current={ui.activeScreen === MenuItemKeys.tasks}
                      onClick={() => handleNavigateTo(MenuItemKeys.tasks)}
                    >
                      {t<string>("nav.tasks")}
                    </Box>
                  </SubMenuItemContainer>
                  <SubMenuItemContainer>
                    <NavItemIcon>
                      <Icon iconName={"HiCheck"} color={ui.activeScreen === MenuItemKeys.teamplan ? "green" : "black"} />
                    </NavItemIcon>
                    <Box as='button' flex='1' textAlign='left' color={ui.activeScreen === MenuItemKeys.teamplan ? "green" : "black"}
                      aria-current={ui.activeScreen === MenuItemKeys.teamplan}
                      onClick={() => handleNavigateTo(MenuItemKeys.teamplan)}
                    >
                      {t<string>("nav.teamplan")}
                    </Box>
                  </SubMenuItemContainer>
                  <SubMenuItemContainer>
                    <NavItemIcon>
                      <Icon iconName={"HiCheck"} color={ui.activeScreen === MenuItemKeys.teamplan2 ? "green" : "black"} />
                    </NavItemIcon>
                    <Box as='button' flex='1' textAlign='left' color={ui.activeScreen === MenuItemKeys.teamplan2 ? "green" : "black"}
                      aria-current={ui.activeScreen === MenuItemKeys.teamplan2}
                      onClick={() => handleNavigateTo(MenuItemKeys.teamplan2)}
                    >
                      {t<string>("nav.teamplan") + "2"}
                    </Box>
                  </SubMenuItemContainer>
                </AccordionPanel>
              </AccordionItem>


              <SubMenuItemContainer id="dashboard-item">
                <NavItemIcon>
                  <Icon iconName={"HiOutlineChartBar"} color={ui.activeScreen === MenuItemKeys.dashboard ? "green" : "black"} />
                </NavItemIcon>
                <Box as='button' flex='1' textAlign='left'
                  color={ui.activeScreen === MenuItemKeys.dashboard ? "green" : "black"}
                  aria-current={ui.activeScreen === MenuItemKeys.dashboard}
                  onClick={() => handleNavigateTo(MenuItemKeys.dashboard)}
                  fontSize={16}
                >
                  {t<string>("nav.dashboard")}
                </Box>
              </SubMenuItemContainer>

              <SubMenuItemContainer id="project-item">
                <NavItemIcon>
                  <Icon iconName={"HiOutlineLightningBolt"} color={ui.activeScreen === MenuItemKeys.projects ? "green" : "black"} />
                </NavItemIcon>
                <Box as='button' flex='1' textAlign='left'
                  color={ui.activeScreen === MenuItemKeys.projects ? "green" : "black"}
                  aria-current={ui.activeScreen === MenuItemKeys.projects}
                  onClick={() => handleNavigateTo(MenuItemKeys.projects)}
                  fontSize={16}
                >
                  {t<string>("nav.projects")}
                </Box>
              </SubMenuItemContainer>

              <AccordionItem id="organization-item">
                <h2>
                  <AccordionButton>
                    <NavItemIcon>
                      <Icon iconName={"HiOutlineOfficeBuilding"} color={ui.activeScreen === MenuItemKeys.organization ? "green" : "black"} />
                    </NavItemIcon>
                    <Box as='span' flex='1' textAlign='left'
                      color={ui.activeScreen === MenuItemKeys.organization ? "green" : "black"} >
                      {t<string>("nav.organization")}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <SubMenuItemContainer>
                    <Box as='button' flex='1' textAlign='left'
                      color={ui.activeScreen === MenuItemKeys.organizationDetail ? "green" : "black"}
                      aria-current={ui.activeScreen === MenuItemKeys.organizationDetail}
                      onClick={() => handleNavigateTo(MenuItemKeys.organizationDetail)}
                    >
                      {t<string>("screens.organization.nav.organizationDetail")}
                    </Box>
                  </SubMenuItemContainer>
                  <SubMenuItemContainer>
                    <Box as='button' flex='1' textAlign='left'
                      color={ui.activeScreen === MenuItemKeys.customers ? "green" : "black"}
                      aria-current={ui.activeScreen === MenuItemKeys.customers}
                      onClick={() => handleNavigateTo(MenuItemKeys.customers)}
                    >
                      {t<string>("screens.customers.titleList")}
                    </Box>
                  </SubMenuItemContainer>
                  <Accordion allowToggle id="project">
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                        >
                          <Box as='span' flex='1' textAlign='left'
                          >
                            {t<string>("screens.organization.nav.projectSetup")}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <SubMenuItemContainer>
                          <Box as='button' flex='1' textAlign='left'
                            color={ui.activeScreen === MenuItemKeys.workflows ? "green" : "black"}
                            aria-current={ui.activeScreen === MenuItemKeys.workflows}
                            onClick={() => handleNavigateTo(MenuItemKeys.workflows)}
                          >
                            {t<string>("screens.workflows.title")}
                          </Box>
                        </SubMenuItemContainer>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                  <Accordion allowToggle id="user-management">
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left'>
                            {t<string>("screens.organization.nav.userManagement")}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <SubMenuItemContainer>
                          <Box as='button' flex='1' textAlign='left'
                            color={ui.activeScreen === MenuItemKeys.roles ? "green" : "black"}
                            aria-current={ui.activeScreen === MenuItemKeys.roles}
                            onClick={() => handleNavigateTo(MenuItemKeys.roles)}
                          >
                            {t<string>("screens.roles.titleList")}
                          </Box>
                        </SubMenuItemContainer>
                        <SubMenuItemContainer>
                          <Box as='button' flex='1' textAlign='left'
                            color={ui.activeScreen === MenuItemKeys.users ? "green" : "black"}
                            aria-current={ui.activeScreen === MenuItemKeys.users}
                            onClick={() => handleNavigateTo(MenuItemKeys.users)}
                          >
                            {t<string>("screens.users.titleList")}
                          </Box>
                        </SubMenuItemContainer>
                        <SubMenuItemContainer>
                          <Box as='button' flex='1' textAlign='left'
                            color={ui.activeScreen === MenuItemKeys.timesheet ? "green" : "black"}
                            aria-current={ui.activeScreen === MenuItemKeys.timesheet}
                            onClick={() => handleNavigateTo(MenuItemKeys.timesheet)}
                          >
                            {t<string>("screens.timesheet.title")}
                          </Box>
                        </SubMenuItemContainer>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                  <Accordion allowToggle id="bill-sub">
                    <AccordionItem>
                      <h2>
                        <AccordionButton >
                          <Box as='span' flex='1' textAlign='left'>
                            {t<string>("screens.organization.nav.subscription")}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <SubMenuItemContainer>
                          <Box as='button' flex='1' textAlign='left'
                            color={ui.activeScreen === MenuItemKeys.subscription ? "green" : "black"}
                            aria-current={ui.activeScreen === MenuItemKeys.subscription}
                            onClick={() => handleNavigateTo(MenuItemKeys.subscription)}
                          >
                            {t<string>("screens.subscription.title")}
                          </Box>
                        </SubMenuItemContainer>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                  <Accordion allowToggle id="advance-item">
                    <AccordionItem>
                      <h2>
                        <AccordionButton >
                          <Box as='span' flex='1' textAlign='left'>
                            {t<string>("screens.organization.nav.advanced")}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <SubMenuItemContainer>
                          <Box as='button' flex='1' textAlign='left'
                            color={ui.activeScreen === MenuItemKeys.webhooks ? "green" : "black"}
                            aria-current={ui.activeScreen === MenuItemKeys.webhooks}
                            onClick={() => handleNavigateTo(MenuItemKeys.webhooks)}
                          >
                            {t<string>("screens.webhooks.title")}
                          </Box>
                        </SubMenuItemContainer>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </AccordionPanel>
              </AccordionItem>
              <SubMenuItemContainer>
                <NavItemIcon>
                  <Icon iconName={"HiSwitchHorizontal"} color={ui.activeScreen === MenuItemKeys.integrations ? "green" : "black"} />
                </NavItemIcon>
                <Box as='button' flex='1' textAlign='left'
                  color={ui.activeScreen === MenuItemKeys.integrations ? "green" : "black"}
                  aria-current={ui.activeScreen === MenuItemKeys.integrations}
                  onClick={() => handleNavigateTo(MenuItemKeys.integrations)}
                  fontSize={16}
                >
                  {t<string>("nav.integrations")}
                </Box>
              </SubMenuItemContainer>
            </Accordion>
            {/* TODO: Refactor with Memo variable */}
            {ui.activeScreen === MenuItemKeys.board && session.accessToken && (
              <NavItem
                key={"gcalendar"}
                isActive={"gcalendar" === ui.dataSource}
                onClick={() => handleOpenSourcedata("gcalendar")}
              >
                <NavItemIcon>
                  <IntegrationIcon service={"gcalendar"} />
                </NavItemIcon>
                <NavItemText>{"Google Calendar"}</NavItemText>
              </NavItem>
            )}
            {ui.activeScreen === MenuItemKeys.board &&
              session.user?.integrations.map((integration) => (
                <NavItem
                  key={integration.service}
                  isActive={integration.service === ui.dataSource}
                  onClick={() => handleOpenSourcedata(integration.service)}
                >
                  <NavItemIcon>
                    <IntegrationIcon service={integration.service} />
                  </NavItemIcon>
                  <NavItemText>
                    {integrations[integration.service].title}
                  </NavItemText>
                </NavItem>
              ))}
          </Nav>
          <Nav w="100%" flexDirection={"row"}>
            <NavItem>
              <Menu isLazy>
                <MenuButton>
                  <Avatar
                    src={session.user?.imageUrl}
                    values={session.user?.nominative.split(" ") || []}
                    alt={session.user?.nominative}
                  />
                </MenuButton>
                <MenuList zIndex={999999} minW={"8rem"}>
                  {/* MenuItems are not rendered unless Menu is open */}
                  {session.user && (
                    <MenuItem
                      onClick={() =>
                        navigate(`/user/${session.user?.uid}/profile`)
                      }
                    >
                      {t<string>("menu.profile")}
                    </MenuItem>
                  )}
                  <GoogleLogout
                    clientId={keys.GOOGLE_CLIENT_ID}
                    onLogoutSuccess={() => {
                      session.resetSession();
                      navigate("/", { replace: true });
                    }}
                    render={(renderProps) => (
                      <MenuItem onClick={renderProps.onClick}>
                        {t<string>("screens.board.logoutButton")}
                      </MenuItem>
                    )}
                  />
                </MenuList>
              </Menu>
            </NavItem>
            <NavItem>
              <a
                target={"_blank"}
                href={keys.KNOWLEDGEBASE_URL}
                rel={"noopener noreferrer"}
                title={"TimeTrap Knowledgebase"}
              >
                <NavItemIcon>
                  <Icon
                    iconName={"HiInformationCircle"}
                    color={"#6FCDBE"}
                    size={"large"}
                  />
                </NavItemIcon>
              </a>
            </NavItem>

            <NavItem>
              <Button
                variant={"link"}
                colorScheme={"facebook"}
                onClick={toggleShowChangelogModal}
              >
                v 0.23.0
              </Button>
            </NavItem>
          </Nav>
        </section>
      </SidebarContainer>
      <ChangelogModal
        userHasAcceptedCookies={Boolean(session.userHasAcceptedCookies)}
        t={t}
        isVisible={showChangelogModal}
        toggleModal={toggleShowChangelogModal}
      />
    </>
  );
});
