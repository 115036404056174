import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { InvitedUser, Role } from "../../../types";
import { InviteUserForm } from "../InviteUserForm";

type Props = {
  roles: Role[];
  isFetchingRoles: boolean;
  isVisible: boolean;
  onDismiss: () => void;
  onLoad: () => void;
  onSubmit: (invitedUser: InvitedUser) => void;
};

export const InviteUserModal: React.FC<Props> = ({
  roles,
  isFetchingRoles,
  isVisible,
  onSubmit,
  onLoad,
  onDismiss,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t<string>("screens.users.inviteUser")}</ModalHeader>
        <ModalBody>
          <InviteUserForm
            roles={roles}
            isFetchingRoles={isFetchingRoles}
            onCancel={onDismiss}
            onSubmit={onSubmit}
            onLoad={onLoad}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
