import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Avatar,
  Text,
  Badge,
} from "@chakra-ui/react";
import { User } from "../../../types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  users: User[];
};

export const UsersTable: React.FC<Props> = ({ users }) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">{t<string>("screens.users.nominative")}</Th>
          <Th color="gray.400">{t<string>("screens.roles.title")}</Th>
          <Th color="gray.400">{t<string>("screens.users.status")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {users.map((user) => (
          <Tr key={user.uid}>
            <Td minWidth={{ sm: "250px" }} pl="0px">
              <Flex align="center" minWidth="100%" flexWrap="nowrap">
                <Avatar
                  src={user.imageUrl}
                  borderRadius="12px"
                  me="18px"
                  size="sm"
                />
                <Flex direction="column">
                  <Link to={`./${user.uid}`}>
                    <Text fontSize="md" fontWeight="medium" minWidth="100%">
                      {user.nominative}
                    </Text>
                  </Link>
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    {user.email}
                  </Text>
                </Flex>
              </Flex>
            </Td>
            <Td>{user.role?.name}</Td>
            <Td>
              <Badge
                bg={
                  user.status === "active"
                    ? "green.300"
                    : user.status === "invited"
                    ? "yellow.300"
                    : "gray.200"
                }
              >
                {user.status}
              </Badge>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
