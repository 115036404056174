import { Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ProjectReport as TProjectReport } from "../../types";
import { sec2time } from "../../utils/dateTimeFormat";
import { Avatar } from "../Avatar";

type Props = {
  report: TProjectReport[];
};

export const ProjectReportTable: React.FC<Props> = ({ report }) => {
  const { t } = useTranslation();
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th color="gray.400">{t<string>("screens.projects.reports.user")}</Th>
          <Th color="gray.400">{t<string>("screens.projects.reports.task")}</Th>
          <Th color="gray.400">
            {t<string>("screens.projects.reports.startTime")}
          </Th>
          <Th color="gray.400">
            {t<string>("screens.projects.reports.endTime")}
          </Th>
          <Th color="gray.400">
            {t<string>("screens.projects.reports.elapsedTime")}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {report.map((record, index) => (
          <Tr key={record.title + index + record.startTime}>
            <Td>
              <Avatar
                src={record.user.imageUrl}
                values={[record.user.nominative]}
                alt={record.user.nominative}
              />
            </Td>
            <Td>
              <Text>{record.title}</Text>
            </Td>
            <Td>
              <div>{moment(record.startTime).format("DD/MM/YY HH:mm")}</div>
            </Td>
            <Td>
              <div>{moment(record.endTime).format("DD/MM/YY HH:mm")}</div>
            </Td>
            <Td>
              <div>
                {record.elapsedTime
                  ? sec2time(record.elapsedTime, "HH:MM")
                  : "-"}
              </div>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
