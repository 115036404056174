import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Field, Form, FormikHelpers, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { FixMeLater, RecurrenceRule, TaskCategory } from "../types";
import moment from "moment";
import TimeField from "./TimeField";
import { sec2time, time2sec } from "../utils/dateTimeFormat";
import Project from "../models/Project";
import { RecurrenceForm } from "./RecurrenceForm";
import { Heading, HStack, Stack } from "@chakra-ui/layout";
import { RadioCardGroup } from "./RadioCardGroup";
import { Select } from "./Select";
import { Input } from "@chakra-ui/input";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { DatePicker } from "./DatePicker";
import { IntegrationIcon } from "./IntegrationIcon";
import { CategorySelect } from "./CategorySelect";
import * as Yup from "yup";
import { Text, Box } from "@chakra-ui/react"
import dayjs from "dayjs";
import { IconType } from "react-icons";
import { PiCalendarCheck } from "react-icons/pi";
import { TbCalendarDue } from "react-icons/tb";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useStores } from "../hooks/useStores";
import api from "../services/api";
import { ReactComponent as PhCalanderCehck } from "../assets/icons/ph_calendar-check.svg"
import { ReactComponent as PhCalanderDot } from "../assets/icons/ph_calendar-dot.svg"

interface Values {
  elapsedTime: number;
  title: string;
  startTime: Date;
  endTime: Date;
  color: string;
  projectId: string | null;
  categoryId: string | null;
  source: string | null;
  sourceId: string | null;
  eventType: string;
  taskLinkId: string | null;
  calanderId: string | null;
}

type EventFormProps = {
  data: FixMeLater;
  projects: Project[];
  isFetchingProjects: boolean;
  taskCategories: TaskCategory[];
  isFetchingTaskCategories: boolean;
  onSubmit: (data: FixMeLater) => any;
  onCancel: () => any;
  onDelete?: (data: FixMeLater) => any;
  isSubmitting?: boolean;
};

export const EventForm: React.FC<EventFormProps> = ({
  data,
  projects,
  isFetchingProjects,
  taskCategories,
  isFetchingTaskCategories,
  onSubmit,
  onCancel,
  onDelete,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const { session } = useStores();
  console.log("event ->\n ", data);


  const { isLoading, isError, data: tasksData, error } = useQuery(
    // ["tasks", session.user?.organization?.uid, userId],
    // // @ts-ignore
    // () => api.fetchTasks(session.user?.organization?.uid, null, userId),
    {
      queryKey: ["tasks", session.user?.organization?.uid, userId],
      queryFn: () => api.fetchTasks(session.user?.organization?.uid!, undefined, userId),
      onError: (err) => {
        console.log("errro -> \n", err);
        alert("error to fetchs task!")
      },
      // onSuccess: (suc) => {
      //   console.log("suc -> \n", suc);
      // }
    }

  );

  const [isModalRecurrenceVisible, setIsModalRecurrenceVisible] = useState(
    false
  );

  const [recurrenceRule, setRecurrenceRule] = useState<RecurrenceRule | null>(
    data?.recurrence || null
  );

  const groupedProjects = useMemo(() => {
    const groupedProjects: any = [{ id: "", label: t("eventTile.noProject") }];

    for (let project of projects) {
      if (project.category) {
        if (
          !groupedProjects.find(
            (group) => group.label === project.category?.name
          )
        ) {
          groupedProjects.push({ label: project.category?.name, options: [] });
        }
      } else {
        if (
          !groupedProjects.find(
            (group) =>
              group.label === t("screens.board.event.noProjectCategory")
          )
        ) {
          groupedProjects.push({
            label: t("screens.board.event.noProjectCategory"),
            options: [],
          });
        }
      }

      for (let i = 0; i < groupedProjects.length; i++) {
        if (
          groupedProjects[i].label ===
          (project.category?.name || t("screens.board.event.noProjectCategory"))
        ) {
          groupedProjects[i].options.push({
            value: project.uid,
            label: `${project.title} (${project.customer?.nominative})`,
          });
        }
      }
    }

    return groupedProjects;
  }, [projects, t]);

  const tasks = useMemo(() => {
    const task_list: any = [
      {
        id: "",
        value: "",
        label: t("screens.board.event.noTask"),
      },
    ];
    if (tasksData) {
      for (let taskItem of tasksData) {
        task_list.push({
          id: taskItem.uid,
          label: taskItem.name,
          value: taskItem.uid,
          complete: taskItem.complete
        });
      }
    }
    return (task_list || [])
  }, [tasksData]);

  const taskCategoriesOpts = useCallback(
    (eventType: string) => {
      const categories: any = [
        {
          id: "",
          value: null,
          label: t("screens.board.event.noProjectCategory"),
        },
      ];

      for (let taskCategory of taskCategories) {
        if (
          (eventType === "planning" && taskCategory.isUsedForPlanning) ||
          (eventType === "activity" && taskCategory.isUsedForTasks)
        ) {
          categories.push({
            value: taskCategory.uid,
            label: taskCategory.name,
            color: taskCategory.color,
          });
        }
      }
      return categories;
    },
    [t, taskCategories]
  );

  useEffect(() => {
    // alert(JSON.stringify(data))
    setRecurrenceRule(data?.recurrence || null);
  }, [data]);

  const recurrenceLabel = useMemo(() => {
    let label = "";

    if (!recurrenceRule) {
      label = t("eventTile.doesNotRepeat");
    } else {
      label = `${t<string>("eventTile.repeatEvery")} ${recurrenceRule.every
        } ${t<string>(`eventTile.${recurrenceRule.frequency}`)} `;
      if (recurrenceRule.dayOfWeeks?.length > 0) {
        label += ` ${t<string>(
          "eventTile.on"
        )} ${recurrenceRule.dayOfWeeks
          .map((dayOfWeek) => t(`dayOfWeek.${dayOfWeek}`))
          .join(", ")}`;
      }
      if (recurrenceRule.until) {
        label += ` ${t<string>("eventTile.untilDate")} ${moment(
          recurrenceRule.until
        ).format("DD/MM/YYYY")} `;
      }
      if (recurrenceRule.repeat) {
        label += `, ${t<string>("eventTile.repeatLabel", {
          repeat: recurrenceRule.repeat,
        })}`;
      }
    }

    return label;
  }, [recurrenceRule, t]);

  const handleCancelModal = useCallback(
    () => setIsModalRecurrenceVisible(false),
    [setIsModalRecurrenceVisible]
  );

  const handleSubmitRecurrence = useCallback(
    (rule: RecurrenceRule) => {
      setRecurrenceRule(rule);
      setIsModalRecurrenceVisible(false);
    },
    [setRecurrenceRule]
  );

  const handleSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const projectId =
        values.projectId && values.projectId?.length > 0
          ? values.projectId
          : null;

      const event = { ...values, projectId };

      if (data.id && data.id !== "null") {
        event["uid"] = data.id;
      }
      if (event["id"] === "null") event["id"] = null;

      if (data.extendedProps) {
        event["extendedProps"] = data.extendedProps;
        if (data.extendedProps.recurrenceId)
          event["recurrenceId"] = data.extendedProps.recurrenceId;
      }

      if (recurrenceRule && !data.extendedProps) {
        event["recurrence"] = recurrenceRule;
      }
      if (values.taskLinkId) {
        event["taskLinkId"] = values.taskLinkId
      }
      if (data.extendedProps?.source === "gcalendar") {
        event["source"] = "gcalendar";
        event["calanderId"] = data.extendedProps?.calanderId;
      } else {
        event["source"] = "timetrap"
      }

      setSubmitting(false);
      onSubmit(event);
    },
    [onSubmit, recurrenceRule, data]
  );

  const getOptions = useCallback((type) => {
    let options: { value: string, label: string }[] = [];

    if (!data?.id) {
      options = [
        {
          value: "planning",
          label: t("screens.board.formTaskTypePlanning"),
        },
        {
          value: "activity",
          label: t("screens.board.formTaskTypeActivity"),
        },
      ];
    } else if (type === "planning") {
      options = [
        {
          value: "planning",
          label: t("screens.board.formTaskTypePlanning"),
        },
      ];
    } else if (type === "activity") {
      options = [
        {
          value: "activity",
          label: t("screens.board.formTaskTypeActivity"),
        },
      ];
    } else {
      // Fallback case: show both options with task as default
      options = [
        {
          value: "planning",
          label: t("screens.board.formTaskTypePlanning"),
        },
        {
          value: "activity",
          label: t("screens.board.formTaskTypeActivity"),
        },
      ];
    }

    return options
  }, [data]);

  const getSelectedProject: any = (projectId) => {
    if (!projectId) return "";

    const project =
      data.extendedProps?.project &&
        data.extendedProps?.project.uid === projectId
        ? data.extendedProps?.project
        : projects.find((project) => project.uid === projectId);

    return project
      ? {
        value: project.uid,
        label: project.title,
      }
      : "";
  };

  const getSelectedTask: any = (taskId) => {
    if (!taskId) return "";
    const findTask = tasks.find((taskI) => taskI.value === taskId);
    return findTask ?? "";
  };

  const getSelectedTaskCategory: any = (
    taskCategoryId: TaskCategory["uid"] | null
  ) => {
    if (!taskCategoryId) return "";

    const taskCategory =
      data.extendedProps?.category &&
        data.extendedProps?.category.uid === taskCategoryId
        ? data.extendedProps?.category
        : taskCategories.find(
          (taskCategory) => taskCategory.uid === taskCategoryId
        );

    return taskCategory
      ? {
        value: taskCategory.uid,
        label: taskCategory.name,
        color: taskCategory.color,
      }
      : "";
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      height: "var(--chakra-sizes-10)",
      borderRadius: "var(--chakra-radii-md)",
      borderColor: "inherit",
    }),
  };

  const FormControlsComponent = (props: FormikProps<Values>) => {
    useEffect(() => {
      const takeDiffrence = dayjs(props.values.endTime).diff(props.values.startTime, 'second')
      props.setFieldValue("elapsedTime", takeDiffrence)
    }, [props.values.startTime, props.values.endTime, props.setFieldValue]);

    useEffect(() => {
      if (props.values.taskLinkId && tasksData?.length) {
        const findTask = tasksData.find(e => e.uid === props.values.taskLinkId);
        if (findTask) {
          // alert('record find');
          if (findTask.project?.uid) {
            props.setFieldValue("projectId", findTask.project?.uid)
          } else {
            props.setFieldValue("projectId", null)
          }
          if (findTask.category?.uid) {
            props.setFieldValue('categoryId', findTask.category?.uid)
          } else {
            props.setFieldValue("categoryId", null)
          }
        }
      }
    }, [props.values.taskLinkId])

    return (
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <Field type={"hidden"} name={"id"} />

          <HStack spacing={"0.7rem"}>
            <Box as='section' display={"flex"} justifyContent={"center"} alignItems={"center"}>
              {props.values.eventType === "planning" ? (
                <PhCalanderDot height={40} width={40} />
              ) : (
                <PhCalanderCehck height={40} width={40} />
              )}
              <Text as={"h4"} fontSize={"xl"}>
                {data?.id
                  ? props.values.eventType === "planning" ? t("screens.board.event.editEventTitle") + t("screens.board.event.planning") : t("screens.board.event.editEventTitle") + t("screens.board.event.activity")
                  : props.values.eventType === "planning" ? t("screens.board.event.addEventTitle") + t("screens.board.event.planning") : t("screens.board.event.addEventTitle") + t("screens.board.event.activity")}
              </Text>
            </Box>
            <Box as='section' display={"flex"} justifyContent={"center"} alignItems={"center"} flex={1}>
              {!data?.id && <Field name={"eventType"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => {
                  const options = getOptions(field.value);
                  return (
                    <RadioCardGroup
                      name={"type"}
                      value={field.value}
                      options={options}
                      onChange={(value) => setFieldValue(field.name, value)}
                    />
                  );
                }}
              </Field>}
            </Box>
          </HStack>
          <Stack spacing={"1rem"} paddingTop={"2rem"}>
            <Field id={"title"} name={"title"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="title">
                    {t<string>("screens.board.formTitle")}
                  </StyledFormLabel>
                  <Input
                    name={field.name}
                    value={field.value === "null" ? t('screens.board.formEventUntitledTitle') : field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field id={"taskLinkId"} name={"taskLinkId"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor="taskLinkId">
                    Tasks
                  </StyledFormLabel>
                  <Select
                    options={tasks?.filter(e => !e.complete)}
                    value={getSelectedTask(field.value)}
                    isLoading={isLoading}
                    onChange={(option) =>
                      setFieldValue(field.name, option?.value)
                    }
                    onBlur={field.onBlur}
                    styles={selectStyles}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Field name={"projectId"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor="projectId">
                        {t<string>("screens.board.project")}
                      </StyledFormLabel>
                      <Select
                        options={groupedProjects}
                        value={getSelectedProject(field.value)}
                        isLoading={isFetchingProjects}
                        onChange={(option) => {
                          if (tasksData) {
                            const getTask = tasksData.find(e => e.project?.uid === option?.value);
                            if (getTask?.uid !== props.values.taskLinkId) {
                              setFieldValue('taskLinkId', "")
                            }
                          }
                          setFieldValue(field.name, option?.value)
                        }}
                        onBlur={field.onBlur}
                        styles={selectStyles}
                      />

                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  marginLeft: "1rem",
                }}
              >
                <Field name={"categoryId"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <FormControl isInvalid={meta.error && meta.touched}>
                      <StyledFormLabel htmlFor="categoryId">
                        {t<string>("screens.board.formColor")}
                      </StyledFormLabel>
                      <CategorySelect
                        options={taskCategoriesOpts(props.values.eventType)}
                        value={getSelectedTaskCategory(field.value)}
                        isLoading={isFetchingTaskCategories}
                        onChange={(option) => {
                          if (!props.values.title) {
                            setFieldValue("title", option?.label);
                          }
                          setFieldValue(field.name, option?.value);
                        }}
                        onBlur={field.onBlur}
                      />

                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </div>
            </div>

            {/* <Field name={"color"}>
            {({
              field, // { name, value, onChange, onBlur }
              form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => (
              <FormControl isInvalid={meta.error && meta.touched}>
                <StyledFormLabel htmlFor={"color"}>
                  {t<string>("screens.board.formColor")}
                </StyledFormLabel>
                <CirclePicker
                  // name={field.name}
                  // @ts-ignore
                  color={{ hex: field.value }}
                  onChangeComplete={(color) =>
                    setFieldValue(field.name, color.hex)
                  }
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field> */}

            <HStack wrap="wrap" alignItems={"baseline"}>
              <Field name="startTime">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl
                    isInvalid={meta.error && meta.touched}
                    // style={{ width: "auto !important" }}
                    style={{
                      width: "11rem",
                    }}
                  >
                    <StyledFormLabel htmlFor={"startTime"}>
                      {t<string>("eventTile.day")}
                    </StyledFormLabel>
                    <DatePicker
                      // @ts-ignore
                      dateFormat={"dd/MM/yyyy"}
                      selectedDate={
                        props.values.startTime
                          ? new Date(props.values.startTime)
                          : undefined
                      }
                      onChange={(date) => {
                        setFieldValue("startTime", date || null);
                        setFieldValue("endTime", date || null);
                      }}
                    />
                    {/* <DatePicker
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                    value={moment(props.values.startTime)}
                    onChange={(date) => {
                      setFieldValue("startTime", date?.toDate() || null);
                      setFieldValue("endTime", date?.toDate() || null);
                    }}
                  /> */}
                  </FormControl>
                )}
              </Field>
              <Field name={"startTime"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl
                    isInvalid={meta.error && meta.touched}
                    style={{
                      width: "11rem",
                    }}
                  >
                    <StyledFormLabel htmlFor={"startTime"}>
                      {t<string>("eventTile.fromTime")}
                    </StyledFormLabel>

                    <DatePicker
                      // @ts-ignore
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={"HH:mm"}
                      timeFormat={"HH:mm"}
                      selectedDate={
                        props.values.startTime
                          ? new Date(props.values.startTime)
                          : undefined
                      }
                      timeCaption={t<string>("eventTile.fromTime")}
                      onChange={(date) => {
                        // Workaround for DatePicker bug when changing time manually
                        let newDate = new Date(date);
                        if (props.values.startTime) {
                          newDate.setFullYear(
                            props.values.startTime.getFullYear()
                          );
                          newDate.setMonth(
                            props.values.startTime.getMonth()
                          );
                          newDate.setDate(props.values.startTime.getDate());
                        }
                        setFieldValue("startTime", newDate || null);
                      }}
                    />
                  </FormControl>
                )}
              </Field>
              <Field name={"endTime"}>
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }) => (
                  <FormControl
                    isInvalid={meta.error}
                    style={{
                      width: "11rem",
                    }}
                  >
                    <StyledFormLabel htmlFor={"endTime"}>
                      {t<string>("eventTile.toTime")}
                    </StyledFormLabel>

                    <DatePicker
                      // @ts-ignore
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat={"HH:mm"}
                      timeFormat={"HH:mm"}
                      selectedDate={
                        props.values.endTime
                          ? new Date(props.values.endTime)
                          : undefined
                      }
                      timeCaption={t<string>("eventTile.toTime")}
                      onChange={(date) => {
                        // Workaround for DatePicker bug when changing time manually
                        let newDate = new Date(date);
                        if (props.values.endTime) {
                          newDate.setFullYear(
                            props.values.endTime.getFullYear()
                          );
                          newDate.setMonth(props.values.endTime.getMonth());
                          newDate.setDate(props.values.endTime.getDate());
                        }
                        setFieldValue("endTime", newDate || null);
                        const didf = dayjs(newDate).diff(props.values.startTime, 'second')
                        if (didf) {
                          setFieldValue("elapsedTime", didf);
                        } else {
                          setFieldValue("elapsedTime", 0);
                        }
                      }}
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {props.values.eventType !== "planning" && (
                <Field name={"elapsedTime"}>
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => {
                    // console.log(" => ", field.value);

                    // const [deValue, setDfulalVlaue] = useState(0);
                    // const takeDiffrence = dayjs(props.values.endTime).diff(props.values.startTime, 'second')

                    // if(field.value === 0)
                    return (
                      <FormControl
                        isInvalid={meta.error && meta.touched}
                        style={{
                          width: "11rem",
                        }}
                      >
                        <StyledFormLabel htmlFor={"elapsedTime"}>
                          {t<string>("screens.board.elapsedTime")}
                        </StyledFormLabel>
                        <TimeField
                          value={sec2time(field.value || 0)}
                          onChange={(_, value) => {
                            const filedSecond = time2sec(value);
                            setFieldValue(field.name, filedSecond)
                            const newEndDate = dayjs(props.values.startTime).set('millisecond', filedSecond * 1000)
                            setFieldValue("endTime", newEndDate.toDate() || null);
                          }
                          }
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              )}
            </HStack>

            <div style={{ marginTop: "0.5rem", marginBottom: "0.75rem" }}>
              <Button
                variant={"ghost"}
                onClick={() => setIsModalRecurrenceVisible(true)}
                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              >
                {recurrenceLabel}
              </Button>
            </div>
          </Stack>
          <div
            style={{
              display: "flex",
              marginTop: "1em",
              justifyContent: "space-between",
            }}
          >
            <div>
              {data && data.id && onDelete && (
                <Button colorScheme={"red"} onClick={() => onDelete(data)}>
                  {t<string>("screens.board.deleteButton")}
                </Button>
              )}
            </div>
            <div>
              <Button variant={"ghost"} onClick={onCancel}>
                {t<string>("screens.board.cancelButton")}
              </Button>

              <Button
                colorScheme={"teal"}
                type={"submit"}
                disabled={
                  Object.keys(props.errors).length !== 0 || isSubmitting
                }
              >
                {t<string>("screens.board.confirmButton")}
              </Button>
            </div>
          </div>
        </>
      </Form>
    )
  }

  return (
    <>
      <Formik
        key={JSON.stringify(data)}
        initialValues={{
          id: data ? data.id : "",
          title: data ? data.title : "",
          startTime: data ? data.startTime : "",
          endTime: data ? data.endTime : "",
          color: data ? data.color : "",
          elapsedTime: data ? data.extendedProps?.elapsedTime : 0,
          eventType: data?.extendedProps?.eventType || "activity",
          projectId: data ? data.extendedProps?.projectId : "",
          categoryId: data ? data.extendedProps?.category?.uid : "",
          source: data ? data.source : null,
          taskLinkId: data ? data.extendedProps?.taskLinkId : null,
          sourceId: data ? data.sourceId : null,
          calanderId: data ? data.calanderId : null,
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          startTime: Yup.date(),
          endTime: Yup.date()
            .min(Yup.ref("startTime"), t("eventTile.validations.endTime"))
            .test({
              name: "same",
              exclusive: false,
              params: {},
              message: t("eventTile.validations.endTime"),
              test: function (value) {
                const startTime = moment(this.parent.startTime);
                const endDate = moment(value);
                return !moment(startTime).isSame(moment(endDate));
              },
            }),
        })}
      >
        {(props) => (<FormControlsComponent {...props} />)}
      </Formik>
      {isModalRecurrenceVisible && (
        <Modal isOpen={isModalRecurrenceVisible} onClose={handleCancelModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t<string>("eventTile.recurrence")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RecurrenceForm
                rule={recurrenceRule}
                onSubmit={handleSubmitRecurrence}
                onCancel={handleCancelModal}
              //onDelete={handleDelete}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;

const LinkedSource = styled.div``;

type IconProps = {
  iconName: IconType;
  size?: string;
  color?: string;
  extraProps?: any;
};

const Icon2: React.FC<IconProps> = ({ iconName, size, color, extraProps, }) => {
  let iconComponent = React.createElement(iconName);
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small2"
        ? "20px"
        : size === "small"
          ? "1rem"
          : size === "large"
            ? "2.5rem"
            : size === "xlarge"
              ? "3rem"
              : size === "xxlarge"
                ? "3.8rem"
                : "1.4rem";

  return (
    <div style={{ fontSize: iconSize, color: color }} {...extraProps}>{iconComponent}</div>
  );
};
