import { SimpleGrid } from "@chakra-ui/react";
import { TFunction } from "i18next";
import Project from "../../../models/Project";
import { Avatar } from "../../Avatar";
import { GridCell } from "../GridCell/GridCell";

type HeaderGridProps = {
  currentProject: Project;
  t: TFunction;
};

export const HeaderGrid: React.FC<HeaderGridProps> = ({
  currentProject,
  t,
}) => {
  return (
    <>
      <SimpleGrid
        columns={currentProject.startDate && currentProject.endDate ? 5 : 3}
        spacing={10}
        style={{ margin: "0rem 1rem" }}
      >
        <GridCell
          label={t<string>("screens.projects.customer")}
          value={currentProject.customer?.nominative || ""}
          helpText={
            currentProject.contactName && currentProject.contactSurname
              ? `${currentProject.contactName} ${currentProject.contactSurname}`
              : ""
          }
        />

        <GridCell
          label={t<string>("screens.projects.status")}
          value={currentProject.status?.name || ""}
        />

        {currentProject.externalReference && (
          <GridCell
            label={t<string>("screens.projects.externalReference")}
            value={currentProject.externalReference}
          />
        )}

        {currentProject.startDate && (
          <GridCell
            label={t<string>("screens.projects.startDate")}
            value={currentProject.startDate.toDateString()}
          />
        )}

        {currentProject.endDate && (
          <GridCell
            label={t<string>("screens.projects.endDate")}
            value={currentProject.endDate.toDateString()}
          />
        )}

        {currentProject.closingDate && (
          <GridCell
            label={t<string>("screens.projects.closingDate")}
            value={currentProject.closingDate.toDateString()}
          />
        )}
      </SimpleGrid>
      <SimpleGrid style={{ margin: "0.5rem 1rem" }} columns={2}>
        {currentProject.projectManager && (
          <GridCell
            label={t<string>("screens.projects.projectManager")}
            value={
              <Avatar
                src={currentProject.projectManager.imageUrl}
                values={[currentProject.projectManager.nominative]}
                alt={currentProject.projectManager.nominative}
              />
            }
          />
        )}

        {currentProject.members && currentProject.members.length > 0 && (
          <GridCell
            label={t<string>("screens.projects.team")}
            value={currentProject.members.map((member) => (
              <Avatar
                src={member.imageUrl}
                values={[member.nominative]}
                alt={member.nominative}
              />
            ))}
          />
        )}
      </SimpleGrid>
    </>
  );
};
