import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Category } from "../../../components/Category";
import { TaskCategoryForm } from "../../../components/TaskCategoryForm";
import api from "../../../services/api";
import { Organization, TaskCategory } from "../../../types";

type Props = {
  organizationId: Organization["uid"];
};

export const TaskCategories: React.FC<Props> = ({ organizationId }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const { data: projectCategories } = useQuery(
    ["task-categories", organizationId],
    // @ts-ignore
    () => api.fetchTaskCategories(organizationId)
  );

  const selectedCategory = useMemo(() => {
    return selectedId
      ? (projectCategories || []).find(
        (category) => category.uid === selectedId
      )
      : null;
  }, [projectCategories, selectedId]);

  const addCategoryMutation = useMutation(
    // @ts-ignore
    (category: TaskCategory) =>
      api.createTaskCategory(organizationId, category),
    {
      onSuccess: (category) => {
        queryClient.setQueryData(["task-categories"] as any, (categories) => [
          ...((categories as TaskCategory[]) || []),
          category,
        ]);
      },
      onSettled: () => {
        queryClient.invalidateQueries("task-categories" as any);
      },
    }
  );

  const updateCategoryMutation = useMutation(
    // @ts-ignore
    (category: TaskCategory) =>
      api.updateTaskCategory(organizationId, category),
    {
      onSettled: () => {
        queryClient.invalidateQueries("task-categories" as any);
      },
    }
  );

  const deleteCategoryMutation = useMutation(
    // @ts-ignore
    (categoryId: TaskCategory["uid"]) =>
      api.deleteTaskCategory(organizationId, categoryId),
    {
      onSettled: () => {
        queryClient.invalidateQueries("task-categories" as any);
      },
    }
  );

  const handleModalClose = useCallback(() => {
    onModalClose();
    setSelectedId(null);
  }, [onModalClose]);

  const handleSubmitCategory = useCallback(
    async (category: TaskCategory) => {
      try {
        category["is_used_for_planning"] = category.isUsedForPlanning;
        category["is_used_for_tasks"] = category.isUsedForTasks;

        for (const field of ["isUsedForTasks", "isUsedForPlanning"]) {
          delete category[field];
        }

        if (category.uid.length === 0) {
          await addCategoryMutation.mutateAsync(category);

          toast({
            title: t("screens.projects.actions.categorySuccess"),
            status: "success",
            position: "bottom-left",
          });
        } else {
          await updateCategoryMutation.mutateAsync(category);
          toast({
            title: t("screens.projects.actions.categoryUpdateSuccess"),
            status: "success",
            position: "bottom-left",
          });
        }

        handleModalClose();
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [handleModalClose, addCategoryMutation, toast, t, updateCategoryMutation]
  );

  const handleCategoryClick = useCallback(
    (categoryId: string) => {
      setSelectedId(categoryId);
      onModalOpen();
    },
    [onModalOpen]
  );

  const handleDeleteCategory = useCallback(
    async (categoryId: TaskCategory["uid"]) => {
      await deleteCategoryMutation.mutateAsync(categoryId);
      handleModalClose();
    },
    [deleteCategoryMutation, handleModalClose]
  );

  return (
    // @ts-ignore
    <Stack>
      <Stack>
        {projectCategories &&
          projectCategories.map((category) => (
            <Category
              key={category.uid}
              id={category.uid}
              label={category.name}
              color={category.color}
              onClick={handleCategoryClick}
            />
          ))}
      </Stack>
      <Button colorScheme={"teal"} variant={"ghost"} onClick={onModalOpen}>
        {t<string>("screens.projects.addCategory")}
      </Button>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {t<string>("screens.projects.addCategory")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TaskCategoryForm
                selectedCategory={selectedCategory}
                onSubmit={handleSubmitCategory}
                onCancel={handleModalClose}
                onDelete={handleDeleteCategory}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Stack>
  );
};
