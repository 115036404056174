import React, { useContext } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { FixMeLater, Organization } from "../types";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import styled from "styled-components";
import { Button } from "@chakra-ui/button";
import { MultiValueInput } from "./MultiValueInput/MultiValueInput";
import { AbilityContext } from "./Can";

interface Values {
  uid: string;
  name: string;
  domains: Array<string>;
}

type OrganizationFormProps = {
  organization: Organization | null;
  isSubmitting: boolean;
  onSubmit: (organization: FixMeLater) => any;
  forceCanEdit?: boolean;
};

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  organization,
  isSubmitting,
  onSubmit,
  forceCanEdit = false,
}) => {
  const { t } = useTranslation();
  const ability: any = useContext(AbilityContext);
  const canEdit = forceCanEdit || ability.can("edit", "organization");

  return (
    <Formik
      initialValues={{
        uid: organization?.uid || "",
        name: organization?.name || "",
        domains: organization?.domains || [],
      }}
      onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
        setSubmitting(false);
        onSubmit(values);
      }}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "50vw",
          rowGap: "1rem",
        }}
      >
        <Field type={"hidden"} name={"uid"} />

        <Field id={"name"} name={"name"}>
          {({ field, form: { setFieldValue }, meta }) => (
            <FormControl isInvalid={meta.error && meta.touched}>
              <StyledFormLabel htmlFor="name">
                {t<string>("screens.organization.name")}
              </StyledFormLabel>
              <Input
                name={field.name}
                value={field.value}
                onChange={(event) =>
                  setFieldValue(field.name, event.target.value)
                }
                disabled={!canEdit}
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        {/* <label htmlFor={"name"}>{t<string>("screens.organization.name")}</label>
        <Field
          id={"name"}
          name={"name"}
          rules={[
            {
              required: true,
              message: "Inserisci il nome dell'organizzazione!",
            },
          ]}
        /> */}

        <Field id={"domains"} name={"domains"}>
          {({ field, form: { setFieldValue }, meta }) => (
            <FormControl isInvalid={meta.error && meta.touched}>
              <StyledFormLabel htmlFor="domains">
                {t<string>("screens.organization.domains")}
              </StyledFormLabel>
              <MultiValueInput
                name={field.name}
                placeholder={t<string>(
                  "screens.organization.domainsPlaceholder"
                )}
                initialValue={field.value}
                disabled={!canEdit}
                onChange={(value) => setFieldValue(field.name, value)}
                checkIfValidInput={(value) =>
                  !!value &&
                  !field.value.includes(value) &&
                  !!value
                    .toLowerCase()
                    .match(
                      /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    )
                }
                t={t}
              />
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <div
          style={{
            display: "flex",
          }}
        >
          <Button
            colorScheme={"teal"}
            type={"submit"}
            style={{ width: "100%" }}
            isLoading={isSubmitting}
            disabled={!canEdit}
            maxW={"350px"}
            margin={"auto"}
          >
            {t<string>("screens.board.confirmButton")}
          </Button>
          {/* <button type={"submit"}>{t<string>("screens.board.confirmButton")}</button> */}
        </div>
      </Form>
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
