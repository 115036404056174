import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react";
import { Role } from "../../types";
import { getBaseRole } from "../../config/roles";
import { Button } from "@chakra-ui/button";
import {
  Box,
  Heading,
  Spinner,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { OrganizationRolesModal } from "../../components/OrganizationRolesModal";
import { PageHeader } from "../../components/PageHeader";
import { ActionGroup } from "../../components/ActionGroup";
import { OrganizationRolesTable } from "../../components/OrganizationRolesTable";
import styled from "styled-components";

interface Action {
  type: string;
  payload?: {
    role: Role;
  };
}

const initialState = {
  isModalVisible: false,
  role: getBaseRole(),
};

export const OrganizationRoles: React.FC = observer(() => {
  const { organization } = useStores();
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [activeItem, setActiveItem] = useState<Role>(getBaseRole());
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    if (organizationId) {
      organization.fetchRoles(organizationId);
    }
  }, []);

  const handleShowModal = useCallback(
    (role?: Role) => {
      setActiveItem(role || getBaseRole());
      onModalOpen();
    },
    [onModalOpen]
  );

  const handleSubmitRole = useCallback(
    async (role: Role) => {
      try {
        if (organizationId) {
          if (role.uid === "-1") {
            await organization.createRole(organizationId, role);

            toast({
              title: t("screens.roles.actions.createdSuccess"),
              status: "success",
              position: "bottom-left",
            });
          } else {
            await organization.updateRole(organizationId, role);

            toast({
              title: t("screens.roles.actions.updatedSuccess"),
              status: "success",
              position: "bottom-left",
            });
          }
          onModalClose();
        } else {
          throw new Error("Organization not found");
        }
      } catch (err) {
        console.log(err);
        toast({
          title: t("common.error"),
          status: "error",
          position: "bottom-left",
        });
      }
    },
    [onModalClose, organization, organizationId, toast, t]
  );

  return (
    <StyledStack>
      <PageHeader>
        <Heading as={"h4"} size={"md"} fontWeight={"semibold"}>
          {t<string>("screens.roles.title")}
        </Heading>
        <ActionGroup>
          <Button colorScheme={"teal"} onClick={() => handleShowModal()}>
            {t<string>("screens.workflows.bttAdd")}
          </Button>
        </ActionGroup>
      </PageHeader>
      <Stack>
        <Box>
          <OrganizationRolesTable
            roles={organization.roles}
            onItemClick={handleShowModal}
          />
        </Box>
        {organization.isFetching && <Spinner />}
      </Stack>

      {isModalOpen && (
        <OrganizationRolesModal
          role={activeItem}
          isVisible={isModalOpen}
          onSubmit={handleSubmitRole}
          onDismiss={onModalClose}
        />
      )}
    </StyledStack>
  );
});

const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem;
  width: 100%;
`;
