import { SimpleGrid, Stack } from "@chakra-ui/layout";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer } from "react-virtualized";
import {
  DiscreteColorLegend,
  VerticalGridLines,
  XAxis,
  XYPlot,
} from "react-vis";
import Project from "../../../../models/Project";
import { GridCell } from "../../GridCell/GridCell";

interface ProjectInsightsProps {
  project: Project;
}

export const ProjectInsights: React.FC<ProjectInsightsProps> = ({
  project,
}) => {
  const { t } = useTranslation();

  const currentMarginabilityPercentage = useMemo(
    () =>
      project?.budget
        ? (
            100 -
            (((project.totalWorkLogCost || 0) +
              (project.totalFinalBalanceExpenses || 0)) /
              project.budget) *
              100
          ).toFixed(2)
        : null,
    [project]
  );

  const availableBudget = useMemo(
    () =>
      project?.budget
        ? project.budget -
          ((project?.totalWorkLogCost || 0) +
            project.totalFinalBalanceExpenses || 0)
        : null,
    [project]
  );

  const totalEstimatedExpenses = useMemo(() => {
    return (
      project?.expenses
        .filter((expense) => !expense.isFinalBalance)
        .reduce((total, expense) => {
          return total + parseFloat(expense.amount.toString());
        }, 0) || 0
    );
  }, [project.expenses]);

  const totalCost = useMemo(() => {
    return (
      (project?.totalFinalBalanceExpenses || 0) +
      (project?.totalWorkLogCost || 0) +
      totalEstimatedExpenses
    );
  }, [project, totalEstimatedExpenses]);

  return (
    <Stack gridGap={5}>
      <SimpleGrid columns={3}>
        <GridCell
          label={t<string>("screens.projects.budget")}
          value={`${project.budget || "-"} €`}
        />

        <GridCell
          label={t<string>("screens.projects.marginabilityPercentage")}
          value={`${project.marginabilityPercentage || "-"} %`}
        />

        <GridCell
          label={t<string>("screens.projects.notBillable")}
          value={t<string>(`common.${project.notBillable ? "yes" : "no"}`)}
        />
      </SimpleGrid>

      <SimpleGrid columns={5}>
        <GridCell
          label={t<string>("screens.projects.insights.workLog")}
          value={project.totalWorkLog.toString() || "0"}
        />

        <GridCell
          label={t<string>("screens.projects.insights.workLog")}
          value={`${project.totalWorkLogCost || "0"} €`}
        />

        <GridCell
          label={t<string>("screens.expenses.finalBalanceExpenses")}
          value={`${project.totalFinalBalanceExpenses || "0"} €`}
        />

        {currentMarginabilityPercentage && (
          <GridCell
            label={t<string>(
              "screens.projects.insights.currentMarginabilityPercentage"
            )}
            value={`${currentMarginabilityPercentage} %`}
          />
        )}

        {availableBudget && (
          <GridCell
            label={t<string>("screens.projects.insights.availableBudget")}
            value={`${availableBudget} €`}
          />
        )}
      </SimpleGrid>

      <Stack gridGap={1}>
        <div style={{ height: "100px" }}>
          <AutoSizer>
            {({ height, width }) => (
              <XYPlot
                margin={{ right: 100 }}
                width={width}
                height={height}
                stackBy={"x"}
                xDomain={[0, Math.max(project?.budget || 0, totalCost)]}
              >
                <VerticalGridLines />
                {project.marginabilityPercentage && project.budget && (
                  <VerticalGridLines
                    tickTotal={1}
                    tickValues={[
                      (project.budget *
                        (100 - project.marginabilityPercentage)) /
                        100,
                    ]}
                    style={{ stroke: "red" }}
                  />
                )}
                <XAxis />
                {/* @ts-ignore */}
                {/* <HorizontalBarSeries
                  data={[{ y: 1, x: project?.totalWorkLogCost || 0 }]}
                /> */}
                {/* @ts-ignore */}
                {/* <HorizontalBarSeries
                  data={[{ y: 1, x: totalEstimatedExpenses }]}
                /> */}
                {/* @ts-ignore */}
                {/* <HorizontalBarSeries
                  data={[
                    {
                      y: 1,
                      x: project.totalFinalBalanceExpenses || 0,
                    },
                  ]}
                /> */}
              </XYPlot>
            )}
          </AutoSizer>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DiscreteColorLegend
            items={[
              // @ts-ignore
              t("screens.projects.insights.workLog"),
              // @ts-ignore
              t("screens.expenses.estimateExpenses"),
              // @ts-ignore
              t("screens.expenses.finalBalanceExpenses"),
            ]}
            orientation={"horizontal"}
          />
        </div>
      </Stack>
    </Stack>
  );
};
