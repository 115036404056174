export const DAYS = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

export const TERMS_OF_USE_URL =
  "https://laringroup.notion.site/Terms-and-Conditions-f35f3d14a58947019314c292fd6b3f36";

export const PRIVACY_POLICY_URL =
  "https://laringroup.notion.site/Privacy-Policy-52865abbb604424a98c384506dca5530";
