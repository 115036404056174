import {
  Stores,
  Organization,
  Role,
  User,
  ProjectCategory,
  EditedProject,
  Timesheet,
  Customer,
  TaskCategory,
} from "../types";
import { makeAutoObservable, runInAction } from "mobx";
import api from "../services/api";
import Project from "../models/Project";
import { Moment } from "moment";
import { toUserRole } from "../services/utils";

type UserDataView = {
  id: string;
  value: string;
  label: string;
  nextFiveDayValue: number[];
  isActive: boolean;
}

type SelectorValueType = {
  value: string;
  label: string;
}
export class OrganizationStore {
  roles: Role[] = [];
  users: User[] = [];
  projectCategories: ProjectCategory[] = [];
  projects: Project[] = [];
  userProjects: Project[] = [];
  projectReport: any = null;
  timesheet: Timesheet = [];
  customers: Customer[] = [];
  taskCategories: TaskCategory[] = [];

  chartViewUser: UserDataView[] = [];
  selectorValues: SelectorValueType[] = [];

  projectTableState: any = null;
  projectsFilters: {
    [dataKey: string]: string | string[];
  } = {};
  customersFilters: {
    [dataKey: string]: string | string[];
  } = {};
  timesheetFilters: {
    [dataKey: string]: string | string[];
  } = {};

  isSubmitting = false;
  isFetchingRoles = false;
  isFetchingUsers = false;
  isFetchingProjects = false;
  isFetchingProjectCategories = false;
  isFetchingProjectReport = false;
  isFetchingExpenseTypes = false;
  isFetchingReport = false;
  isFetchingCustomers = false;
  isFetchingTaskCategories = false;
  isRightSideTimeGrapOpen = false;

  stores: Stores;

  get isFetching() {
    return (
      this.isFetchingRoles ||
      this.isFetchingUsers ||
      this.isFetchingProjectCategories
    );
  }

  // @computed get projectTableActiveFilters() {
  //   return this.projectTableState?.filters
  //     ? Object.keys(this.projectTableState.filters).some(
  //         (key) => this.projectTableState.filters[key]?.length > 0
  //       )
  //     : false;
  // }

  constructor(stores: Stores) {
    this.stores = stores;

    makeAutoObservable(this);
  }

  createOrganization = async (
    newOrganization: Organization,
    userLanguage: string = "en"
  ) => {
    this.isSubmitting = true;
    try {
      if (this.stores.session.user) {
        const {
          invalidDomains,
          organization,
          role,
        } = await api.createOrganization(
          this.stores.session.user?.uid,
          newOrganization,
          userLanguage
        );
        this.stores.session.user.organization = { ...organization };
        this.stores.session.user.role = toUserRole(role);
        if (invalidDomains && invalidDomains.length > 0) {
          throw new Error(`Invalid Domains:${invalidDomains.join(" ")}`);
        }
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  updateOrganization = async (data: Organization) => {
    this.isSubmitting = true;
    try {
      if (this.stores.session.user) {
        const response = await api.updateOrganization(
          this.stores.session.user?.uid,
          data.uid,
          data
        );
        if (response.updated) {
          this.stores.session.user.organization = {
            ...(this.stores.session.user.organization as Organization),
            name: data.name,
            domains: data.domains,
          };
          if (response.invalidDomains && response.invalidDomains.length > 0) {
            throw new Error(
              `Invalid Domains:${response.invalidDomains.join(" ")}`
            );
          }
        } else {
          throw new Error("Update failed");
        }
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  fetchRoles = async (organizationId: Organization["uid"]) => {
    this.isFetchingRoles = true;

    try {
      const roles = await api.fetchOrganizationRoles(organizationId);

      this.roles = roles;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingRoles = false;
    }
  };

  createRole = async (organizationId: Organization["uid"], role: Role) => {
    this.isSubmitting = true;

    try {
      const newRole = await api.createOrganizationRole(organizationId, role);

      this.roles.push(newRole);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  updateRole = async (organizationId: Organization["uid"], role: Role) => {
    this.isSubmitting = true;

    try {
      const editedRole = await api.updateOrganizationRole(organizationId, role);

      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].uid === editedRole.uid) {
          this.roles[i] = editedRole;
        }
      }
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  fetchUsers = async (
    organizationId: Organization["uid"],
    onlyActiveUsers: boolean = false
  ) => {
    this.isFetchingUsers = true;

    try {
      const users = await api.fetchOrganizationUsers(
        organizationId,
        onlyActiveUsers
      );
      this.users = users;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingUsers = false;
    }
  };

  inviteUser = async (
    organizationId: Organization["uid"],
    email: User["email"],
    nominative: User["nominative"],
    roleId: Role["uid"]
  ) => {
    this.isSubmitting = true;

    try {
      await api.inviteUser(organizationId, email, nominative, roleId);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  updateUser = async (
    organizationId: Organization["uid"],
    userId: User["uid"],
    roleId: Role["uid"]
  ) => {
    this.isSubmitting = true;

    try {
      const updatedUser = await api.updateOrganizationUser(
        organizationId,
        userId,
        roleId
      );

      this.users = this.users.map((user) =>
        user.uid === updatedUser.uid ? { ...user, ...updatedUser } : user
      );
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  updateUserData = async (userId: User["uid"], data: Pick<User, "status">) => {
    this.isSubmitting = true;
    try {
      const updatedUser = await api.updateUser(userId, data);

      this.users = this.users.map((user) =>
        user.uid === updatedUser.uid
          ? { ...user, status: updatedUser.status }
          : user
      );
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  fetchProjectCategories = async (organizationId: Organization["uid"]) => {
    this.isFetchingProjectCategories = true;

    try {
      const categories = await api.fetchProjectCategories(organizationId);
      this.projectCategories = categories;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjectCategories = false;
    }
  };

  fetchTaskCategories = async (organizationId: Organization["uid"]) => {
    this.isFetchingTaskCategories = true;

    try {
      const categories = await api.fetchTaskCategories(organizationId);

      this.taskCategories = categories;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingTaskCategories = false;
    }
  };

  // createProjectCategory = async (
  //   organizationId: Organization["uid"],
  //   name: ProjectCategory["name"]
  // ) => {
  //   this.isSubmitting = true;

  //   try {
  //     const category = await api.createProjectCategory(organizationId, name);
  //     this.projectCategories.push(category);
  //   } catch (err) {
  //     throw err;
  //   } finally {
  //     this.isSubmitting = false;
  //   }
  // };

  // updateProjectCategory = async (
  //   organizationId: Organization["uid"],
  //   categoryId: ProjectCategory["uid"],
  //   name: ProjectCategory["name"]
  // ) => {
  //   this.isSubmitting = true;

  //   try {
  //     const category = await api.updateProjectCategory(
  //       organizationId,
  //       categoryId,
  //       name
  //     );
  //     for (let i = 0; i < this.projectCategories.length; i++) {
  //       if (this.projectCategories[i].uid === categoryId) {
  //         this.projectCategories[i] = { ...category };
  //       }
  //     }
  //   } catch (err) {
  //     throw err;
  //   } finally {
  //     this.isSubmitting = false;
  //   }
  // };

  fetchProjects = async (organizationId: Organization["uid"]) => {
    this.isFetchingProjects = true;

    try {
      const projects = await api.fetchProjects(organizationId);

      this.projects = projects;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }
  };

  fetchUserProjects = async (
    organizationId: Organization["uid"],
    userId: User["uid"]
  ) => {
    this.isFetchingProjects = true;

    try {
      const userProjects = await api.fetchUserProjects(organizationId, userId);
      this.userProjects = userProjects;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjects = false;
    }
  };

  createProject = async (
    organizationId: Organization["uid"],
    newProject: EditedProject
  ) => {
    this.isSubmitting = true;

    try {
      const project = await api.createProject(organizationId, newProject);

      this.projects.push(project);
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  deleteProject = async (
    organizationId: Organization["uid"],
    projectId: Project["uid"]
  ) => {
    this.isSubmitting = true;

    try {
      const deletedProjectId = await api.deleteProject(
        organizationId,
        projectId
      );

      this.projects = this.projects.filter(
        (project) => project.uid !== deletedProjectId
      );
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  fetchProjectReport = async (
    organizationId: Organization["uid"],
    projectId: Project["uid"]
  ) => {
    this.projectReport = null;
    this.isFetchingProjectReport = true;

    try {
      const report = await api.getProjectReport(organizationId, projectId);

      this.projectReport = report;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingProjectReport = false;
    }
  };

  fetchTimesheet = async (
    organizationId: Organization["uid"],
    startDate: Moment,
    endDate: Moment,
    timesheetType: "byDay" | "byProject" | "byClient"
  ) => {
    this.isFetchingReport = true;
    try {
      const timesheet = await api.getTimesheet(
        organizationId,
        startDate,
        endDate,
        timesheetType
      );
      this.timesheet = timesheet;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingReport = false;
    }
  };

  resetProjectTableFilters = () => {
    this.projectTableState = null;
  };

  fetchCustomers = async (organizationId: Organization["uid"]) => {
    this.isFetchingCustomers = true;

    try {
      const customers = await api.fetchCustomers(organizationId);

      this.customers = customers;
    } catch (err) {
      throw err;
    } finally {
      this.isFetchingCustomers = false;
    }
  };

  createCustomer = async (organizationId: Organization["uid"], data: any) => {
    this.isSubmitting = true;
    try {
      const customer = await api.createCustomer(
        organizationId,
        data.nominative,
        data.email,
        data.fiscalCode,
        data.vatNumber,
        data.externalId
      );

      runInAction(() => {
        this.customers = [...this.customers, customer];
      });
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  updateCustomer = async (organizationId: Organization["uid"], data: any) => {
    this.isSubmitting = true;
    try {
      const customer = await api.updateCustomer(
        organizationId,
        data.uid,
        data.nominative,
        data.email,
        data.fiscalCode,
        data.vatNumber,
        data.externalId
      );

      runInAction(() => {
        this.customers = this.customers.map((c) =>
          c.uid === customer.uid ? customer : c
        );
      });
    } catch (err) {
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  deleteCustomer = async (
    organizationId: Organization["uid"],
    customerID: Customer["uid"]
  ) => {
    this.isSubmitting = true;
    try {
      const customer = await api.deleteCustomer(organizationId, customerID);

      runInAction(() => {
        this.customers = this.customers.filter((c) => c.uid !== customer);
      });
    } catch (err) {
      console.log("err: ", err);
      throw err;
    } finally {
      this.isSubmitting = false;
    }
  };

  updateProjectFilters = (dataKey: string, value: string) => {
    let newFilters = { ...this.projectsFilters };

    if (value?.length > 0) {
      newFilters[dataKey] = value;
    } else {
      delete newFilters[dataKey];
    }
    this.projectsFilters = newFilters;
  };

  updateCustomerFilters = (dataKey: string, value: string) => {
    let newFilters = { ...this.customersFilters };

    if (value?.length > 0) {
      newFilters[dataKey] = value;
    } else {
      delete newFilters[dataKey];
    }
    this.customersFilters = newFilters;
  };

  updateTimesheetFilters = (dataKey: string, value: string) => {
    let newFilters = { ...this.timesheetFilters };

    if (value?.length > 0) {
      newFilters[dataKey] = value;
    } else {
      delete newFilters[dataKey];
    }
    this.timesheetFilters = newFilters;
  };

  cleanProjectFilters = () => {
    this.projectsFilters = {};
  };

  cleanCustomerFilters = () => {
    this.customersFilters = {};
  };

  updateCharViewUser = (data: UserDataView[]) => {
    this.chartViewUser = data;
  };

  updateSelectorValues = (data: SelectorValueType[]) => {
    this.selectorValues = data;
  };

  toggleRightSideTimeGrapView = (value: boolean) => {
    this.isRightSideTimeGrapOpen = value
  }
}
