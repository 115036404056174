import { Observer } from "mobx-react";
import React from "react";

import Project from "../../../../models/Project";
import { Editor } from "../../../Editor";

interface ProjectDescriptionProps {
  currentProject: Project;
}

export const ProjectDescription: React.FC<ProjectDescriptionProps> = ({
  currentProject,
}) => {
  return (
    <Observer>
      {() => (
        <Editor
          onSubmit={(description) =>
            currentProject.updateDescription(description)
          }
          value={currentProject.description}
          submitting={currentProject.isSubmitting}
        />
      )}
    </Observer>
  );
};
