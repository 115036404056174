import React, { useEffect } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";

/**
 * @chakra-ui
 */
import { VStack, Stack } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Checkbox } from "@chakra-ui/checkbox";
import { NumberInput, NumberInputField } from "@chakra-ui/number-input";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/input";

/**
 * Internal components
 */
import { Organization, EditedProjectExpense } from "../types";
import Project from "../models/Project";
import Expense from "../models/Expense";
import { ModalFooterForm } from "./ModalFooterForm";

type ExpenseFormProps = {
  organizationId: Organization["uid"];
  projectId: Project["uid"];
  expense: Expense | null;
  onSubmit: (expense: any) => any;
  onCancel: () => any;
  onLoad?: () => any;
  onDelete?: (expense: Expense) => any;
};

export const ExpenseForm: React.FC<ExpenseFormProps> = ({
  organizationId,
  projectId,
  expense,
  onSubmit,
  onCancel,
  onLoad,
  onDelete,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    onLoad && onLoad();
  }, []);

  return (
    <Formik
      initialValues={{
        uid: expense ? expense.uid : null,
        organizationId,
        projectId,
        isFinalBalance: expense ? expense.isFinalBalance : false,
        supplier: expense ? expense.supplier : "",
        title: expense ? expense.title : "",
        description: expense ? expense.description : "",
        amount: expense ? expense.amount : null,
        isBillable: expense ? expense.isBillable : false,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .required(t("screens.expenses.validations.titleRequired"))
          .min(1, t("screens.expenses.validations.titleRequired")),
        amount: Yup.number()
          .typeError(t("screens.expenses.validations.titleRequired"))
          .min(1, t("screens.expenses.validations.amountRequired")),
      })}
      onSubmit={(
        values: EditedProjectExpense,
        { setSubmitting }: FormikHelpers<EditedProjectExpense>
      ) => {
        setSubmitting(false);
        if (!values.isFinalBalance) {
          values.isBillable = false;
        }
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Field type={"hidden"} name={"uid"} />
          <Field type={"hidden"} name={"organizationId"} />
          <Field type={"hidden"} name={"projectId"} />

          <Stack style={{ alignItems: "flex-start" }}>
            <Field id={"title"} name={"title"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor={"title"}>
                    {t<string>("screens.expenses.title")}
                  </StyledFormLabel>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    {...field}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"description"} name={"description"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor={"description"}>
                    {t<string>("screens.expenses.description")}
                  </StyledFormLabel>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    {...field}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"supplier"} name={"supplier"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor={"supplier"}>
                    {t<string>("screens.expenses.supplier")}
                  </StyledFormLabel>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    {...field}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"amount"} name={"amount"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl isInvalid={meta.error && meta.touched}>
                  <StyledFormLabel htmlFor={"amount"}>
                    {t<string>("screens.expenses.amount")}
                  </StyledFormLabel>
                  <InputGroup>
                    <InputLeftAddon children={"€"} />
                    <NumberInput
                      precision={2}
                      name={field.name}
                      value={field.value || ""}
                    >
                      <NumberInputField
                        name={field.name}
                        value={field.value || ""}
                        onChange={(event) =>
                          setFieldValue(field.name, event.target.value)
                        }
                        {...field}
                      />
                    </NumberInput>
                  </InputGroup>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field id={"isFinalBalance"} name={"isFinalBalance"}>
              {({ field, form: { setFieldValue }, meta }) => (
                <FormControl
                  isInvalid={meta.error && meta.touched}
                  style={{ paddingTop: "1.2rem" }}
                >
                  <Checkbox
                    name={field.name}
                    isChecked={field.value}
                    onChange={(event) => {
                      console.log(field.name, event.target.checked);
                      setFieldValue(field.name, event.target.checked);
                    }}
                  >
                    {t<string>("screens.expenses.isFinalBalance")}
                  </Checkbox>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {props.values.isFinalBalance && (
              <Field id={"isBillable"} name={"isBillable"}>
                {({ field, form: { setFieldValue }, meta }) => (
                  <FormControl
                    isInvalid={meta.error && meta.touched}
                    style={{ paddingTop: "1.2rem" }}
                  >
                    <Checkbox
                      name={field.name}
                      isChecked={field.value}
                      onChange={(event) => {
                        console.log(field.name, event.target.checked);
                        setFieldValue(field.name, event.target.checked);
                      }}
                    >
                      {t<string>("screens.expenses.isBillable")}
                    </Checkbox>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
          </Stack>

          <ModalFooterForm
            onCancel={onCancel}
            onDelete={
              !!onDelete && expense
                ? () => {
                    onDelete(expense);
                  }
                : undefined
            }
          />
        </Form>
      )}
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
  color: ${(props) => props.theme.secondaryText1};
`;
