import { Spinner } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectReport as TProjectReport } from "../../../../types";
import { sec2time } from "../../../../utils/dateTimeFormat";
import { DownloadCSV } from "../../../DownloadCSV";
import { ProjectReportTable } from "../../../ProjectReportTable";

interface ProjectReportProps {
  report: TProjectReport[];
  isFetching: boolean;
  onLoad: () => any;
}

export const ProjectReport: React.FC<ProjectReportProps> = ({
  report,
  isFetching,
  onLoad,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    onLoad();
  }, []);

  const csvData = useMemo(() => {
    const rows = [
      [
        t("screens.projects.reports.user").toUpperCase(),
        t("screens.projects.reports.task").toUpperCase(),
        t("screens.projects.reports.startTime").toUpperCase(),
        t("screens.projects.reports.endTime").toUpperCase(),
        t("screens.projects.reports.elapsedTime").toUpperCase(),
      ],
    ];

    if (report) {
      for (let data of report) {
        rows.push([
          data.user.nominative,
          data.title,
          moment(data.startTime).format("DD/MM/YY HH:mm"),
          moment(data.endTime).format("DD/MM/YY HH:mm"),
          data.elapsedTime ? sec2time(data.elapsedTime, "HH:MM") : "",
        ]);
      }
    }

    return rows;
  }, [report, t]);

  return (
    <div>
      {isFetching && <Spinner size={"lg"} />}
      {report && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <DownloadCSV data={csvData} target={"_blank"} separator={";"} />
        </div>
      )}
      {!isFetching && report && (
        <>
          <ProjectReportTable report={report} />
        </>
      )}
    </div>
  );
};
