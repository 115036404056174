import React, { useMemo } from "react";
import styled from "styled-components";
import { Button as RebassButton } from "rebass/styled-components";
import { transparentize } from "polished";
import { Icon } from "../Icon";
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import dayjs from "dayjs";
import { useCallback } from "react";
import { Period } from "../../types";

type CalendarNavButtonsProps = {
  initialDate?: Date;
  type?: "week";
  disabled?: boolean;
  onChange?: (period: Period) => void;
};

export const CalendarNavButtons: React.FC<CalendarNavButtonsProps> = ({
  initialDate = new Date(),
  type = "week",
  disabled = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs(initialDate));

  const isDisabledTodayButton = useMemo(() => {
    return (
      (type === "week" &&
        dayjs().startOf("week").isSame(date.startOf("week"))) ||
      disabled
    );
  }, [date, disabled, type]);

  const emitOnChange = useCallback(
    (newDate: dayjs.Dayjs) => {
      let period: Period;
      switch (type) {
        case "week":
          period = {
            fromDate: newDate.startOf("week").toDate(),
            toDate: newDate.endOf("week").toDate(),
          };
          break;
        default:
          period = {
            fromDate: newDate.toDate(),
            toDate: newDate.toDate(),
          };
      }
      onChange && onChange(period);
    },
    [onChange, type]
  );

  const onPrevClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "week":
        newDate = date.subtract(1, "week");
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  const onTodayClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "week":
        newDate = dayjs();
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  const onNextClick = useCallback(() => {
    let newDate: dayjs.Dayjs;
    switch (type) {
      case "week":
        newDate = date.add(1, "week");
        break;
      default:
        newDate = date;
    }
    setDate(newDate);
    emitOnChange(newDate);
  }, [date, emitOnChange, type]);

  return (
    <Flex>
      <PrevButton onClick={onPrevClick} disabled={disabled}>
        <Icon iconName={"HiChevronLeft"} />
      </PrevButton>
      <TodayButton disabled={isDisabledTodayButton} onClick={onTodayClick}>
        {t<string>("calendar.today")}
      </TodayButton>
      <NextButton onClick={onNextClick} disabled={disabled}>
        <Icon iconName={"HiChevronRight"} />
      </NextButton>
    </Flex>
  );
};

const TodayButton = styled(RebassButton)`
  padding: 0.75rem 2rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 1rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.text1};
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  z-index: 1;
`;

const PrevButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => transparentize(0.9, theme.primary1)};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  margin-right: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.primary1};
`;

const NextButton = styled(RebassButton)`
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => transparentize(0.9, theme.primary1)};
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -0.5rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  > * {
    user-select: none;
  }
  color: ${({ theme }) => theme.primary1};
`;
