const it = {
  screens: {
    board: {
      formTitle: "Titolo",
      formColor: "Categoria",
      formTaskType: "Tipologia",
      formTaskTypePlanning: "Pianificazione",
      formTaskTypeActivity: "Attività",
      formEventUntitledTitle: "(Senza titolo)",
      cancelButton: "Annulla",
      deleteButton: "Elimina",
      confirmButton: "Salva",
      logoutButton: "Logout",
      settings: "Impostazioni",
      elapsedTime: "Ore lavorate",
      project: "Progetto",
      toolbar: {
        integrations: "Integrazioni",
        plugins: {
          googleCalendar: "Google calendar",
        },
      },
      board: "Board",
      preferences: {
        allDaySlot: "Slot eventi giornalieri",
        weekends: "Weekend",
        slotDuration: "Dimensione slot",
        slotRangeTime: "Fascia oraria",
        slotMinTime: "Ora di inizio",
        slotMaxTime: "Ora di fine",
        businessDaysOfWeek: "Giornate lavorative",
        businessRangeTime: "Fascia oraria lavorativa",
        businessStartTime: "Orario di inizio lavoro",
        businessEndTime: "Ora di fine lavoro",
        calendarView: "Visualizzazione calendario",
        timeGridWeek: "Settimanale",
        timeGridDay: "Giornaliera",
      },
      event: {
        addEventTitle: "Aggiungi ",
        editEventTitle: "Modifica ",
        activity: "attività",
        planning: "pianificazione",
        noProjectCategory: "Nessuna categoria",
        noTask: "Nessuna Task",
        deleteTitle: "Elimina attività",
        deleteRecurrenceTitle: "Elimina attività ricorrente",
        onlyThisEvent: "Questa attività",
        thisEventAndFollowing: "Questa attività e quelle successive",
        deleteMessage: "Sei sicuro di voler eliminare questa attività?",
      },
      recurrence: {
        editRecurringEvent: "Modifica evento periodico",
        onlyThis: "Questo evento",
        thisAndFollowingOnes: "Questo evento e quelli successivi",
      },
    },
    login: {
      title: "Time Trap",
      login: "Login",
      signUp: "Registrazione",
      ctaLogin: "Sign in with Google",
      notSignup: "Non hai un account?",
      alreadySignup: "Hai già un account?",
      ctaSignup: "Registrati",
      nominative: "Nome e cognome",
      email: "Email",
      password: "Password",
      confirmPassword: "Conferma password",
      loginError: "Email o password errati",
      signUpError: "Errore",
      lostPassword: "Ho dimenticato la password",
      desktopOnly:
        "Siamo al lavoro per portare timetrap sugli smartphone, per ora accedi dal tuo laptop",
    },
    lostPassword: {
      title: "Recupero password",
      sendRecoveryLink: "Invia link di recupero",
      requestSuccess:
        "Richiesta eseguita correttamente. Riceverai una mail con le indicazioni da seguire!",
    },
    changePassword: {
      title: "Cambio password",
      newPassword: "Nuova password",
      confirmNewPassword: "Conferma password",
    },
    organization: {
      name: "Organizzazione",
      domains: "Domini email associati",
      domainsPlaceholder: "nome-azienda.it",
      addDomain: "Aggiungi",
      roleName: "Ruolo",
      addProject: "Aggiungi progetto",
      createTitle: "Inserisci il nome della tua azienda",
      createError: "Errore durante la creazione dell'organizzazione",
      createErrorInvalidDomains:
        "L'organizzazione è stata creata ma non è stato possibile aggiungere i seguenti domini perchè invalidi o già utilizzati da altre organizzazioni: ",
      createErrorInvalidDomains2:
        "Puoi modificare i domini email dalla sezione Organizzazion/Dettagli",
      updateError:
        "Errore durante l'aggiornamento dei dati dell'organizzazione",
      updateErrorInvalidDomains:
        "I seguenti domini non sono validi o sono già utilizzati da altre organizzazioni: ",
      organizationUpdateSuccess:
        "Informazioni dell'organizzazione aggiornate correttamente",
      nav: {
        organization: "Organizzazione",
        organizationDetail: "Dettagli",
        userManagement: "Gestione utenti",
        subscription: "Piano & Fatturazione",
        projectSetup: "Progetti",
        advanced: "Avanzate",
      },
    },
    projects: {
      title: "Progetto",
      titleList: "Progetti",
      notFound: "Progetto non trovato",
      customer: "Cliente",
      externalReference: "Preventivo di riferimento",
      notBillable: "Non fatturabile",
      budget: "Budget",
      marginabilityPercentage: "Marginalità obiettivo %",
      toleranceDays: "Giorni di tolleranza",
      projectManager: "Project Manager",
      category: "Categoria",
      categories: "Categorie",
      projects: "Progetti",
      tasks: "Task",
      addCategory: "Aggiungi categoria",
      status: "Stato",
      contactName: "Nome riferimento cliente",
      contactSurname: "Cognome riferimento cliente",
      contactEmail: "Email riferimento cliente",
      editProject: "Modifica progetto",
      startDate: "Data inizio",
      endDate: "Data fine",
      closingDate: "Data chiusura",
      team: "Team",
      addMember: "Aggiungi utente",
      details: "Dettagli progetto",
      description: "Descrizione",
      note: "Note",
      currentMarginability: "Marginalità attuale",
      actions: {
        createSuccess: "Progetto creato correttamente",
        updateSuccess: "Progetto aggiornato correttamente",
        delete: "Confermi di voler eliminare il progetto?",
        categorySuccess: "Categoria creata correttamente",
        categoryUpdateSuccess: "Categoria aggiornata correttamente",
        resetFilters: "Rimuovi filtri",
      },
      validations: {
        titleRequired: "Inserisci il titolo del progetto!",
        customerRequired: "Inserisci il cliente del progetto!",
        projectManagerRequired: "Inserisci il Project Manager del progetto!",
      },
      notes: {
        actions: {
          addNote: "Aggiungi nota",
          delete: "Confermi di voler eliminare la nota?",
        },
      },
      insights: {
        title: "Insights",
        workLog: "Ore lavoro",
        workLogCost: "Costo ore lavoro",
        currentMarginabilityPercentage: "Marginalità attuale %",
        availableBudget: "Budget disponibile",
      },
      reports: {
        user: "Utente",
        task: "Task",
        startTime: "Data inizio",
        endTime: "Data fine",
        elapsedTime: "Ore lavorate",
      },
      projectCategory: {
        name: "Nome",
        color: "Colore",
        validations: {
          nameRequired: "Inserisci il nome della categoria!",
          colorRequired: "Seleziona il colore della categoria!",
        },
      },
      taskCategory: {
        name: "Nome",
        color: "Colore",
        isUsedForTasks: "Usata per Activity",
        isUsedForPlanning: "Usata per pianificazione",
        validations: {
          nameRequired: "Inserisci il nome della categoria!",
          colorRequired: "Seleziona il colore della categoria!",
        },
      },
      roleTages: {
        resiposiable: 'Responsible',
        accountable: "Accountable",
        consulted: "Consulted",
        informed: "Informed",
      }
    },
    expenses: {
      modalTitle: "Nuova spesa",
      title: "Titolo",
      supplier: "Fornitore",
      type: "Tipologia",
      description: "Descrizione",
      amount: "Importo",
      isBillable: "Pagabile",
      isFinalBalance: "Consuntivata",
      approver: "Approvata da",
      totalAmount: "Importo totale",
      estimateExpenses: "Spese preventivate",
      finalBalanceExpenses: "Spese consuntivate",
      addExpense: "Aggiungi spesa",
      toFinalBalance: "Consuntiva",
      actions: {
        delete: "Confermi di voler eliminare la spesa?",
      },
      validations: {
        titleRequired: "Inserisci il titolo della spesa",
        amountRequired: "Inserisci il costo della spesa",
      },
    },
    expenseTypes: {
      estimate: "Preventivata",
      final_balance: "Consuntivata",
    },
    users: {
      titleList: "Utenti",
      title: "Utente",
      email: "Email",
      nominative: "Nominativo",
      status: "Stato",
      hourlyCost: "Costo orario",
      contractualHours: "Ore contrattuali",
      contractType: "Tipo contratto",
      productivityTarget: "Produttività target",
      actions: {
        userUpdated: "Utente aggiornato correttamente",
        userInvited: "Utente invitato correttamente",
      },
      inviteUser: "Invita utente",
      role: "Ruolo",
    },
    userDetails: {
      title: "Dettaglio utente",
      contracts: "Contratti",
      addContract: "Nuovo",
      activeUser: "Utente attivo",
      inactiveUser: "Utente non attivo",
      editContract: "Modifica contratto",
      newContract: "Nuovo contratto",
      contractCreated: "Contratto creato correttamente",
      contractUpdated: "Contratto aggiornato correttamente",
      contractDeleted: "Contratto cancellato correttamente",
      deleteContract: "Sei sicuro di voler rimuovere questo contratto?",
    },
    roles: {
      titleList: "Ruoli",
      title: "Ruolo",
      subject: "Soggetto",
      editTitle: "Modifica ruolo",
      newTitle: "Nuovo ruolo",
      actions: {
        newRole: "Aggiungi",
        createdSuccess: "Ruolo creato correttamente",
        updatedSuccess: "Ruolo aggiornato correttamente",
      },
    },
    timesheet: {
      title: "Timesheet",
      generate: "Genera",
      workLogDate: "Data",
      workLogDay: "Giorno",
      workLog: "Ore",
      byDay: "Per giorno",
      byProject: "Per progetto",
      projectTitle: "Progetto",
      customerNominative: "Cliente",
      byClient: "Per cliente",
    },
    dashboard: {
      taskNotAssigned: "Task non assegnati",
      weeklyReport: "Report settimanale",
      targetHours: "Target ore",
      plannedHours: "Tempo pianificato",
      workLogged: "Tempo tracciato",
      billableHours: "Tempo fatturabile",
      personal: "Personale",
      team: "Team",
      // totalPlannedHours: "Totale tempo pianificato",
      totalWorkLogged: "Totale tempo tracciato",
      totalBillableHours: "Totale tempo fatturabile",
      timeEntryNotAssigned: "Time entry non assegnate",
    },
    customers: {
      titleList: "Clienti",
      title: "Cliente",
      nominative: "Nominativo",
      email: "Email",
      fiscalCode: "Codice fiscale",
      vatNumber: "Partita Iva",
      externalId: "Riferimento esterno",
      actions: {
        new: "Aggiungi",
        updated: "Cliente aggiornato correttamente",
        created: "Cliente creato correttamente",
        delete: {
          title: "Sei assolutamente sicuro di voler procedere?",
          alert: "Se non leggi questo accadranno cose inaspettate!",
          message:
            "Questa azione non può essere annullata. Questo cancellerà definitivamente il cliente, tutti i progetti correlati e tutte le time entries (tempo tracciato per i progetti).",
          confirm: "Scrivi <1>{{secureText}}</1> qui sotto per confermare.",
          deleted: "Cliente eliminato correttamente",
        },
      },
    },
    integrations: {
      title: "Integrazioni",
      plugins: {
        googleCalendar: "Google calendar",
      },
      enablePaymo: "Abilita Paymo",
      enableJira: "Abilita Jira",
      tips: {
        paymo:
          "Per abilitare l'integrazione con Paymo, genera l'API Key dalla pagina ",
      },
      allProjects: "Tutti i progetti",
      selectProject: "Seleziona progetto",
      tasks: {
        markCompleted: "Segna come completata",
        viewOnasana: "Visualizza su Asana",
        viewOntodoist: "Visualizza su Todoist",
        showDetails: "Mostra dettagli",
        hideDetails: "Nascondi dettagli",
        viewLink: "Visualizza link",
      },
    },
    team: {
      title: "Team",
      plannedHours: "Ore pianificate",
      workLog: "Ore lavorate",
      billableWorkLog: "Ore fatturabili",
    },
    subscription: {
      title: "Gestione abbonamento",
    },
    profile: {
      title: "Profilo",
      language: "Lingua:",
      saveBtnText: "Salva",
      languageChangePlace: "Seleziona la lingua",
    },
    workflows: {
      title: "Workflows",
      bttAdd: "Nuovo",
      name: "Stato",
      newTitle: "Nuovo stato",
      editTitle: "Modifica stato",
      deleteWorkflow: "Sei sicuro di voler rimuovere questo stato?",
      selectedByDefault: "Selezionato di default nella lista progetti",
    },
    webhooks: {
      title: "Webhooks",
      editTitle: "Modifica webhook",
      newTitle: "Nuovo webhook",
      url: "Endpoint URL",
      description: "Descrizione",
      events: "Eventi",
      enabled: "Attivato",
      disabled: "Disattivato",
      status: "Stato",
      urlPlaceholder: "https://",
      descriptionPlaceholder: "Descrizione opzionale dello scopo del webhook.",
      eventCategories: {
        project: "Progetto",
      },
      eventsDescriptions: {
        "project-status-change":
          "Si verifica ogni volta che lo stato di un progetto viene cambiato.",
      },
      condition: "Condizione",
      conditions: {
        always: "Sempre",
      },
      deleteWebhook: "Sei sicuro di voler rimuovere questo webhook?",
    },
    teamplan: {
      chartTitle: "Team Plan",
      noDataFound: "Seleziona un membro del team",
      selectAll: "Seleziona tutti",
    }
  },
  nav: {
    board: "Calendario",
    projects: "Progetti",
    dashboard: "Dashboard",
    integrations: "Integrazioni",
    tasks: "Task",
    organization: "Organizzazione",
    teamplan: "Team Plan",
  },
  tasks: {
    title: "Task",
    addTask: "Nuovo task",
    editTask: "Modifica task",
    name: "Titolo",
    description: "Descrizione",
    dueDate: "Scadenza",
    color: "Categoria",
    user: "Assegnatario",
    project: "Progetto",
    subtasks: "Sotto-attività",
    addSubtask: "Aggiungi sotto-attività",
    priority: "Priorità",
    statusName: "status",
    downloadTask: "Download",
    validations: {
      nameRequired: "Inserisci il nome del task",
      projectRequired: "Seleziona il progetto del task",
      userRequired: "Seleziona l'assegnatario del task",
    },
    priorities: {
      lowest: "Minima",
      low: "Bassa",
      medium: "Media",
      high: "Alta",
      highest: "Massima",
    },
    status: {
      open: "Open",
      completed: "Completed"
    }
  },
  menu: {
    profile: "Profilo",
  },
  eventTile: {
    day: "Giorno",
    fromTime: "Dalle ore",
    toTime: "Alle ore",
    noProject: "Nessun progetto",
    recurrence: "Ricorrenza",
    repeatEvery: "Ripeti ogni",
    repeatOn: "Si ripete il",
    ends: "Fine",
    DAILY: "Giorni",
    WEEKLY: "Settimane",
    MONTHLY: "Mesi",
    YEARLY: "Anni",
    never: "Mai",
    after: "Dopo",
    occurrences: "occorrenze",
    date: "Data",
    doesNotRepeat: "Non si ripete",
    on: "di",
    untilDate: "fino a",
    repeatLabel: "ripeti {{repeat}} volte",
    actions: {
      completed: "Completata",
      confirmTime: "Conferma tempo",
      edit: "Modifica",
      copy: "Duplica",
    },
    validations: {
      endTime: "La data di fine deve essere successiva alla data di inizio",
    },
  },
  days: {
    sunday: "Domenica",
    monday: "Lunedì",
    tuesday: "Martedì",
    wednesday: "Mercoledì",
    thursday: "Giovedì",
    friday: "Venerdì",
    saturday: "Sabato",
  },
  dayOfWeek: {
    0: "Domenica",
    1: "Lunedì",
    2: "Martedì",
    3: "Mercoledì",
    4: "Giovedì",
    5: "Venerdì",
    6: "Sabato",
  },
  dayOfWeekShort: {
    0: "D",
    1: "L",
    2: "M",
    3: "M",
    4: "G",
    5: "V",
    6: "S",
  },
  months: {
    0: "Gennaio",
    1: "Febbraio",
    2: "Marzo",
    3: "Aprile",
    4: "Maggio",
    5: "Giugno",
    6: "Luglio",
    7: "Agosto",
    8: "Settembre",
    9: "Ottobre",
    10: "Novembre",
    11: "Dicembre",
  },
  roles: {
    admin: "Admin",
    manager: "Manager",
    member: "Membro",
  },
  acls: {
    subject: {
      organization: "Organizzazione",
      role: "Ruoli",
      user: "Utenti",
      project: "Progetto",
      timesheet: "Timesheet",
      team: "Team",
    },
    action: {
      read: "Lettura",
      edit: "Modifica",
      create: "Crea",
      delete: "Cancella",
    },
  },
  calendar: {
    today: "Oggi",
  },
  termsOfUse: {
    welcome: "Benvenuto {{nominative}}!",
    message: "Completa la registrazione",
    agree: "Accetto i ",
    termsOfUse: "Termini e condizioni d'utilizzo.",
    privacyPolicy: "Privacy Policy",
  },
  cookiesConsent: {
    message: "Questo sito utilizza i cookie per migliorare la tua esperienza!",
    accept: "Accetta",
    decline: "Rifiuta",
  },
  plans: {
    change: "Cambia",
    free: "Piano gratuito",
    unlimited: "Piano unlimited",
    units: {
      USER: "Utenti",
      PROJECT: "Progetti",
      INTEGRATION: "Integrazioni",
      TEAM_DASHBOARD: "Team dashboard",
    },
    tierNotLimited: "Nessun limite",
    enabled: "Abilitato",
    disabled: "Disabilitato",
    title: "I nostri piani",
    gratis: "Gratuito",
    currentPlan: "Piano corrente",
    selectPlan: "Seleziona {{name}}",
    labels: {
      UNLIMITED_USER: "Utenti illimitati",
      UNLIMITED_PROJECT: "Progetti illimitati",
      UNLIMITED_INTEGRATION: "Integrazioni illimitati",
      UNLIMITED_TEAM_DASHBOARD: "Team dashboard",
    },
    paymentSuccess:
      "Piano attivato correttamente! L'aggiornamento potrebbe richiedere qualche minuto",
    paymentError: "Si è verificato un errore",
    downgradeToFreePlan: "Torna al piano gratuito",
  },
  common: {
    action: "Azione",
    delete: "Cancella",
    edit: "Modifica",
    cancel: "Annulla",
    confirm: "Conferma",
    save: "Salva",
    yes: "Si",
    no: "No",
    search: "Cerca",
    reset: "Cancella",
    actionExecutedSuccessfully: "Azione eseguita correttamente",
    error: "Qualcosa è andato storto. Per favore riprova",
    requiredField: "Campo richiesto",
    cleanFilters: "Rimuovi filtri",
    refresh: "Aggiorna",
    create: "Crea",
    backToLogin: "Torna al login",
    goBack: "Indietro",
    language: "Lingua",
    selectPlaceholder: "Seleziona...",
    newVersionAvailable: "Nuova versione disponibile",
  },
  languages: {
    it: "Italiano",
    en: "English",
  },
  messages: {
    formValidations: {
      projectWorkflowForm: {
        nameRequired: "Il nome deve essere almeno di 2 caratteri",
      },
      webhookForm: {
        urlRequired: "L'endpoint è richiesto",
      },
      inviteUserForm: {
        emailRequired: "L'indirizzo email è richiesto",
        emailInvalid: "Deve essere un indirizzo email valido",
        nominativeRequired: "Il nominativo è richiesto",
        roleRequired: "Il ruolo è richiesto",
      },
    },
  },
  components: {
    searchBar: {
      placeholder: "Cerca...",
    },
    userContractsTable: {
      fromData: "Da",
      toDate: "A",
      hourlyCost: "Costo orario",
      contractualHours: "Ore contrattuali",
      contractType: "Tipo contratto",
      productivityTarget: "Produttività target",
    },
    userContractForm: {
      fromDate: "Da",
      toDate: "A",
    },
    changelogModal: {
      title: "Lista cambiamenti",
      error:
        "C'è stato un errore durante il caricamento della lista cambiamenti",
    },
  },
};

export default it;
