import { CircularProgress } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import HourglassImage from "../assets/images/hourglass.svg";
import useInterval from "../hooks/useInterval";
import { useStores } from "../hooks/useStores";
import { sec2time } from "../utils/dateTimeFormat";

export const TimeButton: React.FC<any> = observer(() => {
  const { session } = useStores();

  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if (session.timer) {
      setElapsedTime((new Date().getTime() - session.timer?.startTime) / 1000);
    }
  }, [session.timer]);

  useInterval(
    () => {
      setElapsedTime(elapsedTime + 1);
    },
    // Delay in milliseconds or null to stop it
    session.timer ? 1000 : null
  );

  const handleButtonStopClick = useCallback(() => {
    session.stopTimer();
  }, [session]);

  const getElapsedPercent = () => {
    return (
      (((session.timer?.event?.elapsedTime || 0) + elapsedTime) /
        (((session.timer?.event?.endTime || 0) -
          (session.timer?.event.startTime || 0)) /
          1000)) *
      100
    );
  };

  if (!session.timer) {
    return null;
  }

  return (
    <TimerContainer>
      <TimerProgress>
        <div style={{ position: "relative" }}>
          <CircularProgress
            value={getElapsedPercent()}
            size={"34px"}
            thickness={"5px"}
          >
            <img
              src={HourglassImage}
              alt={"hourglass"}
              style={{
                position: "absolute",
                width: "16px",
                height: "16px",
                top: "25%",
                left: "25%",
              }}
            />
          </CircularProgress>
        </div>
        <TimerCounter>{sec2time(elapsedTime, "HH:MM:SS")}</TimerCounter>
      </TimerProgress>
      {/* <TimerButton onClick={handleButtonStopClick}>
        {!session.timer && <img src={ClockImage} alt="" />}
        {session.timer && (
          <Progress
            type={"circle"}
            percent={getElapsedPercent()}
            width={32}
            showInfo={false}
          />
        )}
      </TimerButton> */}
      <TimerContent>
        <EventTitle>{session.timer.event.title}</EventTitle>
        <TimerButton onClick={handleButtonStopClick}>Done</TimerButton>
      </TimerContent>
    </TimerContainer>
  );
});

const TimerContainer = styled.div`
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 500;
  display: flex;
  border-radius: 0.8rem;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 6px;
  overflow: hidden;
`;

const TimerContent = styled.div`
  position: relative;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

// const TimerButton = styled.div`
//   height: 3rem;
//   width: 3rem;
//   background-color: red;
//   border-radius: 0.8rem;
//   padding: 0.5rem;
//   cursor: pointer;
//   z-index: 502;
//   position: relative;
// `;

const TimerCounter = styled.div`
  font-size: 1rem;
  margin-left: 1rem;
  color: #fff;
`;

const EventTitle = styled.div`
  max-width: 150px;
  font-size: 0.8rem;
  margin-right: 2rem;
`;

const TimerProgress = styled.div`
  padding: 1rem;
  background-color: #3dbeaa;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const TimerButton = styled.button`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;

  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;

  &:active,
  &:hover {
    color: #fff;
    background-color: #ec971f;
    background-image: none;
    border-color: #d58512;
    outline: 0;
  }

  &:focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d;
    outline: 0;
  }
`;
